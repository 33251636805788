import React, { useEffect, useState, useContext, useRef } from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  InfoWindow,
  Polyline,
} from "react-google-maps";
import greenImage from "../assets/img/locationImagePickGreen.png";
import moment from "moment";
import CircleIcon from "@mui/icons-material/Circle";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import ConfirmationDialogRaw from "./ConfirmationDialogRaw";
import { useSelector, useDispatch } from "react-redux";
import { FirebaseContext } from "common";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from "@mui/icons-material/Send";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import ShareIcon from "@mui/icons-material/Share";

import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { ReportOutlined } from "@material-ui/icons";
import AlertDialog from "./AlertDialog";
import UsersCombo from "./UsersCombo";
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from "react-i18next";

import QRCode from 'qrcode';
import ReactToPrint from 'react-to-print';

const useStyles = makeStyles((theme) => ({
  chatModal: {
    padding: "10px",
    backgroundColor: "#E2F6F9",
    boxShadow: "0 3px 10px rgba(0, 0, 0, 0.3)",
    borderRadius: "10px",
    fontWeight: 500,
    width: 500,
    lineBreak: "anywhere",
    "@media(max-width:600px)": {
      width: "90%",
    },
  },
  chates: {
    height: "56vh",
    overflowY: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    borderRadius: "10px",
    backgroundColor: "#D6F2F6",
    padding: 5,
    width: "100%",
    "@media(min-resolution: 135dpi)": {
      // height: "300px",
    },
  },
  chatTextArea: {
    width: "370px",
    "@media(max-width: 600px)": {
      width: "350px",
    },
    "@media(max-width: 700px) and (min-resolution: 135dpi)": {
      width: "250px",
    },
  },
  callModal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 3px 10px rgba(0, 0, 0, 0.3)",
    backgroundColor: "#fff",
    width: "300px",
    padding: "20px",
    gap: "10px",
    top: "113%",
    borderRadius: "10px",
    margin: "15% auto",
    "@media(max-width: 800px)": {
      marginTop: "20%",
    },
    "@media(max-width: 650px)": {
      marginTop: "30%",
    },
  },
  callButtons: {
    backgroundColor: "#D6F2F6",
    border: "0",
    padding: "10px 5px",
    borderRadius: "20px",
    width: "80px",
    cursor: "pointer",
  },
  copyMessage: {
    position: "absolute",
    right: "300px",
    top: "131%",
    textAlign: "center",
    color: "#C6E8EE",
  },
  truckingMapTexts: {
    fontWeight: 400,
    // '@media(max-width: 770px)': {
    //   marginLeft: '15%'
    // },
    // '@media(max-width: 700px)': {
    //   marginLeft: '35%'
    // },
    // '@media(max-width: 647px)': {
    //   marginLeft: '55%'
    // },

    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // flexDirection: "column",
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    lineBreak:"anywhere",
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    width:"60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    '@media (max-width: 800px)':{ width:"90%"} ,
    maxHeight:'70vh',
    overflow:"auto",
    paddingTop:30
  },
}));

const TruckingMap = withGoogleMap((props) => {
  const { api } = useContext(FirebaseContext);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { t, i18n  } = useTranslation();

  const isRTL = i18n.dir();

  const {
    cancelBooking,
    unreadMessagesOfUser,
    AcceptBooking,
    TrackingNotificationForBroker,
    updateBooking,
    handleEarlyPickUpAndDrop,
  } = api;
  const { curBooking, lastLocation, liveRouteCoords } = props;

  const [all_liveRouteCoords, set_all_liveRouteCoords] = useState([]);
  const [allCurBookingCoords, setAllCurBookingCoords] = useState([]);

  const userdata = useSelector((state) => state.usersdata);
  const unread = useSelector((state) => state.chatdata?.unread);

  function isSuperDispatcher() {
    return (
      auth?.info?.profile?.usertype === "dispatcher" &&
      auth.info.profile.independent
    );
  }

  useEffect(() => {
    const isAssigndFromBroker =
      curBooking?.assigndFromBroker === auth.info?.uid;
    const isAssigndFromCarrier = curBooking?.assigndTo === auth.info?.uid;
    if (auth.info?.profile?.usertype === "shipper" || isAssigndFromBroker) {
      dispatch(
        unreadMessagesOfUser({
          currentUserId: curBooking.customer,
          chatId: curBooking?.chatId,
        })
      );
    } else if (
      isAssigndFromCarrier ||
      auth.info?.profile?.usertype == "fleetadmin"
    ) {
      dispatch(
        unreadMessagesOfUser({
          currentUserId: curBooking?.carrierId,
          chatId: curBooking?.chatId,
        })
      );
    } else if (isSuperDispatcher()) {
      if (
        giveIdThenIwillGiveYouData(curBooking?.assigndFromBroker)
          ?.dispatcherId === auth.info?.uid
      ) {
        dispatch(
          unreadMessagesOfUser({
            currentUserId: curBooking.customer,
            chatId: curBooking?.chatId,
          })
        );
      } else if (
        giveIdThenIwillGiveYouData(curBooking.assigndTo).dispatcherId ===
        auth.info?.uid
      ) {
        dispatch(
          unreadMessagesOfUser({
            currentUserId: curBooking?.carrierId,
            chatId: curBooking?.chatId,
          })
        );
      }
    }

    // if (props.role === 'shipper') {
    //   dispatch(unreadMessagesOfUser({ currentUserId: auth.info.uid, chatId: curBooking?.chatId }));
    // }
    // else if ((props.role === 'dispatcher'&&auth.info.profile?.brokerId) || isSuperDispatcher()) {
    //   dispatch(unreadMessagesOfUser({ currentUserId: curBooking.customer, chatId: curBooking?.chatId }));
    // }
    // else if(props.role === 'fleetadmin' || isSuperDispatcher() || (props.role === 'dispatcher'&&auth.info.profile?.carrierId)){
    //   dispatch(unreadMessagesOfUser({ currentUserId: curBooking?.carrierId, chatId: curBooking?.chatId }));
    // }
  }, [unreadMessagesOfUser, dispatch, curBooking]);

  const handleAcceptBooking = () => {
    let userData = {
      ...auth.info.profile,
      id: auth.info.uid,
    };

    dispatch(
      AcceptBooking({
        booking: curBooking,
        adminId: userData,
      })
    );
  };

  useEffect(() => {
    if (liveRouteCoords) {
      let revertedCourse = liveRouteCoords?.map((e) => {
        return { lat: e?.latitude, lng: e?.longitude };
      });

      set_all_liveRouteCoords(revertedCourse);
    }

    let CurBookingCoords = curBooking?.coords?.map((e) => {
      return { lat: e.latitude, lng: e.longitude };
    });
    setAllCurBookingCoords(CurBookingCoords);
  }, [liveRouteCoords, curBooking]);
  const [openConfirm, setOpenConfirm] = useState(false);

  const classes = useStyles();

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: curBooking,
          cancelledBy: {
            usertype: auth.info.profile.usertype,
            id: auth.info.uid,
            time: new Date().getTime(),
          },
        })
      );
    }
    setOpenConfirm(false);
  };
  const [openChat, setOpenChat] = useState(false);
  const [openCall, setOpenCall] = useState({ open: false, mobile: null });
  const [openShare, setOpenShare] = useState({ open: false, link: null });
  const [openEmail, setOpenEmail] = useState({ open: false, email: null });
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyClick = () => {
    if (giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.mobile) {
      navigator.clipboard.writeText(
        giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.mobile
      );
    }
    setPhoneCopied(true);
  };

  const handleShareCopyClick = () => {
    if (openShare.link) {
      navigator.clipboard.writeText(openShare.link);
    }
    setLinkCopied(true);
  };
  const handleCallClick = () => {
    if (giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.mobile) {
      window.open(
        `tel: ${giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.mobile}`,
        "_self"
      );
    }
  };

  const handleEmailClick = () => {
    if (giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.email) {
      window.open(
        `mailto:${giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.email}`,
        "_self"
      );
    }
  };

  const handleCopyEmailClick = () => {
    if (giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.email) {
      navigator.clipboard.writeText(
        giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.email
      );
    }
    setEmailCopied(true);
  };

  const messagesEndRef = useRef(null);
  const scrollToButtom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ block: "end" });
    }
  };

  const rootRef = useRef(null);
  const [callStatus, setCallStatus] = useState(false);
  const [chatStatus, setChatStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const handleCloseCallModal = (e) => {
    e.preventDefault();
    setCallStatus(false);
    setOpenCall({ open: false, mobile: null });
    setOpenShare({ open: false, link: null });
  };
  const handleCloseChatModal = (e) => {
    e.preventDefault();
    setOpenChat(false);
    setChatStatus(false);
  };
  const handleCloseEmailModal = (e) => {
    e.preventDefault();
    setOpenEmail({ open: false, email: null });
    setEmailStatus(false);
  };

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [driverDetaiil, setDriverDetail] = useState(null);
  const [selectedPolyline, setSelectedPolyline] = useState(null);
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };
  const handleDriverClicked = (curBooking) => {
    if (curBooking.manualBooking || curBooking?.customBooking) {
      setDriverDetail(
        giveIdThenIwillGiveYouData(curBooking?.driverDetails?.id)?.location?.add
      );
    } else {
      setDriverDetail(
        giveIdThenIwillGiveYouData(curBooking.post_request.driver?.id)?.location
          ?.add
      );
    }
  };
  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
    setSelectedPolyline(null);
    setDriverDetail(null);
  };

  function giveMeIdThenIwillGiveYouName(id) {
    if (!userdata?.staticusers) return "";

    let targetCarrier = userdata?.staticusers?.filter((e) => {
      return e.id === id;
    });
    if (targetCarrier.length === 0) return "";
    return targetCarrier[0].firstName + " " + targetCarrier[0].lastName;
  }

  const [carrierName, setCarrierName] = useState("");
  const [brokerName, setBrokerName] = useState(null);
  useEffect(() => {
    let targetCarrier = giveIdThenIwillGiveYouData(
      curBooking.carrierId
    ).companyName;
    setCarrierName(targetCarrier);
    if (curBooking.customer) {
      let targetBroker = giveIdThenIwillGiveYouData(
        curBooking.customer
      ).companyName;
      setBrokerName(targetBroker);
    }
  }, [curBooking]);

  const minWidth = useMediaQuery("(max-width: 702px)");
  const [showDettail, setShowDetail] = useState(true);
  const matches = useMediaQuery("(max-width:400px)");
  const showedstyles = {
    padding: showDettail ? "20px" : 0,
    width: showDettail ? (matches ? "100%" : "350px") : 0,
  };

  const giveIdThenIwillGiveYouData = (id) => {
    const targetUser = userdata.users.filter((e) => {
      return e.id === id;
    });
    if (targetUser.length > 0) return targetUser[0];
    return null;
  };
  const NotificationClicked = (bookingData) => {
    dispatch(TrackingNotificationForBroker(bookingData));
  };

  const isAdminAllowed = () => {
    return (
      props.role === "admin" ||
      (props.role === "fleetadmin" &&
        auth.info?.uid === curBooking.carrierId) ||
      (props.role === "shipper" && auth.info?.uid === curBooking.customer)
    );
  };

  const [currentStatus, setCurrentStatus] = React.useState(curBooking.status);

  const handleChange = (event) => {
    let updatedBooking = { ...curBooking, status: event.target.value };
    dispatch(updateBooking(updatedBooking));
    setCurrentStatus(event.target.value);
  };

  useEffect(() => {
    setCurrentStatus(curBooking.status);
  }, [curBooking.status]);

  const [AvailableStatus, SetAvailableStatus] = useState([]);

  const driverLocation = (curBooking) => {
    if (curBooking.manualBooking || curBooking?.customBooking) {
      return giveIdThenIwillGiveYouData(curBooking?.driverDetails?.id)
        ?.location;
    } else {
      return giveIdThenIwillGiveYouData(curBooking?.post_request?.driver?.id)
        ?.location;
    }
  };

  useEffect(() => {
    SetAvailableStatus([
      { label: !curBooking?.loadImage?"Waiting for collection of item":curBooking?.fullPayment?.status==="verified"?"Waiting for the driver to be assigned":"Awaiting full payment", value: "NEW", active: true },
      {
        label: "Driver Waiting for pickup date",
        value: "ACCEPTED",
        active: true,
      },
      {
        label: "Driver arrived pickup location",
        value: "ARRIVED",
        active: isDriverNearToPickup(
          driverLocation(curBooking),
          curBooking.pickup
        ),
      },
      {
        label: "Driver headed to drop off location",
        value: "STARTED",
        active: isDriverNearToPickup(
          driverLocation(curBooking),
          curBooking.pickup
        ),
      },
      {
        label: "Driver reached drop off location",
        value: "REACHED",
        active:
          curBooking.status === "STARTED" &&
          isDriverNearToDropOff(driverLocation(curBooking), curBooking.drop),
      },
      {
        label: "Booking completed",
        value: "COMPLETE",
        active:
          (curBooking.status === "REACHED" ||
            curBooking.status === "STARTED") &&
          isDriverNearToDropOff(driverLocation(curBooking), curBooking.drop),
      },
    ]);
  }, [curBooking, lastLocation]);

  const changeStatus = (status) => {
    let assignedStatus = AvailableStatus.filter((e) => {
      return e.value === status;
    });
    if (assignedStatus.length === 0) return "Unknown";
    return assignedStatus[0].label;
  };

  const isDriverNearToPickup = (location, pickup) => {
    if (!location || !pickup) return false;
    const distance = calculateDistance(location, pickup);
    // console.log("pick up", distance);
    return distance <= 1;
  };

  const isDriverNearToDropOff = (location, dropOff) => {
    if (!location || !dropOff) return false;
    const distance = calculateDistance(location, dropOff);

    // console.log("dropOff", distance);

    return distance <= 1;
  };

  // Helper function to calculate the distance between two coordinates
  function calculateDistance(coord1, coord2) {
    const R = 6371; // Earth's radius in km
    const lat1 = coord1.lat;
    const lat2 = coord2.lat;
    const lon1 = coord1.lng;
    const lon2 = coord2.lng;

    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  const [earlyPickup, setEarlyPickUp] = useState({
    flag: false,
    value: null,
    type: "pickup",
  });
  const [earlyDropOff, setEarlyDropOff] = useState({
    flag: false,
    value: null,
    type: "drop",
  });

  useState(() => {
    if (curBooking?.earlyPickup?.flag) {
      setEarlyPickUp(curBooking.earlyPickup);
    }
    if (curBooking?.earlyDropOff?.flag) {
      setEarlyDropOff(curBooking.earlyDropOff);
    }
  }, [curBooking]);

  const handleChangeEarlyPickUp = (event) => {
    const newDataToUpdate = { ...earlyPickup, flag: event.target.checked };
    setEarlyPickUp(newDataToUpdate);
    dispatch(
      handleEarlyPickUpAndDrop({
        earlyValue: newDataToUpdate,
        booking: curBooking,
      })
    );
  };

  // const handleEarlyPickUpValue =(event)=>{
  //   const newDataToUpdate = {...earlyPickup,value:event.target.value}
  //   setEarlyPickUp(newDataToUpdate)
  //   dispatch(handleEarlyPickUpAndDrop({earlyValue:newDataToUpdate,booking:curBooking}))

  // }

  const handleChangeEarlyDropOff = (event) => {
    const newDataToUpdate = { ...earlyDropOff, flag: event.target.checked };
    setEarlyDropOff(newDataToUpdate);
    dispatch(
      handleEarlyPickUpAndDrop({
        earlyValue: newDataToUpdate,
        booking: curBooking,
      })
    );
  };

  // const handleEarlyDropOffValue =(event)=>{
  //    const newDataToUpdate = {...earlyDropOff,value:event.target.value}
  //    setEarlyDropOff(newDataToUpdate)
  //    dispatch(handleEarlyPickUpAndDrop({earlyValue:newDataToUpdate,booking:curBooking}))
  // }

  const pageBackgroundColor = () => {
    if (auth?.info?.profile?.dashboardColor)
      return auth?.info?.profile?.dashboardColor;
    if (
      giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
    )
      return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)
        ?.dashboardColor;
    return "#01aac1";
  };

  const isSpecialAdmin = () => {
    return (
      auth.info?.profile?.specialAdmin ||
      giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
    );
  };
  const [editBooking, setEditBooking] = useState({ open: false, data: null });
  const settings = useSelector(state => state.settingsdata.settings);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

const [allowedPayment, setAllowedPayment] = useState('') 
const [depositPaymentstatus, setDepositPaymentStatus] = useState('')
const [fullPaymentstatus, setFullPaymentStatus] = useState('')
const [pickupDate, setPickupDate] = useState(null)
const [dropDate, setDropDate] = useState(null)
const [paymentAmount,setPaymentAmount] = useState(null)

const [depaositTransaction, setDepositTransaction] = useState("")
const [paymentTransaction, setPaymentTransaction] = useState("")

const [bookingLoadWeight,setBookingLoadWeight] = useState(null)
const [drivers, setDrivers] = useState([]);
const [driverCombo, setDriverCombo] = useState(null);

useEffect(() => {
 
  if (userdata.users) {
    let arrDrivers = [];
    for (let i = 0; i < userdata.users.length; i++) {
      let user = userdata.users[i];
      if((!user.approved||!user.emailVerified) && (user.usertype === 'driver') ){
        continue
       }
      if(auth.info.profile.usertype==="admin"){
          if ((user.usertype) && (user.usertype === 'driver') && user.location && (user.queue === false) && (user.driverActiveStatus === true)) {
            arrDrivers.push({
              'uid': user.id,
              'location': user.location,
              'trailerType': user.trailerType,
              'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
              ...user
            });
          }
      }

      else if(auth.info.profile.usertype==="fleetadmin" ){
          if ((user.usertype) && (user.usertype === 'driver') && user.location && (user.queue === false) && (user.driverActiveStatus === true)  && user.fleetadmin===auth.info.uid) {
            arrDrivers.push({
              'uid': user.id,
              'location': user.location,
              'trailerType': user.trailerType,
              'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
              ...user
            });
          }
      }

      else if(auth?.info?.profile?.carrierId){
        if ((user.usertype) && (user.usertype === 'driver') && user.location && (user.queue === false) && (user.driverActiveStatus === true) && user.fleetadmin === auth?.info?.profile?.carrierId) {
          arrDrivers.push({
            'uid': user.id,
            'location': user.location,
            'trailerType': user.trailerType,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            ...user
          });
        }
      }
    
    }

    setDrivers(arrDrivers);
  }

}, [ userdata.users, settings.AllowCriticalEditsAdmin]);

  const editModalOpened = (rowData) => {
    setEditBooking({ open: true, data: rowData });
  };

  const changeToValidDate=(timestamp)=>{
    if(!timestamp) return null
   return moment(timestamp).format('YYYY-MM-DDTHH:mm');
 }

 const handleCommonAlertClose = (e) => {
  e.preventDefault();
  setCommonAlert({ open: false, msg: '' })
};

  useEffect(() => {
    if (editBooking?.data) {
      setAllowedPayment(editBooking?.data?.allowedPayment || "");
      setDepositPaymentStatus(editBooking?.data?.depositPayment?.status);
      setFullPaymentStatus(editBooking?.data?.fullPayment?.status);

      setDepositTransaction(editBooking?.data?.depositPayment?.tx);
      setPaymentTransaction(editBooking?.data?.fullPayment?.tx);

      setPickupDate(changeToValidDate(editBooking?.data?.pickupDate));
      setDropDate(changeToValidDate(editBooking?.data?.dropDate));

      setPaymentAmount(editBooking?.data?.paymentAmount || null);
      setBookingLoadWeight(editBooking?.data?.loadWeight || null);

      if (drivers?.length > 0 && editBooking?.data?.driverDetails?.id) {
        let driverData = drivers.filter((e) => {
          return e.id === editBooking?.data?.driverDetails?.id;
        });

        if (driverData.length > 0) {
          setDriverCombo(driverData[0]);
        }
      }
    } else {
      //setAllowedPayment(null)
      // setDepositPaymentStatus(null)
      // setFullPaymentStatus(null)
      setPickupDate(null);
      setDropDate(null);
      setDriverCombo(null);

      setPaymentTransaction(null);
      setDepositTransaction(null);
      setBookingLoadWeight(null);
      setPaymentAmount(null);
    }
  }, [editBooking, drivers]);

  const handleChangePaymentAmount = (event) => {
    setPaymentAmount(event.target.value);
  };
  const handleChangeBookingLoadWeight = (event) => {
    setBookingLoadWeight(event.target.value);
  };

  const handleChangePaymentType = (event) => {
    setAllowedPayment(event.target.value);
  };
  const handleDepositPayment = (event) => {
    setDepositPaymentStatus(event.target.value);
  };
  const handleFullPayment = (event) => {
    setFullPaymentStatus(event.target.value);
  };

  const onPickupDateChange = (event) => {
    if (event.target.value) {
      const currentDate = new Date().getTime();
      const targetDate = new Date(event.target.value).getTime();
      if (targetDate > currentDate) setPickupDate(event.target.value);
      else {
        setCommonAlert({ open: true, msg: "Pickup Date cannot  be past date" });
        setPickupDate(null);
      }
    }
  };
  const onDropDateChange = (event) => {
    if (event.target.value) {
      const currentDate = new Date().getTime();
      const targetDate = new Date(event.target.value).getTime();
      if (targetDate > currentDate) setDropDate(event.target.value);
      else {
        setCommonAlert({ open: true, msg: "Drop Date cannot  be past date" });
        setDropDate(null);
      }
    }
  };

  const canAddDepositTransactionRef = (booking) => {
    if (depositPaymentstatus !== "processing" || !booking?.assigndTo)
      return false;
    if (auth?.info?.profile?.usertype === "fleetadmin") return true;
    if (
      auth.info?.profile?.carrierId &&
      (booking.manual || !booking.customer)
    ) {
      return true;
    }
    return false;
  };
  const anyValidPaymentStatus = (payment,type) => {
  
    if (!payment) return false;
  
    if(type==="all"){
        return payment.status === 'failed' || payment.status === 'processing';
    }
    //  if(type==="failed") return payment.status === 'failed'
    return payment.status === type
  };

  const updateTargetBooking = () => {
    const depositPayment = {
      status: depositPaymentstatus,
      tx: depaositTransaction || null,
    };
    const fullPayment = {
      status: fullPaymentstatus,
      tx: paymentTransaction || null,
    };
    const driverDetails = {
      id: driverCombo?.id || null,
    };
    const dataToUpdate = {
      allowedPayment,
      dropDate,
      pickupDate,
      depositPayment,
      fullPayment,
      oldBooking: editBooking?.data,
      driverDetails,
      loadWeight: bookingLoadWeight || null,
      paymentAmount: paymentAmount || null,
      adminBankAccount:
        auth.info?.profile?.usertype == "fleetadmin"
          ? auth.info?.profile?.adminBankAccount
          : giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)
              ?.adminBankAccount,
    };

    if (
      depositPaymentstatus &&
      depositPaymentstatus !== "unpaid" &&
      !depaositTransaction
    ) {
      if (!canAddDepositTransactionRef(editBooking?.data)) {
        setCommonAlert({
          open: true,
          msg: "Deposit transaction reference is required, Wait till customer add the refernce.",
        });
      } else
        setCommonAlert({
          open: true,
          msg: "Deposit transaction reference is required, please add valid deposit transaction reference.",
        });
      return;
    }

    if (
      fullPaymentstatus &&
      fullPaymentstatus !== "unpaid" &&
      !paymentTransaction
    ) {
      if (!canAddPaymentTransactionRef(editBooking?.data)) {
        setCommonAlert({
          open: true,
          msg: "Full Payment transaction reference is required,  please add valid full payment transaction reference.",
        });
      } else
        setCommonAlert({
          open: true,
          msg: "Full Payment transaction reference is required, please add valid full payment transaction reference.",
        });
      return;
    }

    if (fullPaymentstatus === "unpaid" && !paymentAmount) {
      setCommonAlert({
        open: true,
        msg: "Payment amount for the load is required",
      });
      return;
    }

    if (
      fullPaymentstatus === "verified" &&
      (!dropDate || !pickupDate || !driverCombo)
    ) {
      setCommonAlert({
        open: true,
        msg: "Since Full payment is verified Pickup date, drop date and driver are required",
      });
      setEditBooking({ open: false, data: null });
      return;
    }

    if ((dropDate && !pickupDate) || (pickupDate && !dropDate)) {
      setCommonAlert({
        open: true,
        msg: "Pickup date and drop date are required",
      });
      setEditBooking({ open: false, data: null });
      return;
    }

    if (
      dropDate &&
      pickupDate &&
      new Date(dropDate).getTime() <= new Date(pickupDate).getTime()
    ) {
      setCommonAlert({
        open: true,
        msg: "The Drop Date cannot be earlier than the Pickup Date.",
      });
      setEditBooking({ open: false, data: null });
      return;
    }

    if (
      ["NEW"].includes(dataToUpdate?.oldBooking.status) &&
      (anyValidPaymentStatus(dataToUpdate.depositPayment, "all") ||
        anyValidPaymentStatus(dataToUpdate.fullPayment, "all")) &&
      dataToUpdate?.oldBooking.paymentStatus === "unpaid"
    ) {
      dispatch(api.verifyTransactionReference(dataToUpdate))
        .then((res) => {
          setCommonAlert({ open: true, msg: res?.msg });
          setEditBooking({ open: false, data: null });
        })
        .catch((error) => {
          setCommonAlert({ open: true, msg: error?.error || "Error occured" });
          setEditBooking({ open: false, data: null });
        });
      setEditBooking({ open: false, data: null });
    } else {
      dispatch(api.UpdateCustomerBooking(dataToUpdate));
      setEditBooking({ open: false, data: null });
    }
  };

  const canAddPaymentTransactionRef = (booking) => {
    if (fullPaymentstatus !== "processing" || !booking?.assigndTo) return false;
    if (auth?.info?.profile?.usertype === "fleetadmin") return true;
    if (
      auth.info?.profile?.carrierId &&
      (booking.manual || !booking.customer)
    ) {
      return true;
    }
    return false;
  };

  const  [shipperName,setShipperName] = useState(null)
  const  [shipperPhone,setShipperPhone] = useState(null)

  useEffect(()=>{
       if(curBooking.customer){
              setShipperName(giveIdThenIwillGiveYouData(curBooking?.customer)?.firstName+" "+giveIdThenIwillGiveYouData(curBooking?.customer)?.lastName)
              setShipperPhone(giveIdThenIwillGiveYouData(curBooking?.customer)?.mobile)
       }
       else {
        setShipperName(curBooking?.shipperName)
        setShipperPhone(curBooking?.shipprPhone)
       }

  },[curBooking,userdata])


  const bookingDetailsRef = useRef();

  const extractCityStateFromAddress = (address) => {
    if (!address) return "";
  
    const parts = address.split(", ");
  
    if (parts.length < 2) return address; 
  
    let firstPart = parts[0].split(" ");
    
    if (firstPart.length > 1) {
      firstPart.shift(); 
    }
  
    let city = firstPart.join(" ");
    let country = parts[parts.length - 1]; 
  
    const fullAddress = `${city}, ${country}`;
  
    return fullAddress;
  };


  const  DownloadPDF=()=>{
     const idConveertedToQeCode = curBooking.id
     const dataToShowUpOnPdf = {
        shipperPhone:shipperPhone,
        shipperName:shipperName,
        pickup:extractCityStateFromAddress(curBooking?.pickup?.add),
        drop:extractCityStateFromAddress(curBooking?.drop?.add),
        merchantName:curBooking?.merchantName,
        merchantPhone:curBooking?.merchantPhone,
        reference:curBooking?.reference,
        pickupDate:moment(curBooking?.pickupDate).format("LLL"),
        dropDate:moment(curBooking?.dropDate).format("LLL"),
        id:idConveertedToQeCode
     }
     
     return dataToShowUpOnPdf
  }



  return (
    <div>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          top: "-670px",
          left: "12px",
          zIndex: 5,
          borderRadius: "20px",
          width: "100%",
          paddingRight: "20px",
        }}
      >
        <Box>
          <IconButton
            sx={{ background: pageBackgroundColor() }}
            onClick={() => props.history.push("/bookings")}
          >
            <ArrowBackIcon style={{ color: "white" }} />
          </IconButton>
        </Box>
        <Box>
          <Tooltip title="Detail">
            <IconButton
              sx={{ background: pageBackgroundColor() }}
              onClick={() => setShowDetail(true)}
            >
              <InfoIcon style={{ color: "white" }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <GoogleMap
        defaultZoom={5}
        defaultCenter={props.center}
        ref={props.mapRef}
      >
        <Marker
          position={props.center}
          icon={{
            url: greenImage,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        >
          {curBooking?.pickup?.add && (
            <InfoWindow>
              <div
                style={{
                  fontWeight: "500",
                  maxWidth: "250px",
                  lineBreak: "anywhere",
                }}
              >
                {curBooking?.pickup?.add}
                <div
                  style={{
                    fontSize: "12px",
                    opacity: 0.6,
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  Pick up
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>

        <Marker
          position={{
            lat: curBooking.drop.lat,
            lng: curBooking.drop.lng,
          }}
        >
          {curBooking.drop.add && (
            <InfoWindow>
              <div
                style={{
                  fontWeight: "500",
                  maxWidth: "250px",
                  lineBreak: "anywhere",
                }}
              >
                {curBooking.drop.add}
                <div
                  style={{
                    fontSize: "12px",
                    opacity: 0.6,
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  Drop off
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>

        {curBooking?.post_request?.origin &&
          curBooking?.post_request?.destination &&
          curBooking.status === "NEW" && (
            <>
              <Polyline
                path={[
                  curBooking?.post_request?.origin,
                  curBooking?.post_request?.destination,
                ]}
                options={{
                  strokeColor: "#31d9e4",
                  strokeOpacity: 1,
                  strokeWeight: 6,
                }}
                onClick={() =>
                  setSelectedPolyline([
                    curBooking?.post_request?.origin,
                    curBooking?.post_request?.destination,
                  ])
                }
              />

              {selectedPolyline && selectedPolyline.length > 0 && (
                <InfoWindow
                  position={curBooking?.post_request?.origin}
                  onCloseClick={handleInfoWindowClose}
                >
                  <div style={{ fontWeight: "500", fontSize: "10.8px" }}>
                    {selectedPolyline[0].add}{" "}
                    <span style={{ color: "red" }}></span>{" "}
                    {selectedPolyline[1].add}
                  </div>
                </InfoWindow>
              )}
            </>
          )}

        {curBooking?.post_request?.origin &&
          !curBooking?.post_request?.destination &&
          curBooking.status === "NEW" && (
            <>
              <Polyline
                path={[curBooking?.post_request?.origin, curBooking?.pickup]}
                options={{
                  strokeColor: "#31d9e4",
                  strokeOpacity: 1,
                  strokeWeight: 6,
                }}
              />

              <Marker
                position={{
                  lat: curBooking?.post_request?.origin.lat,
                  lng: curBooking?.post_request?.origin.lng,
                }}
                icon={{
                  url: "https://cdn-icons-png.flaticon.com/512/6492/6492616.png",
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                onClick={() => handleMarkerClick(curBooking?.post_request)}
              >
                {selectedMarker && (
                  <InfoWindow onCloseClick={handleInfoWindowClose}>
                    <div style={{ fontWeight: "700" }}>
                      {selectedMarker.travelRadius ? (
                        <div style={{ fontWeight: "500", textAlign: "center" }}>
                          Available Anywhere within{" "}
                          {selectedMarker.travelRadius} Mile radius
                        </div>
                      ) : (
                        <div style={{ fontWeight: "500", textAlign: "center" }}>
                          Available Anywhere
                        </div>
                      )}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </>
          )}

        {lastLocation && (
          <Marker
            position={{
              lat: lastLocation.lat,
              lng: lastLocation.lng,
            }}
            icon={{
              url: "https://cdn-icons-png.flaticon.com/512/7828/7828802.png",
              scaledSize: new window.google.maps.Size(45, 45),
            }}
            onClick={() => handleDriverClicked(curBooking)}
          >
            {driverDetaiil && (
              <InfoWindow onCloseClick={handleInfoWindowClose}>
                <div style={{ fontWeight: "700" }}>{driverDetaiil}</div>
              </InfoWindow>
            )}
          </Marker>
        )}

        {liveRouteCoords &&
        (curBooking.status == "ACCEPTED" || curBooking.status == "STARTED") ? (
          <Polyline
            path={all_liveRouteCoords}
            strokeWidth={5}
            options={{
              strokeColor: "red",
              strokeOpacity: 1,
              strokeWeight: 6,
            }}
            lineDashPattern={[1]}
          />
        ) : null}

        {(curBooking.status == "ARRIVED" || curBooking.status == "REACHED") &&
        curBooking.coords ? (
          <Polyline
            coordinates={allCurBookingCoords}
            strokeWidth={4}
            options={{
              strokeColor: "red",
              strokeOpacity: 1,
              strokeWeight: 6,
            }}
            lineDashPattern={[1]}
          />
        ) : null}
      </GoogleMap>

      <Box
        sx={{
          position: "absolute",
          top: props.role === "none" ? "230px" : "105px",
          right: "10px",
          zIndex: 5,
          background: "white",
          padding: showedstyles.padding,
          borderRadius: "20px 20px 20px 20px",
          maxHeight: props.role === "none" ? "50vh" : "80vh",
          overflow: "auto",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          width: showedstyles.width,
          lineBreak: "anywhere",
          overflowX: "hidden",
          transition: "0.5s",
          "@media(max-width:400px)": { right: 0 },
        }}
      >
        <div>
          <IconButton onClick={() => setShowDetail(false)}>
            <CloseIcon />
          </IconButton>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className={classes.truckingMapTexts}>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                marginBottom: "10px",
                fontWeight: 600,
              }}
            >
              Tracking Detail
            </Typography>
            <div>
              <b>Status:</b>{" "}
              {curBooking?.approved
                ? changeStatus(curBooking.status)
                : "Not Approved"}
            </div>
            
            {props?.role !=="none"&& <div>
              <b>Source:</b>{" "}
              <span style={{ color: "#016f94", fontWeight: 600 }}>
                {curBooking?.manualBooking
                  ? "Manually"
                  : curBooking?.customBooking
                  ? `Custom ${(curBooking.manual || !curBooking.customer)?"(Manual)":""}`
                  : curBooking?.loadId
                  ? "Load Board"
                  : "Capacity Board"}
              </span>
            </div>}

            <div>
              <b>Driver location:</b>{" "}
              <span style={{ color: "#016f94" }}>
                {lastLocation?.add || "Not Set"}{" "}
              </span>
            </div>

            {props.role !== "none" && (
              <div>
                <b>Driver name:</b>{" "}
                <span>
                  {curBooking.manualBooking || curBooking?.customBooking
                    ? curBooking?.driverDetails?.id
                      ? `${
                          giveIdThenIwillGiveYouData(
                            curBooking?.driverDetails?.id
                          )?.firstName || null
                        } ${
                          giveIdThenIwillGiveYouData(
                            curBooking?.driverDetails?.id
                          )?.lastName || null
                        }`
                      : "Not Set"
                    : `
                   ${curBooking.post_request.driver?.firstName} ${curBooking.post_request.driver?.lastName}`}
                </span>
              </div>
            )}

            {props.role !== "none" && !curBooking?.customBooking && (
              <div>
                <b>Vehicle Plate Number:</b> {curBooking.vehicle_number}
              </div>
            )}

            {!curBooking?.customBooking && (
              <div style={{ fontWeight: 300 }}>
                <b>Trailer Type:</b> {curBooking.trailerType}
              </div>
            )}

            {props.role !== "none" && !curBooking?.customBooking && (
              <div>
                <b>Rate Offer:</b> ${curBooking.shipperOffer}
              </div>
            )}

            {curBooking?.customBooking &&  props?.role !=="none" && (
              <div>
                <b>Payment Amount:</b>{" "}
                {curBooking?.paymentAmount
                  ? `${curBooking?.paymentAmount} Birr`
                  : "Not Set"}
              </div>
            )}
            {curBooking?.customBooking && props?.role !=="none"&& (
              <div>
                <b>Merchant Name </b> {curBooking?.merchantName || "Not Set"}
              </div>
            )}
            {curBooking?.customBooking && props.role !== "none" && (
              <div>
                <b>Merchant Mobile </b> {curBooking?.merchantPhone || "Not Set"}
              </div>
            )}
            {curBooking?.customBooking && props?.role !=="none"&& (
              <div>
                <b>Shipper Name </b> {shipperName|| "Not Set"}
              </div>
            )}
            {curBooking?.customBooking && props.role !== "none" && (
              <div>
                <b>Shipper Mobile </b> {shipperPhone|| "Not Set"}
              </div>
            )}

            {props.role !== "none" && (
              <div>
                <b>Reference:</b> {curBooking.reference}
              </div>
            )}

            {props?.role !=="none"&&<div>
              <b>Posted Date:</b>{" "}
              {moment(new Date(curBooking.bookingDate)).format(
                "ddd DD MMM YYYY, hh:mm A"
              )}
            </div>}

            <div>
              <b>Pick up Date:</b>{" "}
              {curBooking.pickupDate
                ? moment(new Date(curBooking.pickupDate)).format(
                    "ddd DD MMM YYYY, hh:mm A"
                  )
                : "Not Set"}
            </div>

            <div>
              <b>Drop Date:</b>{" "}
              {curBooking.dropDate
                ? moment(new Date(curBooking.dropDate)).format(
                    "ddd DD MMM YYYY, hh:mm A"
                  )
                : "Not Set"}
            </div>

            {curBooking?.customBooking && props?.role !=="none"&& (
              <div>
                <b>Full Payment:</b>{" "}
                {curBooking?.fullPayment?.status || "Unpaid"}
              </div>
            )}

            {curBooking?.customBooking && props?.role !=="none"&& (
              <div>
                <b>Deposit Payment:</b>{" "}
                {curBooking?.depositPayment?.status || "Unpaid"}
              </div>
            )}

            {curBooking.driver_arrive_time && (
              <div>
                <b>Driver Arrival Time: </b>
                {moment(curBooking.driver_arrive_time).format(
                  "ddd DD MMM YYYY, hh:mm A"
                )}
              </div>
            )}

            {curBooking.trip_start_time && (
              <div>
                <b>Trip Started: </b>
                {moment(curBooking.trip_start_time).format(
                  "ddd DD MMM YYYY, hh:mm A"
                )}
              </div>
            )}

            {curBooking.trip_end_time && (
              <div>
                <b>Trip Ended: </b>
                {moment(curBooking.trip_end_time).format(
                  "ddd DD MMM YYYY, hh:mm A"
                )}
              </div>
            )}

            <div
              style={{
                marginTop: "30px",
                fontWeight: 500,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    width: "35%",
                  }}
                >
                  <div>
                    <CircleIcon style={{ fontSize: "13px", color: "green" }} />
                  </div>
                  <div>Pick Up: </div>
                </div>
                <div style={{ width: "60%" }}>{extractCityStateFromAddress(curBooking.pickup.add)}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    width: "35%",
                  }}
                >
                  <div>
                    <CircleIcon style={{ fontSize: "13px", color: "red" }} />
                  </div>
                  <div>Drop Off:</div>
                </div>
                <div style={{ width: "60%" }}> {extractCityStateFromAddress(curBooking.drop.add)}</div>
              </div>
            </div>

            {props.role !== "none" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: "10px",
                  flexWrap: "wrap",
                  margin: "10px 0 0px 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      opacity: "0.5",
                    }}
                  >
                    Carrier Company
                  </div>
                  <div style={{ width: "100%" }}>
                    <a
                      target="_blank"
                      href={`/user/${curBooking.carrierId}?redirected=tracking/${curBooking.id}`}
                      style={{}}
                    >
                      {carrierName}
                    </a>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      opacity: "0.5",
                    }}
                  >
                    {" "}
                    Carrier Assigned Agent{" "}
                  </div>

                  <div style={{ width: "100%" }}>
                    {curBooking?.assigndTo ? (
                      <>
                        {giveIdThenIwillGiveYouData(curBooking?.assigndTo)
                          .approved ? (
                          <a
                            target="_blank"
                            href={`/user/${curBooking?.assigndTo}?redirected=tracking/${curBooking.id}`}
                            style={{}}
                          >
                            {giveIdThenIwillGiveYouData(curBooking?.assigndTo)
                              ?.firstName +
                              " " +
                              giveIdThenIwillGiveYouData(curBooking?.assigndTo)
                                ?.lastName}
                          </a>
                        ) : (
                          <span style={{ color: "red" }}>
                            {giveIdThenIwillGiveYouData(curBooking?.assigndTo)
                              ?.firstName +
                              " " +
                              giveIdThenIwillGiveYouData(curBooking?.assigndTo)
                                ?.lastName}
                          </span>
                        )}

                        {giveIdThenIwillGiveYouData(curBooking?.assigndTo)
                          ?.dispatcherId && (
                          <>
                            <span
                              style={{
                                color: "black",
                                padding: "0 5px 0 5px",
                                fontWeight: "700",
                                fontSize: "16px",
                              }}
                            >
                              /
                            </span>
                            <Link
                              href={`user/${
                                giveIdThenIwillGiveYouData(
                                  curBooking?.assigndTo
                                )?.dispatcherId
                              }?redirected=bookings`}
                              style={{ textDecoration: "none" }}
                            >
                              {giveMeIdThenIwillGiveYouName(
                                giveIdThenIwillGiveYouData(
                                  curBooking?.assigndTo
                                )?.dispatcherId
                              )}
                            </Link>
                          </>
                        )}

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {curBooking?.assigndTo && (
                            <div>
                              <IconButton
                                onClick={() =>
                                  setOpenEmail({
                                    open: true,
                                    email: giveIdThenIwillGiveYouData(
                                      curBooking?.assigndTo
                                    )?.email,
                                  })
                                }
                              >
                                <EmailIcon
                                  style={{ color: "brown", fontSize: "18px" }}
                                />
                              </IconButton>
                            </div>
                          )}
                          {curBooking?.assigndTo && (
                            <div>
                              <IconButton
                                onClick={() =>
                                  setOpenCall({
                                    open: true,
                                    mobile: giveIdThenIwillGiveYouData(
                                      curBooking?.assigndTo
                                    )?.mobile,
                                  })
                                }
                              >
                                <PhoneIcon
                                  style={{ color: "green", fontSize: "18px" }}
                                />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      "Not Assigned Yet"
                    )}
                  </div>
                </div>
              </Box>
            )}

            {curBooking?.customer && props.role !== "none" && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: "10px",
                  flexWrap: "wrap",
                  margin: "0px 0 15px 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                      opacity: "0.5",
                    }}
                  >
                    {curBooking?.customBooking?"Customer Shop" :"Broker Company"} 
                  </div>

                  <div style={{ width: "100%" }}>
                    <a
                      target="_blank"
                      href={`/user/${curBooking.customer}?redirected=tracking/${curBooking.id}`}
                      style={{}}
                    >
                      {curBooking?.customBooking?giveIdThenIwillGiveYouData(curBooking?.customer)?.shopeName :brokerName} 
                    </a>
                  </div>
                </div>

                  {!curBooking?.customBooking&&<div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "1px",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "12px",
                        opacity: "0.5",
                      }}
                    >
                      Broker Assigned Agent
                    </div>
                    <div style={{ width: "100%" }}>
                      {curBooking?.assigndFromBroker ? (
                        <>
                          {giveIdThenIwillGiveYouData(
                            curBooking?.assigndFromBroker
                          ).approved ? (
                            <a
                              target="_blank"
                              href={`/user/${curBooking?.assigndFromBroker}?redirected=tracking/${curBooking.id}`}
                              style={{}}
                            >
                              {giveIdThenIwillGiveYouData(
                                curBooking?.assigndFromBroker
                              )?.firstName +
                                " " +
                                giveIdThenIwillGiveYouData(
                                  curBooking?.assigndFromBroker
                                )?.lastName}
                            </a>
                          ) : (
                            <span style={{ color: "red" }}>
                              {giveIdThenIwillGiveYouData(
                                curBooking?.assigndFromBroker
                              )?.firstName +
                                " " +
                                giveIdThenIwillGiveYouData(
                                  curBooking?.assigndFromBroker
                                )?.lastName}
                            </span>
                          )}

                          {giveIdThenIwillGiveYouData(
                            curBooking?.assigndFromBroker
                          )?.dispatcherId && (
                            <>
                              <span
                                style={{
                                  color: "black",
                                  padding: "0 5px 0 5px",
                                  fontWeight: "700",
                                  fontSize: "16px",
                                }}
                              >
                                /
                              </span>
                              <Link
                                href={`user/${
                                  giveIdThenIwillGiveYouData(
                                    curBooking?.assigndFromBroker
                                  )?.dispatcherId
                                }?redirected=bookings`}
                                style={{ textDecoration: "none" }}
                              >
                                {giveMeIdThenIwillGiveYouName(
                                  giveIdThenIwillGiveYouData(
                                    curBooking?.assigndFromBroker
                                  )?.dispatcherId
                                )}
                              </Link>
                            </>
                          )}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            {giveIdThenIwillGiveYouData(
                              curBooking?.assigndFromBroker
                            )?.email && (
                              <div>
                                <IconButton
                                  onClick={() =>
                                    setOpenEmail({
                                      open: true,
                                      email: giveIdThenIwillGiveYouData(
                                        curBooking?.assigndFromBroker
                                      ).email,
                                    })
                                  }
                                >
                                  <EmailIcon
                                    style={{ color: "brown", fontSize: "18px" }}
                                  />
                                </IconButton>
                              </div>
                            )}

                            {giveIdThenIwillGiveYouData(
                              curBooking?.assigndFromBroker
                            )?.mobile && (
                              <div>
                                <IconButton
                                  onClick={() =>
                                    setOpenCall({
                                      open: true,
                                      mobile: giveIdThenIwillGiveYouData(
                                        curBooking?.assigndFromBroker
                                      )?.mobile,
                                    })
                                  }
                                >
                                  <PhoneIcon
                                    style={{ color: "green", fontSize: "18px" }}
                                  />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        "Not Assigned Yet"
                      )}
                    </div>
                  </div>}
              </Box>
            )}

            {props.role !== "none" &&
              giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.approved &&
              giveIdThenIwillGiveYouData(curBooking?.assigndFromBroker)
                ?.approved && (
                <div
                  style={{
                    margin: "20px 0 0 0",
                    display: "flex",
                    justifyContent: minWidth ? "flex-start" : "center",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  {!(curBooking.manualBooking || curBooking?.customBooking) &&
                    ((props.role === "dispatcher" &&
                      (curBooking?.assigndTo === auth.info.uid ||
                        curBooking?.assigndFromBroker === auth.info.uid)) ||
                      isAdminAllowed()) && (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <IconButton
                          onClick={() => {
                            setOpenChat(!openChat);
                            scrollToButtom();
                          }}
                        >
                          <ChatIcon style={{ color: "#1ca89d" }} />
                          {unread && unread.length > 0 ? (
                            <span
                              style={{
                                fontSize: 9,
                                backgroundColor: "white",
                                position: "absolute",
                                right: "5px",
                                top: "4px",
                                borderRadius: "50%",
                                width: 22,
                                height: 22,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                style={{
                                  background: pageBackgroundColor(),
                                  borderRadius: "50%",
                                  width: "17px",
                                  height: "17px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "#fff",
                                }}
                              >
                                {unread.length}
                              </span>
                            </span>
                          ) : (
                            ""
                          )}
                        </IconButton>
                      </div>
                    )}
                </div>
              )}

            {props.role !== "none" &&
              (!curBooking?.assigndTo ||
                !giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.approved) &&
              !(curBooking.manualBooking || curBooking?.customBooking) && (
                <div
                  style={{
                    margin: "20px 0 10px 0",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Waiting for carrier to assign active agent to this Load
                </div>
              )}

            {props.role !== "none" &&
              (!curBooking?.assigndFromBroker ||
                !giveIdThenIwillGiveYouData(curBooking?.assigndFromBroker)
                  ?.approved) &&
              !(curBooking.manualBooking || curBooking?.customBooking) && (
                <div
                  style={{
                    margin: "20px 0 10px 0",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Waiting for broker to assign active gent to this Booking
                </div>
              )}

            {props.role !== "none" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flexWrap: "wrap",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                {(curBooking.status == "ACCEPTED" ||
                  curBooking.status == "NEW") &&
                  ((props.role === "dispatcher" &&
                    (curBooking?.assigndTo === auth.info.uid ||
                      curBooking?.assigndFromBroker === auth.info.uid)) ||
                    isAdminAllowed()) && (
                    <div style={{ textAlign: "center" }}>
                      <Button
                        onClick={() => setOpenConfirm(true)}
                        style={{
                          background: "red",
                          color: "white",
                          fontSize: "12px",
                        }}
                      >
                        Cancel Booking
                      </Button>
                    </div>
                  )}

                {curBooking.status == "NEW" &&
                  auth.info?.profile &&
                  !curBooking.approved &&
                  (curBooking.assigndFromBroker || curBooking.customBooking) &&
                  curBooking.assigndTo && (
                    <>
                      {curBooking.loadId ? (
                        <>
                          {((props.role === "shipper" &&
                            auth.info?.uid === curBooking.customer) ||
                            auth.info?.uid === curBooking.assigndFromBroker ||
                            props.role === "admin") && (
                            <div style={{ textAlign: "center" }}>
                              <Button
                                onClick={handleAcceptBooking}
                                style={{
                                  background: "green",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                Approve Booking
                              </Button>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {((props.role === "dispatcher" &&
                            curBooking?.assigndTo === auth.info.uid) ||
                            (props.role === "fleetadmin" &&
                              auth.info?.uid === curBooking.carrierId) ||
                            props.role === "admin") && (
                            <div style={{ textAlign: "center" }}>
                              <Button
                                onClick={handleAcceptBooking}
                                style={{
                                  background: "green",
                                  color: "white",
                                  fontSize: "12px",
                                }}
                              >
                                Approve Booking
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}

                {isSpecialAdmin() &&
                  curBooking.customBooking &&
                 ( curBooking.status === "ACCEPTED" ||
                 ( curBooking.status === "NEW" && curBooking?.fullPayment?.status === 'verified')) && 
                  (
                    <div>
                      <Button
                        onClick={() => editModalOpened(curBooking)}
                        style={{ background: pageBackgroundColor(),color:"white", fontSize: "12px", }}
                      >
                        Edit Booking
                      </Button>
                    </div>
                  )}

                {isSpecialAdmin() &&
                  curBooking.customBooking &&
                  curBooking.status === "REACHED" &&
                  (
                    <div style={{fontWeight:700}}>
                         Use Mobile app to complete this booking
                    </div>
                  )}

              </div>
            )}

            {curBooking.status !== "CANCELLED" &&
              auth.info?.profile &&
              giveIdThenIwillGiveYouData(curBooking?.assigndTo)?.approved &&
              (giveIdThenIwillGiveYouData(curBooking?.assigndFromBroker)
                ?.approved ||
                curBooking.manualBooking) &&
              curBooking.assigndTo &&
              curBooking.approved &&
              !curBooking.customBooking && (
                <>
                  {((props.role === "dispatcher" &&
                    curBooking?.assigndTo === auth.info.uid) ||
                    (props.role === "fleetadmin" &&
                      auth.info?.uid === curBooking.carrierId) ||
                    props.role === "admin") && (
                    <div style={{ textAlign: "center", margin: "20px 0 0 0" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Manage Booking Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={currentStatus}
                          label="Manage Booking Status"
                          onChange={handleChange}
                          disabled={
                            curBooking?.customBooking
                              ? curBooking?.fullPayment?.status !== "verified"
                              : false
                          }
                        >
                          {AvailableStatus.map((e, index) => {
                            return (
                              <MenuItem
                                disabled={!e.active}
                                key={index}
                                value={e.value}
                              >
                                {e.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {curBooking?.customBooking &&
                        curBooking?.fullPayment?.status !== "verified" && (
                          <div
                            style={{
                              marginTop: 5,
                              opacity: 1,
                              color: "#a86f32",
                            }}
                          >
                            Full Payment should be verified to change booking
                            status
                          </div>
                        )}
                    </div>
                  )}
                </>
              )}

            {props.role !== "none" && (
              <div
                style={{
                  margin: "30px 0 20px 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {((props.role === "dispatcher" &&
                  (curBooking?.assigndTo === auth.info.uid ||
                    curBooking?.assigndFromBroker === auth.info.uid)) ||
                  (isAdminAllowed() && curBooking.approved)) && (
                  <div>
                    <IconButton
                      onClick={() =>
                        setOpenShare({
                          open: true,
                          link: `http://freightcanvas.com/freight-tracking/${curBooking.id}`,
                        })
                      }
                    >
                      <ShareIcon style={{ color: "green" }} />
                    </IconButton>
                  </div>
                )}
                

                 <ReactToPrint
                    trigger={() => <Button  style={{background:pageBackgroundColor(),color:"white",textTransform:"inherit"}}>Download PDF</Button>}
                    content={() => bookingDetailsRef.current}
                  />
                  <div style={{ display: 'none' }}>
                    <PdfDetails ref={bookingDetailsRef} booking={DownloadPDF()} />
                  </div>

                {/* <Button onClick={DownloadPDF} style={{background:pageBackgroundColor(),color:"white"}}>
                    Download
                </Button> */}

                 {/**notificccation icon */}
                {curBooking.customer === auth.info?.uid && (
                  <div>
                    <Tooltip
                      title={
                        curBooking?.notifyBroker
                          ? "Do not notify driver location through email"
                          : "Notify driver location through email"
                      }
                    >
                      <IconButton
                        disabled={
                          curBooking.status !== "REACHED" &&
                          curBooking.status !== "STARTED"
                        }
                        onClick={() => NotificationClicked(curBooking)}
                        style={{
                          color:
                            (curBooking.status === "REACHED" ||
                              curBooking.status === "STARTED") &&
                            "red",
                        }}
                      >
                        {curBooking?.notifyBroker ? (
                          <NotificationsActiveIcon />
                        ) : (
                          <NotificationsOffIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}

            {props.role !== "none" &&
              !curBooking.customBooking &&
              (((curBooking.manualBooking || curBooking?.customBooking) &&
                (auth.info?.profile?.usertype === "fleetadmin" ||
                  curBooking?.assigndTo === auth.info?.uid)) ||
                (!(curBooking.manualBooking || curBooking?.customBooking) &&
                  (auth.info?.profile?.usertype === "shipper" ||
                    curBooking?.assigndFromBroker === auth.info?.uid))) && (
                <div>
                  <div>
                    <div>
                      {" "}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={earlyPickup.flag}
                            onChange={handleChangeEarlyPickUp}
                          />
                        }
                        label="Allow Early Pickup"
                      />
                    </div>
                    {/* <FormControl style={{paddingLeft:20}}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={earlyPickup.value}
                        onChange={handleEarlyPickUpValue}
                      >
                        <FormControlLabel value={1} control={<Radio  />} label="1 Day Early" />
                        <FormControlLabel value={2} control={<Radio />} label="2 Days Early" />
                        <FormControlLabel value={3} control={<Radio />} label="More Than 2 Days Early" />
                      </RadioGroup>
                   </FormControl> */}
                  </div>

                  <div>
                    <div>
                      {" "}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={earlyDropOff.flag}
                            onChange={handleChangeEarlyDropOff}
                          />
                        }
                        label="Allow Early Drop Off"
                      />
                    </div>
                    {/* {earlyDropOff.flag && 
                      <FormControl style={{paddingLeft:20}}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          value={earlyDropOff.value}
                          onChange={handleEarlyDropOffValue}
                        >
                          <FormControlLabel value={1} control={<Radio />} label="1 Day Early" />
                          <FormControlLabel value={2} control={<Radio />} label="2 Days Early" />
                          <FormControlLabel value={3} control={<Radio />} label="More Than 2 Days Early" />
                        </RadioGroup>
                     </FormControl>
                    } */}
                  </div>
                </div>
              )}
          </div>
        </div>
      </Box>

      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={editBooking.open}
        onClose={() => setEditBooking({ open: false, data: null })}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Box spacing={1} className={classes.paper} sx={{}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              marginBottom: 1,
            }}
          >
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              {" "}
              Edit Booking {editBooking?.data?.reference}
            </Typography>
            <div
              style={{
                fontSize: 12,
                color: "white",
                padding: 5,
                fontWeight: 700,
                borderRadius: 10,
                background:
                  editBooking.data?.paymentStatus === "unpaid"
                    ? "red"
                    : "green",
              }}
            >
              {editBooking.data?.paymentStatus || "Unpaid"}
            </div>
          </Box>

          {!editBooking?.data?.assigndTo && (
            <Typography
              style={{ marginTop: 5, fontWeight: 500, color: "#9c570e" }}
            >
              Please assign agent before you start customization
            </Typography>
          )}

          <Box sx={{ width: "100%" }}>
             {/* <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 5,
                width: "100%",
                flexWrap:"wrap"
              }}
             >
              <Box className={classes?.eachSettingContaiiner}>
                <FormControl
                  variant="standard"
                  fullWidth
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Allowed Payment
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={allowedPayment}
                    onChange={handleChangePaymentType}
                    label="Allowed Payment"
                    disabled={
                      !editBooking?.data?.assigndTo ||
                      (allowedPayment === "full" &&
                        editBooking?.data?.fullPayment?.status === "verified")
                    }
                  >
                    <MenuItem disabled={true} value="">
                      None
                    </MenuItem>
                    <MenuItem
                      disabled={
                        editBooking?.data?.depositPayment?.status === "verified"
                      }
                      value={"deposit"}
                    >
                      Deposit Payment
                    </MenuItem>
                    <MenuItem
                      disabled={
                        editBooking?.data?.depositPayment?.status !== "verified"
                      }
                      value={"full"}
                    >
                      Full Payment
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {allowedPayment === "deposit" && (
                <Box className={classes?.eachSettingContaiiner}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Deposit Payment Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={depositPaymentstatus}
                      onChange={handleDepositPayment}
                      label="Deposit Payment Status"
                      disabled={
                        !editBooking?.data?.assigndTo ||
                        depositPaymentstatus === "verified"
                      }
                    >
                      <MenuItem value="unpaid">Unpaid</MenuItem>
                      <MenuItem
                        disabled={
                          !editBooking?.data?.approved ||
                          depositPaymentstatus === "verified"
                        }
                        value={"processing"}
                      >
                        Processing
                      </MenuItem>
                      <MenuItem
                        disabled={!editBooking?.data?.approve || true}
                        value={"verified"}
                      >
                        Verified
                      </MenuItem>
                      <MenuItem
                        disabled={
                          !editBooking?.data?.approved ||
                          depositPaymentstatus === "verified"
                        }
                        value={"failed"}
                      >
                        Failed
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}

              {allowedPayment === "full" && (
                <Box className={classes?.eachSettingContaiiner}>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ m: 1, minWidth: 120 }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Full Payment Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={fullPaymentstatus}
                      onChange={handleFullPayment}
                      label="Full Payment Status"
                      disabled={
                        !editBooking?.data?.assigndTo ||
                        (allowedPayment === "full" &&
                          editBooking?.data?.fullPayment?.status === "verified")
                      }
                    >
                      <MenuItem value="unpaid">Unpaid</MenuItem>
                      <MenuItem value={"processing"}>Processing</MenuItem>
                      <MenuItem disabled value={"verified"}>
                        Verified
                      </MenuItem>
                      <MenuItem value={"failed"}>Failed</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box> */}

            {/* {(curBooking.manual || !curBooking.customer)&&<Box
              sx={{
                display: "flex",
                gap: 5,
                width: "100%",
                margin: "20px  0 20px 0",
                flexWrap:"wrap"
              }}
            >
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: !canAddPaymentTransactionRef(editBooking?.data)
                      ? "gray"
                      : "green",
                  }}
                >
                  Full Payment Transaction{" "}
                  {!canAddPaymentTransactionRef(editBooking?.data) && (
                    <LockIcon style={{ fontSize: 13 }} />
                  )}
                </Typography>
                <Box>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    disabled={!canAddPaymentTransactionRef(editBooking?.data)}
                    label={"Transaction ref"}
                    onChange={(e) => setPaymentTransaction(e.target.value)}
                    value={paymentTransaction}
                    className={isRTL === "rtl" ? classes.inputRtl : null}
                    style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                    placeholder="Reference"
                  />
                </Box>
              </Box>
            </Box>} */}

            {/* <Box
              sx={{
                display: "flex",
                gap: 5,
                width: "100%",
                margin: "20px  0 20px 0",
                flexWrap:"wrap"
              }}
            >
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: fullPaymentstatus !== "unpaid" ? "gray" : "green",
                  }}
                >
                  Payment Amount{" "}
                  {fullPaymentstatus !== "unpaid" && (
                    <LockIcon style={{ fontSize: 13 }} />
                  )}
                </Typography>
                <Box>
                  <TextField
                    variant="outlined"
                    type={"number"}
                    margin="normal"
                    fullWidth
                    disabled={
                      !editBooking?.data?.assigndTo ||
                      fullPaymentstatus !== "unpaid"
                    }
                    label={"Amount"}
                    onChange={handleChangePaymentAmount}
                    value={paymentAmount}
                    className={isRTL === "rtl" ? classes.inputRtl : null}
                    style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>Birr</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: fullPaymentstatus !== "unpaid" ? "gray" : "green",
                  }}
                >
                  Load Weight{" "}
                  {fullPaymentstatus !== "unpaid" && (
                    <LockIcon style={{ fontSize: 13 }} />
                  )}
                </Typography>
                <Box>
                  <TextField
                    variant="outlined"
                    type={"number"}
                    margin="normal"
                    fullWidth
                    label={"Amount"}
                    disabled={
                      !editBooking?.data?.assigndTo ||
                      fullPaymentstatus !== "unpaid"
                    }
                    onChange={handleChangeBookingLoadWeight}
                    value={bookingLoadWeight}
                    className={isRTL === "rtl" ? classes.inputRtl : null}
                    style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span>Kg</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box> */}

            <Box
              sx={{
                display: "flex",
                gap: 5,
                width: "100%",
                margin: "20px  0 20px 0",
                flexWrap:"wrap"
              }}
            >
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: fullPaymentstatus !== "verified" ? "gray" : "green",
                  }}
                >
                  Pickup Date{" "}
                  {fullPaymentstatus !== "verified" && (
                    <LockIcon style={{ fontSize: 13 }} />
                  )}
                </Typography>
                <Box>
                  <TextField
                    disabled={
                      !editBooking?.data?.assigndTo ||
                      fullPaymentstatus !== "verified"
                    }
                    id="datetime-local"
                    type="datetime-local"
                    variant="filled"
                    fullWidth
                    className={
                      isRTL === "rtl"
                        ? [classes.inputRtl, classes.commonInputStyle]
                        : classes.commonInputStyle
                    }
                    InputProps={{
                      className: classes.input,
                      style: {
                        textAlignLast: isRTL === "rtl" ? "end" : "start",
                        height: "58px",
                      },
                    }}
                    value={pickupDate}
                    onChange={onPickupDateChange}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    color: fullPaymentstatus !== "verified" ? "gray" : "green",
                  }}
                >
                  Drop Date{" "}
                  {fullPaymentstatus !== "verified" && (
                    <LockIcon style={{ fontSize: 13 }} />
                  )}
                </Typography>
                <Box>
                  <TextField
                    disabled={
                      !editBooking?.data?.assigndTo ||
                      fullPaymentstatus !== "verified"
                    }
                    id="datetime-local"
                    type="datetime-local"
                    variant="filled"
                    fullWidth
                    className={
                      isRTL === "rtl"
                        ? [classes.inputRtl, classes.commonInputStyle]
                        : classes.commonInputStyle
                    }
                    InputProps={{
                      className: classes.input,
                      style: {
                        textAlignLast: isRTL === "rtl" ? "end" : "start",
                        height: "58px",
                      },
                    }}
                    value={dropDate}
                    onChange={onDropDateChange}
                  />
                </Box>
              </Box>
            </Box>

            <Box style={{ width: "100%", marginTop: 6 }}>
              <Grid item xs={12}>
                {drivers && drivers.length > 0 ? (
                  <UsersCombo
                    disabled={
                      !editBooking?.data?.assigndTo ||
                      fullPaymentstatus !== "verified"
                    }
                    className={classes.items}
                    placeholder={
                      !editBooking?.data?.assigndTo ||
                      fullPaymentstatus !== "verified"
                        ? "Select Drivers ( Locked )"
                        : "Select Drivers"
                    }
                    users={drivers}
                    value={driverCombo}
                    onChange={(event, newValue) => {
                      setDriverCombo(newValue);
                    }}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: 17,
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    Drivers Not Found
                  </div>
                )}
              </Grid>
            </Box>

            <Box sx={{ width: "100%", marginTop: 3 }}>
              <Button
                onClick={updateTargetBooking}
                disabled={!editBooking?.data?.assigndTo}
                style={{ background: "green", color: "white", width: "100%" }}
              >
                Update Booking
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {openShare.open && (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={openShare.open}
          onClose={handleCloseCallModal} // assuming you have a function to handle modal close
          className={classes.modal}
          container={() => rootRef.current}
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            className={classes.paper}
            style={{ flexDirection: "column", color: "black" }}
          >
            <div
              style={{
                fontSize: "20px",
              }}
            >
              <Link href={openShare?.link} target="__blank">
                {openShare?.link}
              </Link>
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <button
                onClick={handleShareCopyClick}
                className={classes.callButtons}
              >
                {linkCopied ? <span>Copied</span> : <span>Copy</span>}
              </button>
            </div>
          </Grid>
        </Modal>
      )}

      {openCall.open && (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={openCall.open}
          onClose={handleCloseCallModal} // assuming you have a function to handle modal close
          className={classes.modal}
          container={() => rootRef.current}
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            className={classes.paper}
            style={{ flexDirection: "column", color: "black" }}
          >
            <div
              style={{
                fontSize: "20px",
              }}
            >
              {openCall?.mobile}
            </div>
            <div style={{ display: "flex", gap: "20px", marginTop: "10px" }}>
              <button onClick={handleCopyClick} className={classes.callButtons}>
                {phoneCopied ? <span>Copied</span> : <span>Copy</span>}
              </button>
              <button
                onClick={() => {
                  handleCallClick();
                  setOpenCall({ open: false, mobile: null });
                }}
                className={classes.callButtons}
              >
                Call
              </button>
            </div>
          </Grid>
        </Modal>
      )}

      {openEmail.open && (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={openEmail.open}
          onClose={handleCloseEmailModal} // assuming you have a function to handle modal close
          className={classes.modal}
          container={() => rootRef.current}
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            className={classes.paper}
            style={{ flexDirection: "column", color: "black" }}
          >
            <div
              style={{
                fontSize: "20px",
              }}
            >
              {openEmail?.email}
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "10px",
                justifyContent: "center",
              }}
            >
              <button
                onClick={handleCopyEmailClick}
                className={classes.callButtons}
              >
                {emailCopied ? <span>Copied</span> : <span>Copy</span>}
              </button>
              <button
                onClick={() => {
                  handleEmailClick();
                  setOpenEmail({ open: false, email: null });
                }}
                className={classes.callButtons}
              >
                Mail
              </button>
            </div>
          </Grid>
        </Modal>
      )}

      {openChat &&
        !(curBooking.manualBooking || curBooking?.customBooking) &&
        ((props.role === "dispatcher" &&
          (curBooking?.assigndTo === auth.info.uid ||
            curBooking?.assigndFromBroker === auth.info.uid)) ||
          isAdminAllowed()) && (
          <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            open={openChat}
            onClose={handleCloseChatModal}
            className={classes.modal}
            container={() => rootRef.current}
            sx={{ wiidth: "100%" }}
          >
            <ChatMessage
              userdata={userdata}
              pageBackgroundColor={pageBackgroundColor}
              role={props.role}
              curBooking={curBooking}
              setOpenChat={setOpenChat}
              messagesEndRef={messagesEndRef}
              scrollToButtom={scrollToButtom}
            />
          </Modal>
        )}

      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      />
    </div>
  );
});

const PdfDetails = React.forwardRef(({ booking }, ref) => {
  const [qrCodeURL, setQRCodeURL] = useState('');


  useEffect(() => {
    // Generate QR code for the booking id
    QRCode.toDataURL(booking.id).then((url) => {
      setQRCodeURL(url);
    });
  }, [booking.id]);


  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        background: '#f9f9f9',
        color: '#333',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <h2 style={{ marginBottom: '10px',fontWeight:'bold', color: '#007bff' }}>Delivery Details</h2>
      {qrCodeURL && (
        <img
          src={qrCodeURL}
          alt="QR Code"
          style={{
            width: '400px',
            height: '400px',
            marginBottom: '0px',
            border: '1px solid #ddd',
            borderRadius: '8px',
          }}
        />
      )}
      <h3
        style={{
          marginBottom: '0px',
          fontSize: '24px',
          fontWeight: 'bold',
          color: '#333',
          textAlign: 'center',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
        }}
      >
          {booking?.reference}
      </h3>
      <div
        style={{
          width: '100%',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
          marginBottom: '3px',
        }}
      >
        <p>
          <strong>Shipper Name: </strong>
          {booking.shipperName}
        </p>
        <p>
          <strong>Shipper Phone: </strong>
          {booking.shipperPhone}
        </p>
        <p>
          <strong>Receiver Name: </strong>
          {booking.merchantName}
        </p>
        <p>
          <strong>Receiver Phone: </strong>
          {booking.merchantPhone}
        </p>
        <p>
          <strong>Pickup Address: </strong>
          {booking.pickup}
        </p>
        <p>
          <strong>Drop Address: </strong>
          {booking.drop}
        </p>
        {/* <p>
          <strong>Pickup Date: </strong>
          {moment(booking.pickupDate).format('LLL')}
        </p>
        <p>
          <strong>Drop Date: </strong>
          {moment(booking.dropDate).format('LLL')}
        </p> */}
      </div>
    </div>
  );
})


const ChatMessage = ({
  setOpenChat,
  curBooking,
  role,
  messagesEndRef,
  scrollToButtom,
  userdata,
  pageBackgroundColor,
}) => {
  const { api } = useContext(FirebaseContext);

  const { fetchChatMessages, sendMessage, messageSeen } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const allMessages = useSelector((state) => state.chatdata.messages);
  const isLoading = useSelector((state) => state.chatdata.loading);
  useEffect(() => {
    scrollToButtom();
  }, [allMessages]);

  const giveIdThenIwillGiveYouData = (id) => {
    if (!userdata.users) return null;
    const targetUser = userdata.users.filter((e) => {
      return e.id === id;
    });
    if (targetUser.length > 0) return targetUser[0];
    return null;
  };

  useEffect(() => {
    if (curBooking.chatId) {
      if (auth.info?.profile?.dispatcherId && userdata.users) {
        let ownerData = giveIdThenIwillGiveYouData(
          auth.info?.profile?.dispatcherId
        );
        dispatch(
          fetchChatMessages({ chatId: curBooking.chatId, user: ownerData })
        );
      } else {
        dispatch(
          fetchChatMessages({ chatId: curBooking.chatId, user: auth.info.uid })
        );
      }
    }
  }, [fetchChatMessages, dispatch, userdata.users, curBooking]);

  const [inputMessage, setInputMessage] = useState("");

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      send_message();
    }
  };

  const send_message = () => {
    let message = inputMessage.trim();
    let isShipperSending =
      curBooking.assigndFromBroker === auth.info?.uid || role === "shipper";
    if (!isShipperSending && isSuperDispatcher()) {
      if (
        giveIdThenIwillGiveYouData(curBooking.assigndFromBroker)
          .dispatcherId === auth.info?.uid
      ) {
        isShipperSending = true;
      }
    }
    if (message) {
      dispatch(
        sendMessage({
          booking: curBooking,
          role: role,
          message: inputMessage,
          isShipperSending: isShipperSending,
        })
      );
    }
    setInputMessage("");
  };

  function isSuperDispatcher() {
    return (
      auth.info.profile.usertype === "dispatcher" &&
      auth.info.profile.independent
    );
  }

  function amSender(from) {
    const isAssigndFromBroker = curBooking.assigndFromBroker === auth.info?.uid;
    const isAssigndFromCarrier = curBooking.assigndTo === auth.info?.uid;
    if (role === "shipper" || isAssigndFromBroker) {
      return curBooking.customer === from;
    } else if (isAssigndFromCarrier || role == "fleetadmin") {
      return curBooking.carrierId === from;
    } else if (isSuperDispatcher()) {
      if (
        giveIdThenIwillGiveYouData(curBooking.assigndFromBroker)
          .dispatcherId === auth.info?.uid
      ) {
        return curBooking.customer === from;
      } else if (
        giveIdThenIwillGiveYouData(curBooking.assigndTo).dispatcherId ===
        auth.info?.uid
      ) {
        return curBooking.carrierId === from;
      }
    }

    // if (auth.info.profile.usertype === "shipper") {
    //   return auth.info.uid === from;
    // } else if (auth.info.profile.usertype === "dispatcher") {
    //   return auth.info.profile.carrierId === from || auth.info.profile.brokerId ===from;
    // } else {
    //   return auth.info.uid === from;
    // }
  }

  const classes = useStyles();

  const markAsRead = (message) => {
    const isAssigndFromBroker = curBooking.assigndFromBroker === auth.info?.uid;
    const isAssigndFromCarrier = curBooking.assigndTo === auth.info?.uid;

    //const isThirdPartyDispatcherAgentFromBrokerSide = auth.info.profile?.dispatcherId ? curBooking.assigndFromBroker===auth.info?.uid : false

    if (role === "shipper" || isAssigndFromBroker) {
      dispatch(messageSeen({ message, currentUserId: curBooking.customer }));
    }
    // else  if (isAssigndFromBroker) {
    //   dispatch(messageSeen({ message, currentUserId: auth.info?.profile?.brokerId }))
    // }
    else if (isAssigndFromCarrier || role == "fleetadmin") {
      dispatch(messageSeen({ message, currentUserId: curBooking.carrierId }));
    } else if (isSuperDispatcher()) {
      if (
        giveIdThenIwillGiveYouData(curBooking.assigndFromBroker)
          .dispatcherId === auth.info?.uid
      ) {
        dispatch(messageSeen({ message, currentUserId: curBooking.customer }));
      } else if (
        giveIdThenIwillGiveYouData(curBooking.assigndTo).dispatcherId ===
        auth.info?.uid
      ) {
        dispatch(messageSeen({ message, currentUserId: curBooking.carrierId }));
      }
    }
  };

  useEffect(() => {
    allMessages
      .filter((e) => !e.isRead)
      .forEach((unReadMsg) => markAsRead(unReadMsg));
  }, [allMessages, markAsRead]);

  return (
    <div className={classes.chatModal}>
      {isLoading ? (
        <div
          style={{
            textAlign: "center",
            color: pageBackgroundColor(),
            paddingTop: "40%",
          }}
          className={classes.chates}
        >
          <CircularProgress style={{ fontSize: "40px" }} />
        </div>
      ) : (
        <div className={classes.chates}>
          {allMessages && allMessages.length > 0 ? (
            <div ref={messagesEndRef}>
              {allMessages.map((e) => {
                return (
                  <div
                    key={e.id}
                    style={{
                      marginBottom: "10px",
                      //  maxWidth:"200px",
                      textAlign: amSender(e.from) ? "end" : "start",
                    }}
                    // onScroll={() => markAsRead(e)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent:
                          (amSender(e.from) ||
                            (role === "admin" && e.source === "fleetadmin")) &&
                          "flex-end",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      {!amSender(e.from) && (
                        <div>
                          <Link
                            href={`/user/${
                              auth.info.profile.usertype !== "shipper"
                                ? e.from
                                : curBooking?.assigndTo || e.from
                            }?redirected=tracking/${curBooking.id}`}
                          >
                            <AccountCircleIcon
                              style={{
                                fontSize: "30px",
                                color: pageBackgroundColor(),
                              }}
                            />
                          </Link>
                        </div>
                      )}
                      <span
                        style={{
                          lineBreak: "anywhere",
                          color:
                            amSender(e.from) ||
                            (role === "admin" && e.source === "fleetadmin")
                              ? pageBackgroundColor()
                              : "black",
                          maxWidth: "300px",
                        }}
                      >
                        {e.message}
                      </span>
                    </div>
                    <hr style={{ marginTop: "10px", opacity: 0.3 }} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
                color: pageBackgroundColor(),
                lineBreak: "anywhere",
              }}
            >
              No Message Found. We kindly request you to promptly engage in
              negotiation and approve the meeting before it is automatically
              canceled.{" "}
            </div>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <TextField
            className={classes.chatTextArea}
            placeholder="Text"
            value={inputMessage}
            disabled={role === "admin"}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleEnterPress}
          />
        </div>
        <div>
          <IconButton disabled={role === "admin"} onClick={send_message}>
            <SendIcon
              style={{ fontSize: "20px", color: pageBackgroundColor() }}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default TruckingMap;
