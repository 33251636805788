import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
}from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AppMenu from "../views/AppMenu";
import {colors} from '../components/Theme/WebTheme';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from "react-i18next";
import { Grid, Menu, MenuItem, Modal } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseContext } from 'common';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButtonRight: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  menuButtonLeft: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width:drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  pickupDropModals: {
    display: 'flex', 
    justifyContent: 'center', 
    gap: '20px', 
    alignItems: 'center', 
    height: '200px', 
    borderRadius: '10px', 
    position: 'absolute', 
    backgroundColor: '#fff', 
    padding: '20px', 
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    '@media(max-width: 1100px)': {
    },
    '@media(max-width: 800px)': {
    },
    '@media(max-width: 600px)': {
    },
    '@media(max-width: 530px)': {
      padding: '10px',
      flexDirection:"column"
    }
  }
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { api } = useContext(FirebaseContext);
  const auth = useSelector(state => state.auth);
  const userdata = useSelector(state => state.usersdata);


  const {
    signOut,
  } = api;
  const dispatch = useDispatch();

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawerWidth = 240;

  const { i18n  } = useTranslation();
  const isRTL = i18n.dir();

  const rootRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const profileClicked=()=>{
    props.children.props.history.push("/profile")
  }

  const LogOut = () => {
    dispatch(signOut());
  };
  const [sampleModal,setSampleModAL]=useState(false)

  const handleSmapleModal=()=>{
    setSampleModAL(false)
   }


    const giveIdThenIwillGiveYouData=(id)=>{
      const targetUser =userdata.users.filter(e=>{
        return e.id===id
      })
      if(targetUser.length>0) return targetUser[0]
      return null

    }
    const pageBackgroundColor=()=>{
        if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
        if(giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.carrierId)?.dashboardColor
        return "#01aac1"
    }
  return (
    <>
    <div className={classes.root} style={{direction:isRTL === 'rtl'? 'rtl':'ltr'}}>
      <CssBaseline />
      <AppBar  className={classes.appBar} style={isRTL=== 'rtl'? {marginRight:drawerWidth}:{marginLeft:drawerWidth}}>
        <Toolbar style={{backgroundColor:pageBackgroundColor(), display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={isRTL==='rtl'? classes.menuButtonLeft:classes.menuButtonRight}
          >
            <MenuIcon />
          </IconButton>
          <div style={{textAlign:"end",width:"100%"}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <AccountCircleIcon style={{fontSize:"40px",}} />
            </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={profileClicked}>Profile</MenuItem>
                <MenuItem onClick={()=>setSampleModAL(true)}>Log Out</MenuItem>
              </Menu>

          </div>

          {/* <Typography variant="h6" noWrap>
            {settings && settings.appName? settings.appName: '' }
          </Typography> */}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={isRTL === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <AppMenu handleDrawerToggle={handleDrawerToggle} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            anchor={isRTL === 'rtl' ? 'right' : 'left'}
            open
          >
            <AppMenu/>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} style={{overflow:'auto', overflowX:'hidden'}}>
        <div className={classes.toolbar}/>
        {props.children}
      </main>
    </div>
     <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={sampleModal}
        onClose={handleSmapleModal}
        className={classes.modal}
        container={() => rootRef.current}
        style={{
          overflow: "auto",
          height: "100vh",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          spacing={1}
          className={classes.pickupDropModals}
          style={{ display: 'flex', flexDirection: 'column',width:"80%",maxWidth:"400px",lineBreak:"anywhere" }}
        >
          <div style={{fontSize: '20px', color:pageBackgroundColor()}}>Are you sure do you want to log out ?</div>
          <div style={{display: 'flex', gap: '10px',flexWrap:"wrap"}}>
            <button onClick={LogOut} style={{backgroundColor:pageBackgroundColor(), borderRadius: '10px', border: '0', padding: '10px', cursor: 'pointer', color: '#fff', width: '80px'}}>Yes</button>
            <button onClick={()=>setSampleModAL(false)} style={{backgroundColor: pageBackgroundColor(), borderRadius: '10px', border: '0', padding: '10px', cursor: 'pointer', color: '#fff', width: '80px'}}>No</button>
          </div>
        </Grid>
      </Modal>
    </>
  );
}

ResponsiveDrawer.propTypes = {
  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default ResponsiveDrawer;
