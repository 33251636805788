import React,{ useState, useEffect, useContext,useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import AdjustIcon from '@mui/icons-material/Adjust';
import Slider from 'react-slick';

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists,
    fetchUsersOnce
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);

  useEffect(()=>{
    dispatch(fetchUsersOnce());
},[dispatch,fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
      setData(staticusers.filter(user => user.usertype ==='fleetadmin'));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers]);

  function ExtractDataOfShippers(shippers){
    let shipperDatas=[] 
    for( const shipper of shippers){
      const filterUser=staticusers.filter(user => user.id ===shipper.id)
      if(filterUser.length>0){
        let shipperData={
           name:`${filterUser[0].firstName} ${filterUser[0].lastName}`,
           aprovedByBroker:shipper.aprovedByBroker,
           approved:shipper.approved,
        }
        shipperDatas.push(shipperData)
      }
    }

    return shipperDatas
  }

  const SharedBrokersSlider=({slides})=>{
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const scrollbarStyles = {
      width: '300px',
      overflowX: 'hidden',
      padding: '3px 5px 0 5px',
      textAlign: 'center',
      overflowY: 'auto',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '::webkitScrollbar': {
        display: 'none',
        width:'0px'
      },
    };
    return(
      <Slider {...settings} style={scrollbarStyles} >
          {slides.map((slide,index)=>{
              return (
                <div key={index} style={{height:"400px",background:"red",width:"100%",display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",marginTop:"10px"}}>
                  <div style={{fontWeight:"600", fontSize: '14px'}}>{slide.name}</div>
                  <div style={{display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center', margin: 10, fontWeight: '200', fontSize: '13px'}}>
                      <div>
                        Broker
                        <div>
                          <AdjustIcon style={{color:slide.aprovedByBroker?"green":"red",fontSize:"16px"}}/>
                        </div>
                      </div>
                      <div>
                        Carrier
                        <div><AdjustIcon style={{color:slide.approved?"green":"red",fontSize:"16px"}}/></div>
                      </div>
                  </div>
                  <div style={{marginTop:"5px",fontWeight:"800",color:"#000"}}>{index+1} of {slides.length}</div>
                </div>
                )
          })}
      </Slider>
    )
  }

  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll') :null},
    { title: t('first_name'), field: 'firstName', initialEditValue: '', 
      render: rowData =><div style={{color:!rowData.approved&&"red"}}> {rowData.firstName}</div>,
      cellStyle:{ paddingLeft:isRTL ==='rtl'? 50 : 15}},
    { title: t('last_name'), field: 'lastName', initialEditValue: '', 
      render: rowData =><div style={{color:!rowData.approved&&"red"}}> {rowData.lastName}</div>,
      cellStyle:{ paddingLeft:isRTL ==='rtl'? 30 : 15}},
    { title: t('mobile'), field: 'mobile', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.mobile : "Hidden for Demo"},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",headerStyle:{textAlign:'center'}},
    { title: "DOT Number", field: 'dotNumber', editable:'never',
    render: rowData => <div>
        {rowData?.carrierData?
           <div>
                {rowData?.carrierData?.dotNumber||""}
           </div>
         :"Not Added Yet"}
  
    </div>,
    cellStyle:{width:150,minWidth:150}
    },
    { title: "DBA Name", field: 'dbaName', editable:'never',
    render: rowData => <div>
        {rowData?.carrierData?
           <div>
                {rowData?.carrierData?.dbaName||""}
           </div>
         :"Not Added Yet"}
  
    </div>,
     cellStyle:{width:150,minWidth:150}
    },
    { title: "Legal Name", field: 'legalName', editable:'never',
    render: rowData => <div>
        {rowData?.carrierData?
           <div>
                {rowData.carrierData?.legalName||""}
           </div>
         :"Not Added Yet"}
  
    </div>,
     cellStyle:{width:200,minWidth:200}
    },
    { title: "Allowed to operate", field: 'Allowed', editable:'never',
    render: rowData => <div>
        {rowData?.carrierData?
           <div>
                {rowData.carrierData?.allowedToOperate==="Y"?"Yes":"No"}
           </div>
         :"Not Added Yet"}
  
    </div>,
    },
    { title: "Address", field: 'Address', editable:'never',
    render: rowData => <div>
        {rowData?.carrierData?
           <div>
                {rowData.carrierData?.phyCountry}, {rowData.carrierData?.phyState}, 
                {rowData.carrierData?.phyCity}, {rowData.carrierData?.phyStreet}
           </div>
         :"Not Added Yet"}
  
    </div>,
     cellStyle:{width:200,minWidth:200}
    },
    { title: t('account_approve'),  field: 'approved', type:'boolean', initialEditValue: true, cellStyle:{ textAlign: 'center'}},

    { title: "Shared Brokers",  field: 'SharedBrokers',editable:'never',
    render:rowData=><div>
         {rowData?.SharedBrokers?.length>0?
            <div>
                {ExtractDataOfShippers(rowData.SharedBrokers).length>0?
                <SharedBrokersSlider slides={ExtractDataOfShippers(rowData.SharedBrokers)} />
                  //  <div style={{display:"flex",justifyContent:"flex-start",flexDirection:"column",alignItems:"flex-start"}}>
                  //        {ExtractDataOfShippers(rowData.SharedBrokers).map(e=>{
                  //           return(
                  //             <div key={e.name}>
                  //                 <div style={{fontWeight:"700"}}>{e.name}</div>
                  //                 <div style={{marginLeft:"10px",display:"flex",justifyContent:"flex-start",flexDirection:"column",alignItems:"flex-start"}}>
                  //                      <div>Broker: <AdjustIcon style={{color:e.aprovedByBroker?"green":"red",fontSize:"16px"}}/></div>
                  //                      <div>Carrier: <AdjustIcon style={{color:e.approved?"green":"red",fontSize:"16px"}}/></div>
                  //                 </div>
                  //             </div>
                  //           )
                  //        })}
                  //  </div>
                   :
                   <div>Not Added Yet</div>

                 }
            </div>
            :
            <div>Not Added Yet</div>
          }
    </div>,
    cellStyle:{textAlign:'center',width:300,minWidth:300, backgroundColor: '#01aac1', color: 'white'} ,
    headerStyle:{textAlign:'center',width:300,minWidth:300,backgroundColor: '#01aac1', color: 'white', fontWeight: '900', fontSize: '16px'} ,
  },

  ];

  return (
    !loaded.current? <CircularLoading/>:
    <MaterialTable
      title={t('fleetadmins')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
      }}
      localization={{body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
      editRow: { 
        deleteText: (t('delete_message')),
        cancelTooltip: (t('cancel')),
        saveTooltip: (t('save')) 
          }, 
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      editable={{
        onRowAdd: newData =>
        new Promise((resolve,reject) => {
          setTimeout(() => {
            checkUserExists(newData).then((res) => {
              if (res.users && res.users.length > 0) {
                alert(t('user_exists'));
                reject();
              }
              else if(res.error){
                alert(t('email_or_mobile_issue'));
                reject();
              }
              else{
                newData['regType'] = 'admin';
                newData['usertype'] = 'fleetadmin';
                newData['createdAt'] = new Date().toISOString();
                dispatch(addUser(newData));
                dispatch(fetchUsersOnce());
                resolve();
              }
            });
          }, 600);
        }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
              dispatch(fetchUsersOnce());
            }, 600);
          }),
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
              dispatch(fetchUsersOnce());
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
          , 
      }}
    />
  );
}
