import { title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent:'center',
    height: '82vh',
    '@media(max-height: 3000px)': {
      height: '94vh'
    },
    '@media(max-height: 2200px)': {
      height: '91.95vh'
    },
    '@media(max-height: 1500px)': {
      height: '88vh'
    },
    '@media(max-height: 1100px)': {
      height: '84vh'
    },
    '@media(max-height: 1000px)': {
      height: '82vh'
    },
    '@media(max-height: 930px)': {
      height: '80.78vh'
    },
    '@media(max-height: 850px)': {
      height: '78.5vh'
    },
    '@media(max-height: 730px)': {
      height: '76vh'
    },
    '@media(max-height: 670px)': {
      height: '73.7vh'
    },
    '@media(max-height: 630px)': {
      height: '70.2vh'
    },
    '@media(max-height: 580px)': {
      height: '79vh'
    },
    '@media(max-height: 500px)': {
      height: '100vh'
    },
    '@media(max-height: 400px)': {
      height: '120vh'
    },
    '@media(max-height: 300px)': {
      height: '135vh'
    },
    '@media(max-height: 200px)': {
      height: '180vh'
    },
  },
  tableMapContainer: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent:'center',
  },
  paragraph: {
    position: "relative",
    minHeight: 32,
    color: "#FFFFFF",
    textDecoration: "none",
    fontSize: "1.6rem",
    fontFamily: 'ABeeZee',
    margin: '30% 15% 15px 5%',
    '@media(max-width: 1100px)': {
      marginRight: '20%',
      marginTop: '15%'
    },
    '@media(max-width: 950px)': {
      marginRight: '40%',
      marginTop: '15%'
    },
    '@media(max-width: 600px)': {
      marginRight: '20%'
    }
  },
  containerInput: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent:'center',
    width: "30vw",
    paddingRight: "20px",
    // marginLeft: "20px",
    "@media (max-width: 768px)": {
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      width: "70vw",
      marginTop: "15px",
      paddingRight: "0",
    },
    marginTop: 10
  },
  matchAndClearContainer: {
    display: "flex", 
    // flexDirection: "column",
    "@media (max-width: 1690px)": {
      justifyContent: "space-evenly",
    },
    "@media (max-width: 790px)": {
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      flexDirection: "row"
    }
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    fontSize:"2.955rem"
  },
  gridcontainer:{
    alignContent:'center'
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
    // marginTop: "100px"
  },
  mainRaised: {
    // margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  mainRouteRaised: {
    width: "75vw",
    margin: "45px 20px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  main2: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised2: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  items:{
    margin:0,
    width:'100%'
  },
  menuItems:{
    width:'100%'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor:  "#E8E8E8",
    fontSize: 16,
    padding: '10px 2px 10px 10px',
    color:"#000"
  },
  inputdimmed: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor:  "#E8E8E8",
    fontSize: 16,
    padding: '10px 26px 10px 10px',
    color:"#737373"
  },
  commonInputStyle:{
    borderRadius: 4,
    backgroundColor: "#E8E8E8",
  },
  carphoto:{
    height: '16px',
    marginRight:'10px'
  },
  carphotoRtl:{
    height: '16px',
    marginLeft:'10px'
  },
  inputRtl: {
    "& label": {
      right: 25,
      left: "auto"
    },
    "& legend": {
      textAlign: "right",
      marginRight: 18
    }
  },
  rightRty:{
    "& legend": {
      marginRight: 30
    }
  }
};

export default landingPageStyle;
