/*eslint-disable*/
import React from "react";
import styled from "styled-components";
import { Link } from "@material-ui/core";
import { Box } from "@mui/material";
import LinkedIn from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';


export default function Footer(props) {


  return (
    <Div70>
    <Div71>
      <Div72>
        <Div73>
          <Div74>
            <Column13>
              <Div75>
                <Div76>
                  <Img17
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/803a7b3594103781d9494f4b6e81b96a3978f59ca9d8a5a87fc21175a7405431?apiKey=88f42c79671748f8ba99fcb90941995a&"
                  />
                </Div76>
              </Div75>
            </Column13>
            <Column14>
              <Div79>
                <Div80>Learn More</Div80>
                <Div81>
                  <Link style={{color:"inherit",textDecoration:"none"}} href="/about">About F.C</Link>
                  <br />
                  <Link style={{color:"inherit",textDecoration:"none"}} href="/privacy-policy"> Privacy Policy</Link>
                  <br />
                  
                </Div81>
              </Div79>
            </Column14>
            <Column15>
              <Div82>
                <Div83>
                  <Div84>
                    <Div85>
                      <Div86>Contact Us</Div86>
                      <Div87>Tech Support:</Div87>
                    </Div85>
                    <Div88>952-855-2202</Div88>
                  </Div84>
                </Div83>
                <Div91>
                  <Div92>Info:</Div92>
                  <Div93>952-228-3151</Div93>
                </Div91>
              </Div82>
            </Column15>
            <Column15>
              <Div82>
                <Div83>
                  <Div89>
                    <Div90>Social</Div90>
                    <Link style={{color:"inherit",textDecoration:"none"}} href="https://www.linkedin.com/company/freightcanvas"><LinkedIn  style={{ color: 'white' }}/></Link>
                  </Div89>
                </Div83>
                
              </Div82>
            </Column15>
           
          </Div74>
        </Div73>
        <Div94 />
        <Div95>© 2024 Freight Canvas | All Rights Reserved</Div95>
      </Div72>
    </Div71>
  </Div70>
  );
}


const Div70 = styled.div`
  background-color: #0b305a;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {

  }
`;
const Div71 = styled.div`
  background-color: #0b3764;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;
const Div72 = styled.div`
  display: flex;
  margin-top: 26px;
  width: 100%;
  max-width: 1075px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div73 = styled.div`
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div74 = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;
const Column13 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 36%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Div75 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #fff;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const Div76 = styled.div`
  display: flex;
  alight-items: flex-end;
  justfiy-content: flex-end;
  width: 100%;
`;
const Img17 = styled.img`
  aspect-ratio: 5.26;
  object-fit: auto;
  object-position: center;
  width: 201px;
  max-width: 100%;
`;
const Div77 = styled.div`
  font-family: Poppins, sans-serif;
  align-self: start;
  flex-grow: 1;
  flex-basis: auto;
`;
const Div78 = styled.div`
  margin-top: 7px;
  font: 400 14px/30px Open Sans, sans-serif;
`;
const Column14 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 15%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Div79 = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const Div80 = styled.div`
  font: 300 18px Poppins, sans-serif;
`;
const Div81 = styled.div`
  margin-top: 32px;
  font: 400 14px/30px Open Sans, sans-serif;
`;
const Column15 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 49%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Div82 = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
const Div83 = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;
const Div84 = styled.div`
  align-self: start;
  display: flex;
  gap: 18px;
`;
const Div85 = styled.div`
  display: flex;
  flex-direction: column;
`;
const Div86 = styled.div`
  font: 300 18px Poppins, sans-serif;
`;
const Div87 = styled.div`
  margin-top: 33px;
  font: 400 14px Open Sans, sans-serif;
`;
const Div88 = styled.div`
  align-self: end;
  margin-top: 48px;
  font: 400 14px Open Sans, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const Div89 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 300;
  white-space: nowrap;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;
const Div90 = styled.div`
  font-family: Poppins, sans-serif;
  margin-bottom: 23px;
`;
const Img18 = styled.img`
  aspect-ratio: 8.33;
  object-fit: auto;
  object-position: center;
  width: 146px;
  align-self: center;
  margin-top: 33px;
`;
const Div91 = styled.div`
  align-self: start;
  display: flex;
  margin-top: 15px;
  gap: 20px;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
`;
const Div92 = styled.div`
  font-family: Open Sans, sans-serif;
`;
const Div93 = styled.div`
  font-family: Open Sans, sans-serif;
`;
const Div94 = styled.div`
  border-color: rgba(255, 255, 255, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  margin-top: 65px;
  height: 2px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;
const Div95 = styled.div`
  color: #fff;
  text-align: center;
  align-self: center;
  margin-top: 33px;
  font: 300 14px Montserrat, sans-serif;
`;


