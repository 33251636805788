export const colors = {
    TRANSPARENT: 'transparent',
    WHITE: '#fff',
    DARK: '#01aac1',
    BLACK: '#000',
    RED: 'red',
    SKY: '#1E81D3',
    LIGHT_RED:"#FF2121",
    DULL_RED: "#B41B00",
    LIGHT_YELLOW: "#dbd6a0",
    HEADER: '#01aac1',
    SIDEMENU:"white",
    PROFILE_PLACEHOLDER_TEXT: '#01aac1',
    PROFILE_PLACEHOLDER_CONTENT: '#4f4e4e',
    CAMERA_TEXT: '#01aac1',
    BUTTON_RIGHT: '#666666',
    RADIO_BUTTON: '#9b9b9b',
    RADIO_BUTTON_SELECTION: '#8a8a8a',
    BUTTON_BACKGROUND: '#01aac1',
    BACKGROUND_PRIMARY: '#f5f1f1',
    DRIVER_TRIPS_TEXT: '#01aac1',
    DRIVER_TRIPS_BUTTON: "#01aac1",
    DRIVER_MSG_BACKGROUND: "white",
    DRIVER_RATING_TEXT: "#01aac1",
    SHIPMENTLIST_TEXT: 'black',
    SHIPMENTDETAILS_TEXT: "gray",
    PAYMENT_BUTTON_BLUE: 'black',
    SEARCH_TEXT: "white",
    STAR: "#01aac1",
    MAP_SQUARE: "#9e9e9e",
    MAP_TEXT: '#01aac1',
    CONVERTDRIVER_TEXT: "#01aac1",
    BORDER_TEXT: "gray",
    ONLINE_CHAT_BACKGROUND: "#d9d9d9",
    SELECT_GATEWAY_BACKGROUND: "#dedede",
    BUTTON: "#01aac1",
    BACKGROUND: "rgba(22,22,22,0.8)",
    BORDER_BACKGROUND: "#01aac1",
    WALLET_PRIMARY: "white",
    PROMO: "#9f9f9f",
    GREEN_DOT: "green",
    BALANCE_GREEN:'#01aac1', 
    BUTTON_YELLOW: "black",
    BUTTON_ORANGE: "gray",
    DRIVER_INCOME_YELLOW: "black",
    BOX_BG: 'silver',
    BUTTON_LOADING: "#01aac1",
    INDICATOR_BLUE: "#007aff",
    FOOTERTOP: "#cccccc",
    START_TRIP: "#018E16",
    RE_GREEN:"#7ED957"
  }