import React, { useState, useEffect, useContext, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { CircularProgress} from "@material-ui/core";
import { FirebaseContext } from "common";
import { makeStyles } from "@material-ui/core/styles";
import Polyline from '@mapbox/polyline';

import TruckinMap from '../components/TruckinMap';

import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { colors } from '../components/Truck/theme';
import { Button } from "@mui/material";



const Trucking =(props)=> {
    
      const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    
    const params=useParams()
    const { api } = useContext(FirebaseContext);
    const {
        fetchBookingLocations,
        getDirectionsApi,
        fetchSingleBook,
    } = api;
    const userdata = useSelector(state => state.usersdata);
    const dispatch = useDispatch();
    const  bookingId = params.BookingId;
    const auth = useSelector(state => state.auth);
        
    const [liveRouteCoords, setLiveRouteCoords] = useState(null);
    const mapRef = useRef();
    const pageActive = useRef();
    const [lastCoords, setlastCoords] = useState();
    const [arrivalTime, setArrivalTime] = useState(0);
    const settings = useSelector(state => state.settingsdata.settings);
    const [role, setRole] = useState();
    const targetBooking = useSelector(state => state.bookinglistdata);
    const [curBooking, setCurBooking] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [lastLocation, setLastLocation] = useState(null);
    useEffect(()=>{
      if(curBooking && (curBooking?.status === "ACCEPTED" ||
          curBooking?.status === 'ARRIVED' ||
          curBooking?.status === 'STARTED' ||
          curBooking?.status === 'REACHED'))
          {
             if(curBooking?.driverDetails?.id){
              dispatch(api.fetchBookingLocations(curBooking?.driverDetails?.id))
                 .then(res=>{
                   setLastLocation(res)
                 })
                 .catch(error=>{
                     setLastLocation(null)
                 })
          }
          else{
              setLastLocation(null)
          }
      }
      else{
          setLastLocation(null)
      }
  
  },[curBooking])



    useEffect(()=>{
       if(targetBooking?.singleBook){
            setErrorMessage(null)
            if(targetBooking?.singleBook?.status === 'CANCELLED'){
              setErrorMessage("Tracking Cancelled")
              setCurBooking(null)
            }
            else if(targetBooking?.singleBook?.status === 'COMPLETE'){
              setErrorMessage("Tracking Completed")
              setCurBooking(null)
            }
            else if(!checkIfUserAllowedToSeeTracking(targetBooking?.singleBook)){
              setErrorMessage("Sorry, You are not allowed to access this tracking")
              setCurBooking(null)
            }
            else setCurBooking({...targetBooking?.singleBook,id:bookingId})
       }
       else setErrorMessage("Tracking Not Found")

    },[targetBooking,auth.info])

    useEffect(() => {
        if (auth.info && auth.info.profile) {
            setRole(auth.info.profile.usertype);
        } else {
            setRole(null);
        }
    }, [auth.info]);

    useEffect(()=>{
      if(bookingId){
        dispatch(fetchSingleBook(bookingId))
      }
   },[fetchSingleBook,dispatch,bookingId])

    // useEffect(()=>{
    //   if(curBooking){
    //     if (curBooking.status == "ACCEPTED" ||curBooking.status == 'ARRIVED' || curBooking.status == 'STARTED' ||curBooking.status == 'REACHED') {
    //       dispatch(fetchBookingLocations(bookingId));
    //     }
    //   }
    // },[curBooking])

 function isSuperDispatcher(){
    return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
 }

 const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =userdata.users.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}

function checkIfUserAllowedToSeeTracking(booking) {
  if (isSuperDispatcher() || auth.info?.profile?.dispatcherId) {
    const sharedCarriers = auth.info?.profile?.dispatcherId
      ? giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedCarriers
      : auth.info?.profile?.SharedCarriers;

    if (sharedCarriers && sharedCarriers.length > 0) {
      const isUserAllowed = sharedCarriers.some(carrier =>
        carrier.approved && carrier.aprovedByCarrier && booking.carrierId === carrier.id
      );

      return isUserAllowed;
    } else {
      return false;
    }
  }
  else if (role === "shipper" || auth.info?.profile?.brokerId) {
    return booking?.customer === auth?.info?.uid || booking.customer === auth.info?.profile?.brokerId;
  } else if (role === "fleetadmin" || auth.info?.profile?.carrierId) {
    return booking?.carrierId === auth?.info?.uid || booking.carrierId === auth.info?.profile?.carrierId;; 
  } else if (role === "driver") {
    return booking?.carrierId === auth?.info?.profile?.fleetadmin && booking.approved;
  } else if (role === 'admin') {
    return true;
  } else {
    return false;
  }
}



useEffect(() => {
  pageActive.current = true;
  return () => {
      pageActive.current = false;
  };
}, []);


  useEffect(() => {
        if(!lastLocation && (curBooking?.status == "NEW" || curBooking?.status == "ACCEPTED"||curBooking?.status == "ARRIVED" ) ){
                setTimeout(() => {
                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(new window.google.maps.LatLng(curBooking?.pickup?.lat,  curBooking?.pickup?.lng));
                    bounds.extend(new window.google.maps.LatLng( curBooking?.pickup?.lat, curBooking?.pickup?.lng));
                    if (mapRef.current) {
                      mapRef.current.fitBounds(bounds);
                      
                  }
                }, 1000); 
        }

        if(!lastLocation && (curBooking?.status == "STARTED" ||curBooking?.status == "REACHED" ) ){

              setTimeout(() => {
                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(new window.google.maps.LatLng(curBooking?.drop?.lat,  curBooking?.drop?.lng));
                    bounds.extend(new window.google.maps.LatLng( curBooking?.drop?.lat, curBooking?.drop?.lng));
                    if (mapRef.current) {
                      mapRef.current.fitBounds(bounds);
                      
                  }
              }, 1000);
          
        }

      if (lastLocation && curBooking && curBooking.status == "ACCEPTED" && pageActive.current) {
          let point1 = { lat: lastLocation.lat, lng: lastLocation.lng };
          let point2 = { lat: curBooking.pickup.lat, lng: curBooking.pickup.lng };
          fitMap(point1, point2);
          setlastCoords(lastLocation);
      }

      if (lastLocation&&curBooking && curBooking.post_request && curBooking.status == "ACCEPTED" && pageActive.current && role == "driver") {
              let point1 = { lat: lastLocation.lat, lng: lastLocation.lng };
              let point2 = { lat: curBooking.post_request?.origin?.lat || curBooking.pickup.lat, lng: curBooking.post_request?.origin?.lng||curBooking.pickup.lng };
              fitMap(point1, point2);
              setlastCoords(lastLocation);          
      } 

      if (lastLocation&&curBooking && !curBooking.post_request && curBooking.status == 'ARRIVED' && pageActive.current) {
          setlastCoords(null);
          setTimeout(() => {
                const bounds = new window.google.maps.LatLngBounds();
                bounds.extend(new window.google.maps.LatLng(lastLocation.lat, lastLocation.lng));
                bounds.extend(new window.google.maps.LatLng( curBooking.pickup.lat, curBooking.pickup.lng));
                if (mapRef.current) {
                  mapRef.current.fitBounds(bounds);
                  
               }
              
          }, 1000);
      }

      if (lastLocation&&curBooking && curBooking.post_request && curBooking.status == 'ARRIVED' && pageActive.current && role == "driver") {
          setlastCoords(null);
          setTimeout(() => {
                const bounds = new window.google.maps.LatLngBounds();
                bounds.extend(new window.google.maps.LatLng(lastLocation.lat, lastLocation.lng));
                bounds.extend(new window.google.maps.LatLng( curBooking.post_request?.origin?.lat || curBooking.pickup.lat, curBooking.post_request?.origin?.lng || curBooking.pickup.lng));
                
                if (mapRef.current) {
                  mapRef.current.fitBounds(bounds);
                  
                }
            
          }, 1000);
      } 
      else if (lastLocation && curBooking && curBooking.post_request && curBooking.status == 'ARRIVED' && pageActive.current ) {
          setlastCoords(null);
          setTimeout(() => {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(lastLocation.lat, lastLocation.lng));            
            bounds.extend(new window.google.maps.LatLng( curBooking.pickup.lat,  curBooking.pickup.lng));
            if (mapRef.current) {
              mapRef.current.fitBounds(bounds);
              
            }
              
          }, 1000);
      }

      if (lastLocation && curBooking && !curBooking.post_request && curBooking.status == 'STARTED' && pageActive.current) {
          let point1 = { lat: lastLocation.lat, lng: lastLocation.lng };
          let point2 = { lat: curBooking.drop.lat, lng: curBooking.drop.lng };
          fitMap(point1, point2);
          setlastCoords(lastLocation);
      }

      if (lastLocation && curBooking && curBooking.post_request && curBooking.status == 'STARTED') {
          let point1 = { lat: lastLocation.lat, lng: lastLocation.lng };
          let point2 = { lat: curBooking.post_request?.destination?.lat|| curBooking.drop.lat, lng: curBooking.post_request?.destination?.lng|| curBooking.drop.lng };
          fitMap(point1, point2);
          setlastCoords(lastLocation);
      } 
      else if (lastLocation && curBooking && curBooking.post_request && curBooking.status == 'STARTED' && pageActive.current ) {
          let point1 = { lat: lastLocation.lat, lng: lastLocation.lng };
          let point2 = { lat: curBooking.drop.lat, lng: curBooking.drop.lng };
          fitMap(point1, point2);
          setlastCoords(lastLocation);
      }

      if (lastLocation&& curBooking && curBooking.status == 'REACHED'  && pageActive.current) {
        setlastCoords(null);
          setTimeout(() => {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(lastLocation.lat, lastLocation.lng));            
            bounds.extend(new window.google.maps.LatLng( curBooking.drop.lat,  curBooking.drop.lng));
            if (mapRef.current) {
              mapRef.current.fitBounds(bounds);
              
            }
              
          }, 1000);
      }

  }, [lastLocation, curBooking, pageActive.current])
  

  const fitMap = (point1, point2) => {
      let startLoc = point1.lat + ',' + point1.lng;
      let destLoc = point2.lat + ',' + point2.lng;
      if (settings.showLiveRoute) {
          getDirectionsApi(startLoc, destLoc, null).then((details) => {
              setArrivalTime(details.time_in_secs ? parseFloat(details.time_in_secs / 60).toFixed(0) : 0);
              let points = Polyline.decode(details.polylinePoints);
              let coords = points.map((point, index) => {
                  return {
                      latitude: point[0],
                      longitude: point[1]
                  }
              })

              setLiveRouteCoords(coords);
              const bounds = new window.google.maps.LatLngBounds();
              bounds.extend(new window.google.maps.LatLng(point1.lat, point1.lng));
              bounds.extend(new window.google.maps.LatLng(point2.lat, point2.lng));
              
              if (mapRef.current) {
                mapRef.current.fitBounds(bounds);
                
              }
          }).catch((error) => {
                  //console.log(error); 
          });
      } else {
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng( point1.lat, point1.lng));
            bounds.extend(new window.google.maps.LatLng(point2.lat, point2.lng));
            
            if (mapRef.current) {
              mapRef.current.fitBounds(bounds);
              
            }
      }
  }

  


const initialRegion = {
  lat: curBooking?.pickup?.lat,
  lng: curBooking?.pickup?.lng,
};


return (
    <div >
            <div >
                {curBooking&& !targetBooking.singleloading ?
                   <div style={{width:"100%",minHeight:"700px",maxHeight:"88vh",overflow:"hidden"}}>
                          
                        <TruckinMap  
                          history = {props.history}
                          mapRef={mapRef}
                          colors={colors}
                          curBooking={curBooking}
                          role={role}
                          liveRouteCoords={liveRouteCoords}
                          lastLocation={lastLocation}
                          center={initialRegion}
                          loadingElement={<div style={{ height: `80vh` }} />}
                          containerElement={<div />}
                          mapElement={<div style={{ height: `87vh` }} />}
                        />

                   </div>
                      
                   :
                     <>
                       {!targetBooking.singleloading&&errorMessage?
                           <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"20px",flexDirection:"column",height:"80vh"}}>
                              <h2 style={{color: '#06525c'}}>
                                    {errorMessage}
                              </h2>
                              <div style={{}}><Button><Link to="/bookings">Back to Bookings</Link></Button></div>
                           </div>
                           :
                           <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80vh"}}><CircularProgress/></div>
                        }
                     </>
                   } 

             </div>

    </div>
  )
}

export default Trucking       



/**
 * 
 */