import React,{ useState, useEffect, useContext,useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import AlertDialog from 'components/AlertDialog';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, IconButton, Link, Modal, TextField } from '@material-ui/core';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  carriersStyle:{
    padding: '5px',
    "&:hover,&:focus": {
      backgroundColor: '#F7F6F6',
      width: '100%',
    },
  }
}));

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    AddBroker,
    updateSharedBrokerStatus, 
    deleteSharedBroker,
    fetchSharedBrokersOfCarrier,
    DispatcherVsAgentAdd,
    DispatcherVsAgentDelete,
    DispatcherVsAgentFetch,
    fetchUsersActiveHistory,
     DispatcherVsAgentUpdate
  } = api;
  const [data, setData] = useState([]);
  const sharedBrokers = useSelector(state => state.usersdata.carrierBrokers);
  const  dispatcherAgents = useSelector(state => state.usersdata. dispatcherAgents);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const allUsers=useSelector(state => state.usersdata.users)
  const rootRef = useRef(null);


  useEffect(()=>{

    if(isSuperDispatcher()){
      dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid}))
    }
    else  dispatch(fetchSharedBrokersOfCarrier({carrierId:auth.info.profile.usertype==="dispatcher"?auth.info.profile.carrierId:auth.info.uid}));

    
},[dispatch,fetchSharedBrokersOfCarrier,DispatcherVsAgentFetch]);

  useEffect(()=>{
    const filteredDispatcherAgents = dispatcherAgents ? dispatcherAgents.filter(agent => agent !== undefined) : [];
    const filteredSharedBrokers = sharedBrokers ? sharedBrokers.filter(broker => broker !== undefined) : [];
    
    if(isSuperDispatcher() && filteredDispatcherAgents.length > 0){
      setData(filteredDispatcherAgents);
    }
    else if(!isSuperDispatcher() && filteredSharedBrokers.length > 0){
      setData(filteredSharedBrokers);
    }
    else{
      setData([]);
    }
    loaded.current = true;
  },[sharedBrokers,auth.info.profile.usertype, auth.info.uid, dispatcherAgents]);

const [newData,setNewData]=useState(null)
const [showSearchModel,setShowSearchModel]=useState(false)
const [searchValue,setSearchValue]=useState("")
const [AllBrokerData,setAllBrokerData]=useState([])

const fetchCarrierData = async (brokerOnly) => {
   
  let availableBrokers = [];

  for (const broker of brokerOnly) {
           try {
              const res = await dispatch(fetchUsersActiveHistory(broker?.id));
              if (res && res?.activeHistoryPerWeek) {
                availableBrokers.push({ ...broker, activeHistory: res?.activeHistoryPerWeek });
              } else {
                availableBrokers.push({ ...broker,activeHistory:0 });
              }
            } catch (error) {
               //console.error(error);
            }
      
    }    


    setAllBrokerData(availableBrokers);
};

useEffect(()=>{

  if(allUsers&&allUsers.length>0){
        let brokerOnly= allUsers.filter(e=>{
          return e.usertype==='shipper'
        })
        if(!brokerOnly || brokerOnly.length===0){
          setAllBrokerData([])
        }
        else{
          fetchCarrierData(brokerOnly)
        }

  }
},[allUsers])


const [unSelecetedCarriers,setUnSelecetedCarriers] = useState(null)
useEffect(()=>{

  
  if(AllBrokerData&&AllBrokerData.length>0){
  
    if(isSuperDispatcher()){
      if(dispatcherAgents&&dispatcherAgents.length>0){
        let availableBrokers=[]
          for (const broker of AllBrokerData){
                let brokers=dispatcherAgents.filter(e=>{
                  return e?.email==broker?.email
                })
              if(brokers.length===0) availableBrokers.push(broker)
                
          }
          setUnSelecetedCarriers(availableBrokers)
  
      }
      else {
        setUnSelecetedCarriers(AllBrokerData)
      }
    
  }
  else{
      if(sharedBrokers&&sharedBrokers.length>0){
        
        let availableBrokers=[]
        for (const broker of AllBrokerData){
              let brokers=sharedBrokers.filter(e=>{
                return e?.email==broker?.email
              })
              if(brokers.length===0) availableBrokers.push(broker)
              
        }
        setUnSelecetedCarriers(availableBrokers)

      }
      else{
        setUnSelecetedCarriers(AllBrokerData)
      }
   }

 }

},[AllBrokerData,dispatcherAgents,sharedBrokers])


const openSearchModal=(rowData)=>{
     
  setShowSearchModel(true)
}
const handleSearchModal = (e) => {
  setShowSearchModel(false);
};

const sortCarriersBasedOnSharedCompanies = (AllData) => {
  return AllData.sort((a, b) => {
    let aNumberOfCompanies = 0;
    let bNumberOfCompanies = 0;

    if (a.SharedBrokers && a.SharedBrokers.length > 0) {
      aNumberOfCompanies += a.SharedBrokers.length;
    }
    if (a.SharedDispatcher && a.SharedDispatcher.length > 0) {
      aNumberOfCompanies += a.SharedDispatcher.length;
    }
    if (a.SharedCarriers && a.SharedCarriers.length > 0) {
      aNumberOfCompanies += a.SharedCarriers.length;
    }

    if (b.SharedBrokers && b.SharedBrokers.length > 0) {
      bNumberOfCompanies += b.SharedBrokers.length;
    }
    if (b.SharedDispatcher && b.SharedDispatcher.length > 0) {
      bNumberOfCompanies += b.SharedDispatcher.length;
    }
    if (b.SharedCarriers && b.SharedCarriers.length > 0) {
      bNumberOfCompanies += b.SharedCarriers.length;
    }

    return bNumberOfCompanies - aNumberOfCompanies;
  });
};
const sortBaseedOnActiveHistory = (AllData) => {
  return AllData.sort((a, b) => {
    if (a.activeHistory && b.activeHistory) {
      return b.activeHistory - a.activeHistory;
    } else if (a.activeHistory) {
      return -1;
    } else if (b.activeHistory) {
      return 1;
    } else {
      return 0;
    }
  });
};

const [filteredData,setFilteredData]=useState([])
useEffect(()=>{
  if(unSelecetedCarriers&&unSelecetedCarriers.length>0){
    let filtering=unSelecetedCarriers.filter(e=>{
      
      if(e.carrierData?.dotNumber){
        return  e.carrierData?.dotNumber.toString().includes(searchValue.trim())|| 
                e.companyName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                e.carrierData?.legalName?.toLowerCase().includes(searchValue.trim().toLowerCase())
      }
      else{
        return e?.firstName.toLowerCase().includes(searchValue.trim())||
                e.companyName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                e?.lastName?.toLowerCase().includes(searchValue.trim().toLowerCase())
      }
    })
  setFilteredData(sortBaseedOnActiveHistory(filtering))
  }
  else{
    setFilteredData([])
  }

},[searchValue,unSelecetedCarriers])


function isSuperDispatcher(){
  return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
}
  const columns = [
      { title: 'Company Name', field: 'companyName', editable:'onAdd', 
        render: rowData =><div>{rowData.approved?
            <Link style={{textDecoration:"none"}} href={`/user/${rowData.id}?redirected=my-carriers`}>
                {rowData.companyName?rowData.companyName:`${rowData.firstName} ${rowData.lastName}`}
          </Link>
          :<span style={{color:"red"}}>
                {rowData.id?
                    <>
                      {rowData?.companyName?rowData?.companyName:`${rowData?.firstName} ${rowData?.lastName}`}
                    </>
                :
                <div>{rowData.inviteName||rowData.email} (Invited)</div>
                }
                
            </span>}
        </div>,
        editComponent: (props) => (
            <button onClick={() => openSearchModal(props.rowData)}>
                {newData?.companyName?
                  <>  
                      {newData.companyName}
                  </>
                  :
                  newData?.inviteName||"Add Brokers/Forwarders"
                }
            </button>
          ),
          cellStyle:{ paddingLeft:isRTL ==='rtl'? 50 : 15,width:150,minWidth:150},
      }, 
      { title:"Country",  field: 'country', editable: 'never', 
          render: rowData =>
            <div>
                {rowData?.selectedcountry?.label}
            </div>,
           cellStyle:{ textAlign:'center',width:180,minWidth:180},
          headerStyle:{textAlign:'center'},
         },     
       { title: isSuperDispatcher()?"Approved By Me":"Approved By Me",  field: 'isBrokerActive', type:'boolean', initialEditValue: true, 
           cellStyle:{ textAlign: 'center'},
           headerStyle:{textAlign:'center'},
        },
       { title: "Approved By Broker",  field: 'aprovedByBroker', type:'boolean', editable: 'never',
            headerStyle:{textAlign:'center',width:180,minWidth:180},
            cellStyle:{ textAlign:'center',width:180,minWidth:180} 
        },
        
 
  ];
  /**
   * { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",
          headerStyle:{textAlign:'center'},
          cellStyle:{ paddingLeft:isRTL ==='rtl'? 30 : 15,width:180,minWidth:180} 
      },
   */
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

const [openInvitation,setOpenInvitation]=useState(false)
const [emailToBeInvite,setEmailToBeInvite]=useState("")
const [nameToBeInvite,setNameToBeInvite]=useState("")
const [emailError,setEmailError]=useState("")
const OpenInviteViaEmail=()=>{
  setOpenInvitation(true)
  setShowSearchModel(false);
}
const closeInviteViaEmail=()=>{
  setOpenInvitation(false)
  setShowSearchModel(false);
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const AddBrokerData=(data)=>{
  setNewData(data)
  setShowSearchModel(false);
}

const InviteViaEmail=()=>{
  if(isValidEmail(emailToBeInvite)){
    if(nameToBeInvite&&nameToBeInvite.length>0){
            setNewData({email:emailToBeInvite,type:"invitation",inviteName:nameToBeInvite})
            setOpenInvitation(false)
            setShowSearchModel(false);
      }
    else{
        setEmailError("Company Name is Required")
        setTimeout(()=>{
          setEmailError("")
        },5000)
    }
  }
  else{
    setEmailError("Invalid email")
    setTimeout(()=>{
      setEmailError("")
    },5000)
  }
  setEmailToBeInvite('')
  setNameToBeInvite('')
}

const handlePress = (e) => {
  if(e.key === 'Enter'){
    InviteViaEmail()
  }
}


return (
    !loaded.current? <CircularLoading/>:
    <>
        <MaterialTable
          title={"Manage Freight Brokers "}
          columns={columns}
          style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
          data={data}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
          }}
          localization={{body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
              (t('blank_message'))
          ),
          editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
              }, 
            },
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: (t('actions')) 
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
          }}
          editable={{
            onRowAdd: newDatas =>
            new Promise((resolve,reject) => {
              if(!isValidEmail(newData.email)){
                      setCommonAlert({open:true,msg:"Invalid Email"})
                      if(isSuperDispatcher()){
                        dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid}))
                    }
                    else  dispatch(fetchSharedBrokersOfCarrier({carrierId:auth.info.profile.usertype==="dispatcher"?auth.info.profile.carrierId:auth.info.uid}));
                      resolve();
                }
              else{
                setTimeout(() => {
                  newDatas['createdAt'] = new Date().toISOString();
                  if(isSuperDispatcher()){
                    newDatas['dispacherId']=auth.info.uid
                    newDatas['target']='shipper'
                    newDatas['email']=newData.email
                    newDatas['inviteName']=newData.inviteName
                     dispatch(DispatcherVsAgentAdd(newDatas));
                      setNewData(null)
                   }
                   else{
                     if(auth.info.profile.usertype==="dispatcher"&&!auth.info.profile.independent) newDatas['carrierId'] =auth.info.profile.carrierId
                     else if(auth.info.profile.usertype==="fleetadmin") newDatas['carrierId'] = auth.info.uid;
                     newDatas['email']=newData.email 
                     newDatas['inviteName']=newData.inviteName
                     dispatch(AddBroker(newDatas));
                     setNewData(null)
                    }
                    
                    setTimeout(()=>{
                      if(isSuperDispatcher()){
                        dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid}))
                      }
                      else  dispatch(fetchSharedBrokersOfCarrier({carrierId:auth.info.profile.usertype==="dispatcher"?auth.info.profile.carrierId:auth.info.uid}));
                      
                      resolve();
                      
                    },1000)
                }, 600);
              }
              setNewData(null)
            }),
            onRowUpdate: (newDatas) =>
              new Promise(resolve => {
                setTimeout(() => {
                  
                  if(auth.info.profile.usertype==="dispatcher") newDatas['carrierId'] =auth.info.profile.carrierId
                  else  newDatas['carrierId'] = auth.info.uid;
                
                  if(isSuperDispatcher()){
                    newDatas['dispacherId']= auth.info.uid
                    newDatas['target']='shipper'
                    dispatch( DispatcherVsAgentUpdate(newDatas))
                    dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid}))
                  }
                  else{
                    dispatch(updateSharedBrokerStatus(newDatas));
                    dispatch(fetchSharedBrokersOfCarrier({carrierId:auth.info.profile.usertype==="dispatcher"?auth.info.profile.carrierId:auth.info.uid}));
                  }
             
                  resolve();
                }, 600);
              }),
            onRowDelete: oldData =>
              settings.AllowCriticalEditsAdmin?
              new Promise(resolve => {
                setTimeout(() => {
                  if(isSuperDispatcher()){
                    oldData['dispatcherId']= auth.info.uid;
                    oldData['target']='shipper'
                    dispatch( DispatcherVsAgentDelete(oldData))
                    dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid}))
                 
                  }
                  else {
                      if(auth.info.profile.usertype==="dispatcher") oldData['carrierId'] =oldData.info.profile.carrierId
                      else  oldData['carrierId'] = auth.info.uid;
                      dispatch(deleteSharedBroker(oldData));
                      dispatch(fetchSharedBrokersOfCarrier({carrierId:auth.info.profile.usertype==="dispatcher"?auth.info.profile.carrierId:auth.info.uid}));
                    }
               
                    resolve();
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              })
              , 
          }}
        />

    <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openInvitation}
        onClose={closeInviteViaEmail}
        className={classes.modal}
        container={() => rootRef.current}
      >
           <Grid
            container
            spacing={1}
            className={classes.paper}
              >
            <Grid item xs={12} style={{textAlign:"center"}}>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"15px",}}>
                      <div>
                          <TextField 
                              placeholder='Company Name'
                              onChange={(e)=>setNameToBeInvite(e.target.value)}
                              value={nameToBeInvite} 
                              onKeyDown={handlePress}
                              style={{}}
                              />
                      </div>
                      <div>
                        <TextField 
                          placeholder='Email'
                          onChange={(e)=>setEmailToBeInvite(e.target.value)}
                          value={emailToBeInvite} 
                          onKeyDown={handlePress}
                          />

                      </div>
                       <div>
                       <Tooltip title="Invite">
                          <Button style={{color:"red"}} onClick={InviteViaEmail}>Invite</Button>
                       </Tooltip>
                    </div>
                  </div>
                  {emailError&&<div style={{color:"red"}}>{emailError}</div>}
                  
            </Grid>
          </Grid>
         
      </Modal>
         
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={showSearchModel}
        onClose={handleSearchModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
        >
          <Grid item xs={12} style={{textAlign:"center"}}>
                  <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      <TextField 
                        value={searchValue} 
                        placeholder='Search...'
                        onChange={(e)=>setSearchValue(e.target.value)}
                        style={{width:"90%"}}
                        label="Search For Active Brokers/Forwarders" 
                        variant="standard"
                        />
                  </div>
          </Grid>

          <Grid item xs={12} style={{height:"60vh",overflow:"auto",marginTop:"15px"}}>
              {filteredData.map(e=>{
                 return <div onClick={()=>AddBrokerData(e)} style={{display:"flex",justifyContent:"flex-start",gap:"10px",marginBottom:"20px",cursor:"pointer"}} className={classes.carriersStyle}>
                           <div><HdrWeakIcon style={{fontSize:"15px",color:"red"}}/> </div>
                           <div>
                              <div>{e.companyName}</div>
                              <div style={{opacity:0.5,fontSize:"12px"}}>{e?.selectedcountry?.label}</div>
                              <div><Link href={`/user/${e.id}?redirected=my-brokers`}>See more</Link></div>
                           </div>
                   
                   </div>
              })}
              {filteredData.length===0&&<div style={{fontSize:"20px",opacity:0.5,display:"flex",justifyContent:"center",alignItems:"center",height:'100%'}}>No Company is found</div>}
          </Grid>

          
          <div style={{ display:"flex",justifyContent:"space-between",alignItems:"center",gap:"30px"}}>
               <div>
                  <Button
                    onClick={handleSearchModal}
                    variant="contained"
                    color="dangerColor">
                    Cancel
                  </Button>
               </div>
                <div>
                    <Button style={{background:"red",color:"white"}} onClick={OpenInviteViaEmail}>Invite New</Button>
                </div>
          </div>
          
        
          
        </Grid>
      </Modal>

     <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </>
  );
}
