import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import Calendar from "react-calendar";
import { colors } from "../components/Theme/WebTheme";
import Button from "components/CustomButtons/Button.js";
import { Modal, Grid, Typography, IconButton, TextField,Select,
    MenuItem, FormControl,InputLabel, Link, useMediaQuery, 
    InputAdornment} from "@material-ui/core";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

const useStyles = makeStyles((theme) => ({
    submit: {
      backgroundColor: "#de7d1e",
    },
    modal: {
      display: "flex",
      padding: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 15,
      alignItems: "center",
      justifyContent: "center",
    },
  }));

export default function LoadBoard() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();

  const {
    addLoad,
    editRoutes,
    fetchRoutes,
    deleteRoutes,
    fetchUsersOnce,
  } = api;

  const settings = useSelector(state => state.settingsdata.settings);
  const routes = useSelector(state => state.routes);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const rootRef = useRef(null);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const userdata = useSelector(state => state.usersdata);

  

  useEffect(()=>{
    if(auth?.info?.profile?.usertype === 'fleetadmin'){
        dispatch(fetchRoutes(auth.info.uid));
    }
    else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    
},[dispatch,fetchRoutes,auth?.info?.uid]);



const [loading, setLoading] = useState(false);
const [pickup, setPickup] = useState(null);
const [drop, setDrop] = useState(null);

const [pickupDate, setPickupDate] = useState(null);
const [dropDate, setDropDate] = useState(null);
const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

const [rowToUpdate,setRowToUpdate]=useState({})
const [PickupModal, setPickupModal] = useState(false);
const [PickupDateModal, setPickupDateModal] = useState(false);
const [DropModal, setDropModal] = useState(false);
const [DropDateModal, setDropDateModal] = useState(false);
const [loadData,setLoadData]=useState(null)


const openPickupModal = (rowData) => {
    setLoadData(rowData)
    if(!pickup||rowToUpdate?.pickup!==rowData.id){
        setPickup(rowData.pickup
        ? {
            coords:rowData.pickup.coords ,
            description: rowData.pickup.description,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, pickup: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, pickup: null }))

    // Open the modal
    setPickupModal(true);
  };

const openDropModal = (rowData) => {
    setLoadData(rowData)
    if(!drop||rowToUpdate?.drop!==rowData.id){
        setDrop(rowData.drop
        ? {
            coords:rowData.drop.coords ,
            description: rowData.drop.description,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, drop: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, drop: null}))
    // Open the modal
    setDropModal(true);
  };

const pageBackgroundColor=()=>{
    if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
    if(giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor
    return "#01aac1"
}


const columns = [
    {
        title: "Identification",
        field: "identification",
        editable: "never",
        initialEditValue: '',
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 150,
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          zIndex: 2
        },
    },
    { 
        title:"Pick up", 
        field: 'pickup',
        render: (rowData) =>
            rowData.pickup||pickup?.description ? (
                <div>
                <p>{(pickup?.description&&rowToUpdate?.pickup===rowData.id)?pickup?.description:rowData?.pickup?.description}</p>
                </div>
            ) : (
                "Missing Pick Up"
        ),
        editComponent: (props) => (
            <button onClick={() => openPickupModal(props.rowData)}>
                {props.rowData.pickup||pickup?.description ? (
                <div>
                    <p>{(pickup?.description&&rowToUpdate?.pickup===props.rowData.id)?
                     pickup?.description
                    :props.rowData?.pickup?.description}</p>
                </div>
                ) : (
                "Add"
                )}
            </button>
        ),
        cellStyle: {
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
        },
        headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
        },
     
     },
    { title: "Drop off", field: 'drop',
    render: (rowData) =>
        rowData.drop||drop?.description ? (
            <div>
            <p>{(drop?.description&&rowToUpdate?.drop===rowData.id)?drop?.description:rowData?.drop?.description}</p>
            </div>
        ) : (
            "Missing Drop off"
    ),
    editComponent: (props) => (
        <button onClick={() => openDropModal(props.rowData)}>
            {props.rowData.drop||drop?.description ? (
            <div>
                <p>{(drop?.description&&rowToUpdate?.drop===props.rowData.id)?drop?.description:props.rowData?.drop?.description}</p>
            </div>
            ) : (
             "Add"
            )}
        </button>
    ),
    cellStyle: {
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
        },
    headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
        }
    },
    {
        title: "Name",
        field: "name",
        initialEditValue: '',
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 150,
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          zIndex: 2
        },
    },
    {
        title: "Active",
        field: "isActive",
        type: "boolean",
        initialEditValue: true,
        cellStyle: { 
            textAlign: isRTL === "rtl" ? "right" : "center", 
            minWidth: 150,
            zIndex: 2
          },
        headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            zIndex: 2
        },
      },
    { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},

  ];


const isPickupAndDropAreValid = async (pickup, drop) => {

  if (!pickup || !drop) return false;

  try {
    const directionService = new window.google.maps.DirectionsService();
    const result = await directionService.route({
      origin: new window.google.maps.LatLng(pickup.coords.lat, pickup.coords.lng),
      destination: new window.google.maps.LatLng(drop.coords.lat, drop.coords.lng),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    return result.status === window.google.maps.DirectionsStatus.OK;
  } catch (error) {
    //console.error(error);
    return false;
  }
};


const handleSetPickup=()=>{
    if(!pickup) return
    if(loadData.id){
        let datatToBeUpdate={...loadData,pickup:pickup}
        dispatch(editRoutes(datatToBeUpdate));
        if(auth?.info?.profile?.usertype === 'fleetadmin'){
            dispatch(fetchRoutes(auth.info.uid));
        }
        else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    }
    setPickupModal(false)
    
}

const handleSetDrop=()=>{
    if(!drop) return
    if(loadData.id){
        let datatToBeUpdate={...loadData,drop:drop}
        dispatch(editRoutes(datatToBeUpdate));
        if(auth?.info?.profile?.usertype === 'fleetadmin'){
            dispatch(fetchRoutes(auth.info.uid));
        }
        else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    }
    setDropModal(false)
}


function EditRowDone(newData,oldData){ 
    let datatToBeUpdate={...newData}

    if(pickupDate&&rowToUpdate?.pickupDate===datatToBeUpdate.id){
      datatToBeUpdate["pickupDate"]=new Date(pickupDate).getTime()
    }
    if(dropDate&&rowToUpdate?.dropDate===datatToBeUpdate.id){
      datatToBeUpdate["dropDate"]=new Date(dropDate).getTime()
    }

    if(pickup&&rowToUpdate?.pickup===datatToBeUpdate.id){
        datatToBeUpdate["pickup"]=pickup
      }

   if(drop&&rowToUpdate?.drop===datatToBeUpdate.id){
        datatToBeUpdate["drop"]=drop
      }
      
      dispatch(editRoutes(datatToBeUpdate));
      setRowToUpdate({})
      setTimeout(() => {
        if(auth?.info?.profile?.usertype === 'fleetadmin'){
            dispatch(fetchRoutes(auth.info.uid));
        }
        else dispatch(fetchRoutes(auth.info.profile?.carrierId));
    }, 1000);
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =userdata.users.filter(e=>{
      return e.id===id
    })
    if(targetUser.length>0) return targetUser[0]
    return null

  }


  return (
    (routes.loading&&routes)? <CircularLoading/>:
     <>
        <MaterialTable
        title= 'Manage Routes'
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
        data={routes.routes||[]}
        options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
        }}
        localization={{ body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
            (t('blank_message'))
        ),
            editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
            }, 
            },
            toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
            },
            header: {
            actions: (t('actions')) 
        },
        pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
        },
        }}
        editable={{
          onRowAdd: async (newData) =>
            new Promise((resolve,reject) => {
                setTimeout(async() => {
                    if(auth.info?.profile?.usertype === 'dispatcher'){
                      newData['carrierId'] = auth.info?.profile?.carrierId;
                     }
                    else newData['carrierId'] = auth.info.uid;
                    newData['drop']=drop
                    newData['pickup']=pickup
                   
                    newData['createdAt'] = new Date().toISOString();
                    
                    if(drop&&pickup){
                        const isValidRoutes=await isPickupAndDropAreValid(pickup,drop)
                        if(isValidRoutes){

                              dispatch(api.createRoutes(newData))
                                setPickup(null)
                                setDrop(null)
                                if(auth?.info?.profile?.usertype === 'fleetadmin'){
                                    dispatch(fetchRoutes(auth.info.uid));
                                }
                                else dispatch(fetchRoutes(auth.info.profile?.carrierId));
                        }
                        else{
                            setCommonAlert({
                              open: true,
                              msg: 'The pickup and drop-off locations you provided are not valid. Please check the coordinates and try again.',
                            });
                        }
                        
                    }
                    else{
                      setCommonAlert({open:true,msg:"Please fill all fields"})
                    }
               
                    resolve();
                }, 600);
            }),

            onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
                setTimeout(() => {

                  dispatch(editRoutes(newData));
                  if(auth?.info?.profile?.usertype === 'fleetadmin'){
                    dispatch(fetchRoutes(auth.info.uid));
                   }
                  else dispatch(fetchRoutes(auth.info.profile?.carrierId));

                    resolve();
                   
                }, 600);
            }),
            onRowDelete: oldData =>
            new Promise(resolve => {
               setTimeout(() => {

                  dispatch(deleteRoutes(oldData))

                  if(auth?.info?.profile?.usertype === 'fleetadmin'){
                        dispatch(fetchRoutes(auth.info.uid));
                    }
                  else dispatch(fetchRoutes(auth.info.profile?.carrierId));

                   resolve();
                }, 600);
            })
            
        }}
        />

        
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={PickupModal}
        onClose={()=>setPickupModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Pick Up"}
              value={pickup}
              className={classes.items}
              onChange={(value) => {
                setPickup(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetPickup}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10,background:pageBackgroundColor() }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={DropModal}
        onClose={()=>setDropModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Drop off"}
              value={drop}
              className={classes.items}
              onChange={(value) => {
                setDrop(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetDrop}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10,background:pageBackgroundColor() }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>




      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
     </>
  );
}
