import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";

import HeroImg from '../assets/img/BG_HERO_DESIGN.png';
import styled from "styled-components";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutUs(props) {

  const classes = useStyles();
  const { ...rest } = props;


  return (
    <div>
      <Header
        color="landing"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/* <Parallax small filter image={require("assets/img/background.png").default} className={classes.aboutUsParallax} /> */}
       
      <Div12 style={{
        backgroundImage: `url(${HeroImg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <div className={classes.container} style={{color:"white"}}>
            <h2 style={{textAlign:'center',margin: "0px 0 40px 0",color: "white",fontWeight: "800"}}>
               About Freight Canvas
            </h2>
            <div style={{font:'500 20px Open Sans, sans-serif', color:"white"}}>
                <P>
                   At Freight Canvas, our mission is to transform the over-the-road logistics industry by providing a comprehensive platform that enhances reliability, efficiency, and growth globally. We create an environment where Freight Carriers, Freight Brokers, and Freight Forwarders can seamlessly collaborate, building strong logistics networks that transcend borders and deliver exceptional service.

                </P>
                <P>
                    Our platform empowers logistics professionals to digitize their existing offline networks or establish new ones, offering a suite of features that support operations anywhere in the world. By integrating data from various logistics partners, we deliver a customized and efficient experience tailored to each user's needs.

                </P>
                <P>
                    Freight Canvas consolidates workflows into a single platform, simplifying local and international operations. We foster long-term relationships based on reliability and transparency, enhancing overall service quality. By promoting better workflows and professionalism in over-the-road logistics, we connect the world more effectively, providing broader access to reliable logistics solutions.
                </P>
                <P>
                   Join us at Freight Canvas and become part of a global network that values reliability, collaboration, and innovation. Together, we can build a more connected and efficient world.
                </P>
            </div>

        </div>

      </Div12>
    

      <Footer/>
    </div>
  );
}

const P=styled.p`
 font:Bold 17px Open Sans, sans-serif;
 line-height:1.5;
`

const Div12 = styled.div`
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: flex;
  width: 100%;
  height:100%;
  min-height:80vh;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;