
import {countries} from './continents'

export function getContinent(countryCode) {
    if(!countryCode) return "Not Registered"
    const targetCountry = countries.filter(e=>{
         return e.code === countryCode.toUpperCase()
    })
    if(targetCountry.length === 0)  return  "Not Registered"
    return targetCountry[0].continent

}
