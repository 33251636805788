export const FirebaseConfig = {
	"projectId": "dskonect-firebase",
	"appId": "1:191776750000:web:b73efe8cf076f6f8777e75",
	"databaseURL": "https://dskonect-firebase-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "dskonect-firebase.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyDr6x3ERrlsG6IZH4eS4EMDUKQn3TXeiRA",
	"authDomain": "dskonect-firebase.firebaseapp.com",
	"messagingSenderId": "191776750000",
	"measurementId": "G-VBRH6PJM2S"
};