import React, { useState, useEffect, useContext, useRef } from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { Typography, Button, IconButton, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import CheckCircle from "@material-ui/icons/CheckCircle";
import GridContainer from "components/Grid/GridContainer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import moment from "moment";
import * as geolib from 'geolib';

import MultipleStopIcon from "@mui/icons-material/MultipleStop";

const useStyles = makeStyles((theme) => ({
  ...styles,
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PublicTruckBoard = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const trailertypes = useSelector((state) => state.trailertypes);
  const settings = useSelector((state) => state.settingsdata.settings);
  const [filter, setFilter] = useState([]);
  const [reset, setReset] = useState(true);
  const auth = useSelector((state) => state.auth);
  const staticusers = useSelector((state) => state.usersdata.staticusers);

  function extractStateAndCity(address) {
    const parts = address.split(", ");
    if(parts?.length!==4){
      return address
    }
    const cityState = parts[parts.length - 2].split(" ");
    if(cityState?.length!==2){
      return  address
    }
    const city = parts[1];
    const state = cityState[0];
  
    return `${city}, ${state}` ;
  }

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser = staticusers.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null
}


  const isUsingMile=(driverOwner)=>{
    if(giveIdThenIwillGiveYouData(driverOwner)){
      const  countryCode=giveIdThenIwillGiveYouData(driverOwner)?.selectedcountry?.code
      if(countryCode === 'US') return true
      return false
    }
    return false
    
 }

const [columns,setColumns] = useState([])
useEffect(()=>{
      if(props?.role?.usertype === "shipper" || auth.info?.profile?.brokerId){
        setColumns( [
          {
            title: "",
            field: "actions",
            headerStyle: {
              backgroundColor: "#01aac1",
              color: "white",
              fontSize: 16,
            },
            render: (rowData) => {
              const IconComponent = (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 100,
                    height: 50,
                    color: "white",
                    backgroundColor: props.estimateRequested.loading&&props.estimateRequested?.id=== rowData?.id?"white":"#01aac1",
                    borderRadius: 5,
                    fontSize: 10,
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  {props.estimateRequested.loading&&props.estimateRequested?.id=== rowData?.id?
                    <CircularProgress size={25}/>
                    :<>
                      <Typography variant="subtitle2" >Book</Typography>
                      <CheckCircle  style={{color:rowData?.canBeBooked?"green":"gray"}}/>
                    </>}
                </div>
              );
                return (
                  <IconButton
                    onClick={(event) => {
                      props.handleGetEstimate(event,getObjectReadyToEstimate(rowData));
                    }}
                    disabled={props.estimateRequested.loading}
                  >
                    {IconComponent}
                  </IconButton>
                );
              
            },
          },
          {
            title: "Real-Time Location",
            field: "location",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                  {giveIdThenIwillGiveYouData(rowData.driver?.id)?.location?.add ? giveIdThenIwillGiveYouData(rowData.driver?.id)?.location?.add :  'Unknown'}
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              width: 250,
              minWidth: 250,
              fontSize: "13px",
              fontWeight: 500,
            },
            headerStyle: {
              backgroundColor: "#01aac1",
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
              width: 250,
              minWidth: 250,
            },
          },
      
          {
            title: "Trailer Type",
            field: "trailerType",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                   {trailerDatasOfDriiver(rowData.trailerType)?.imageUrl && 
                       <img  style={{width: 50}}  alt='CarImage' src={trailerDatasOfDriiver(rowData.trailerType)?.imageUrl}/>}
                    {trailerDatasOfDriiver(rowData.trailerType)?.name}
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              width: 160,
              minWidth: 160,
              fontSize: "13px",
              fontWeight: 500,
            },
            headerStyle: {
              width: 200,
              minWidth: 200,
              backgroundColor: "#01aac1",
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
            },
          },
          {
            title: "Vehicle Number",
            field: "vehicleNumber",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                    {giveIdThenIwillGiveYouData(rowData.driver?.id)?.vehicleNumber||'Not Registered'}
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              width: 160,
              minWidth: 160,
              fontSize: "13px",
              fontWeight: 500,
            },
            headerStyle: {
              width: 200,
              minWidth: 200,
              backgroundColor: "#01aac1",
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
            },
          },
      
          {
            title: "Route",
            field: "route",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                  {rowData?.route?.emptyRouteFrom && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          margin: "5px 0 5px 0",
                        }}
                      >
                        {rowData?.route?.emptyRouteFrom?.add}
                        &ensp;
                        <span
                          style={{ color: "red", fontWeight: 800, fontSize: "16px" }}
                        >
                          {'-->'}
                        </span>
                        &ensp;
                        {rowData?.route?.emptyRouteTo?.add}
                      </div>
      
                     {/* <div style={{ fontSize: "10.7px", opacity: 0.5 }}>
                        Posted on: {moment(rowData?.route.postDate).format("lll")}
                      </div>*/}
                    </div>
                  )}
      
                  {rowData?.route?.cityOne && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "6px",
                          alignItems: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>{rowData?.route?.cityOne?.add} </div>
                        <div style={{ color: "red", width: "fit-content" }}>
                          <MultipleStopIcon />
                        </div>
                        <div>{rowData?.route?.cityTwo?.add}</div>
                      </div>
                     
                    </div>
                  )}
      
                  {!rowData?.route?.emptyRouteFrom &&
                    !rowData?.route?.cityOne &&
                    rowData.routeType == "Available Capacity" && (
                      <div>
                        {rowData.route.add}
                        &ensp;
                        <span
                          style={{ color: "red", fontWeight: 800, fontSize: "16px" }}
                        >
                          {'-->'}
                        </span>
                        &ensp;
                        {rowData?.travelRadius ? (
                          <span>Any where {rowData.travelRadius} mile radius</span>
                        ) : (
                          <span>Any where</span>
                        )}
                      </div>
                    )}
      
                  {(rowData?.routeType === "Future Capacity" && !rowData?.route?.emptyRouteFrom)&& (
                    <div>
                      {rowData?.location?.add || "undefined"}
                      &ensp;
                      <span
                        style={{
                          color: "red",
                          fontWeight: 800,
                          fontSize: "16px",
                        }}
                      >
                        {'-->'}
                      </span>
                      &ensp;
                      {rowData?.baseCity?.add}
                     
                    </div>
                  )}
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              width: 300,
              minWidth: 300,
              fontSize: "13px",
              fontWeight: 500,
            },
            headerStyle: {
              backgroundColor: "#01aac1",
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
              width: 200,
              minWidth: 200,
              textAlign: "center",
            },
          },
          {
            title: " Route type",
            field: "route",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {rowData?.routeType === "Future Capacity" && rowData?.route?.emptyRouteFrom ? (
                      <div>
                        {rowData?.route?.routeType
                          ? rowData?.route?.routeType?.name
                            ? rowData?.route?.routeType?.name
                            : rowData?.route?.routeType
                          : rowData?.routeType+ " (Unspecified)"}
                      </div>
                    ) : (
                      rowData?.routeType
                    )}
      
                    {rowData?.routeType === "Future Capacity" && rowData?.route?.emptyRouteFrom ? (
                      <div>
                          ( One Direction )
                      </div>
                    ) : (
                      <div>
                         ( Multi Direction )                  
                      </div>
                      )}
      
                  </div>
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              fontSize: "13px",
              fontWeight: 500,
              width: 250,
              minWidth: 250,
            },
            headerStyle: {
              backgroundColor: "#01aac1",
              width: 250,
              minWidth: 250,
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
            },
          },
          {
            title: "Availability",
            field: "route",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                   {rowData?.route?.emptyRouteFrom&&<div>
                       {moment(rowData?.route.routedate).format("LL")}
                    </div> }
      
                    {rowData?.routeType === "Future Capacity" && !rowData?.route?.emptyRouteFrom&&<div>
                    {moment(new Date(rowData.route)).format("LL")}
                    </div> }
      
                    {rowData?.routeType === "Available Capacity"&&<div>
                       Available Now
                    </div> }
      
                    {rowData?.routeType === "Preferred Lane"&&<div>
                      {rowData?.frequentRouteDates?.join(", ")}
                    </div> }
      
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              fontSize: "13px",
              fontWeight: 500,
              width: 150,
              minWidth: 150,
            },
            headerStyle: {
              backgroundColor: "#01aac1",
              width: 150,
              minWidth: 150,
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
            },
          },
          {
            title: "Rate Per Mile/KM",
            field: "ratePerMile",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                    {rowData.ratePerMile?`$${rowData.ratePerMile}/${isUsingMile(rowData.fleetadmin)?"Mile":"KM"}`:"Unknown"}
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              fontSize: "13px",
              fontWeight: 500,
              width: 190,
              minWidth: 190,
            },
            headerStyle: {
              backgroundColor: "#01aac1",
              width: 150,
              minWidth: 150,
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
            },
          },
          {
            title: "Loaded Weight",
            field: "availableWeight",
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "10px",
                    alignItems: "center",
                    alignItems: "center",
                  }}
                >
                    {rowData.availableWeight?`${rowData.availableWeight} Kg`:"0 Kg"}
                </div>
              );
            },
            cellStyle: {
              textAlign: isRTL === "rtl" ? "right" : "left",
              color: "black",
              fontSize: "13px",
              fontWeight: 500,
              width: 190,
              minWidth: 190,
            },
            headerStyle: {
              backgroundColor: "#01aac1",
              width: 150,
              minWidth: 150,
              fontWeight: "bold",
              color: "white",
              fontSize: 18,
            },
          },
        ])
      }
      else{
          setColumns( [
            {
              title: "Real-Time Location",
              field: "location",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                   {giveIdThenIwillGiveYouData(rowData.driver?.id)?.location?.add ? giveIdThenIwillGiveYouData(rowData.driver?.id)?.location?.add :  'Unknown'}
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                width: 250,
                minWidth: 250,
                fontSize: "13px",
                fontWeight: 500,
              },
              headerStyle: {
                backgroundColor: "#01aac1",
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
                width: 250,
                minWidth: 250,
              },
            },
        
            {
              title: "Trailer Type",
              field: "trailerType",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                     {trailerDatasOfDriiver(rowData.trailerType)?.imageUrl && 
                         <img  style={{width: 50}}  alt='CarImage' src={trailerDatasOfDriiver(rowData.trailerType)?.imageUrl}/>}
                      {trailerDatasOfDriiver(rowData.trailerType)?.name}
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                width: 160,
                minWidth: 160,
                fontSize: "13px",
                fontWeight: 500,
              },
              headerStyle: {
                width: 200,
                minWidth: 200,
                backgroundColor: "#01aac1",
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
              },
            },
        
            {
              title: "Vehicle Number",
              field: "vehicleNumber",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                      {giveIdThenIwillGiveYouData(rowData.driver?.id)?.vehicleNumber||'Not Registered'}
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                width: 160,
                minWidth: 160,
                fontSize: "13px",
                fontWeight: 500,
              },
              headerStyle: {
                width: 200,
                minWidth: 200,
                backgroundColor: "#01aac1",
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
              },
            },
        

            {
              title: "Route",
              field: "route",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                    {rowData?.route?.emptyRouteFrom && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            margin: "5px 0 5px 0",
                          }}
                        >
                          {rowData?.route?.emptyRouteFrom?.add}
                          &ensp;
                          <span
                            style={{ color: "red", fontWeight: 800, fontSize: "16px" }}
                          >
                            {'-->'}
                          </span>
                          &ensp;
                          {rowData?.route?.emptyRouteTo?.add}
                        </div>
        
                      {/* <div style={{ fontSize: "10.7px", opacity: 0.5 }}>
                          Posted on: {moment(rowData?.route.postDate).format("lll")}
                        </div>*/}
                      </div>
                    )}
        
                    {rowData?.route?.cityOne && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "6px",
                            alignItems: "center",
                            alignItems: "center",
                          }}
                        >
                          <div>{rowData?.route?.cityOne?.add} </div>
                          <div style={{ color: "red", width: "fit-content" }}>
                            <MultipleStopIcon />
                          </div>
                          <div>{rowData?.route?.cityTwo?.add}</div>
                        </div>
                      
                      </div>
                    )}
        
                    {!rowData?.route?.emptyRouteFrom &&
                      !rowData?.route?.cityOne &&
                      rowData.routeType == "Available Capacity" && (
                        <div>
                          {rowData.route.add}
                          &ensp;
                          <span
                            style={{ color: "red", fontWeight: 800, fontSize: "16px" }}
                          >
                            {'-->'}
                          </span>
                          &ensp;
                          {rowData?.travelRadius ? (
                            <span>Any where {rowData.travelRadius} mile radius</span>
                          ) : (
                            <span>Any where</span>
                          )}
                        </div>
                      )}
        
                    {(rowData?.routeType === "Future Capacity" && !rowData?.route?.emptyRouteFrom)&& (
                      <div>
                        {rowData?.location?.add || "undefined"}
                        &ensp;
                        <span
                          style={{
                            color: "red",
                            fontWeight: 800,
                            fontSize: "16px",
                          }}
                        >
                          {'-->'}
                        </span>
                        &ensp;
                        {rowData?.baseCity?.add}
                      
                      </div>
                    )}
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                width: 300,
                minWidth: 300,
                fontSize: "13px",
                fontWeight: 500,
              },
              headerStyle: {
                backgroundColor: "#01aac1",
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
                width: 200,
                minWidth: 200,
                textAlign: "center",
              },
            },
            {
              title: " Route type",
              field: "route",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      {rowData?.routeType === "Future Capacity" && rowData?.route?.emptyRouteFrom ? (
                        <div>
                          {rowData?.route?.routeType
                            ? rowData?.route?.routeType?.name
                              ? rowData?.route?.routeType?.name
                              : rowData?.route?.routeType
                            : rowData?.routeType+ " (Unspecified)"}
                        </div>
                      ) : (
                        rowData?.routeType
                      )}
        
                      {rowData?.routeType === "Future Capacity" && rowData?.route?.emptyRouteFrom ? (
                        <div>
                            ( One Direction )
                        </div>
                      ) : (
                        <div>
                          ( Multi Direction )                  
                        </div>
                        )}
        
                    </div>
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                fontSize: "13px",
                fontWeight: 500,
                width: 250,
                minWidth: 250,
              },
              headerStyle: {
                backgroundColor: "#01aac1",
                width: 250,
                minWidth: 250,
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
              },
            },
            {
              title: "Availability",
              field: "route",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                    {rowData?.route?.emptyRouteFrom&&<div>
                        {moment(rowData?.route.routedate).format("LL")}
                      </div> }
        
                      {rowData?.routeType === "Future Capacity" && !rowData?.route?.emptyRouteFrom&&<div>
                      {moment(new Date(rowData.route)).format("LL")}
                      </div> }
        
                      {rowData?.routeType === "Available Capacity"&&<div>
                        Available Now
                      </div> }
        
                      {rowData?.routeType === "Preferred Lane"&&<div>
                        {rowData?.frequentRouteDates?.join(", ")}
                      </div> }
        
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                fontSize: "13px",
                fontWeight: 500,
                width: 150,
                minWidth: 150,
              },
              headerStyle: {
                backgroundColor: "#01aac1",
                width: 150,
                minWidth: 150,
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
              },
            },
            {
              title: "Rate Per Mile/KM",
              field: "ratePerMile",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                      {rowData.ratePerMile?`$${rowData.ratePerMile}/${isUsingMile(rowData.fleetadmin)?"Mile":"KM"}`:"Unknown"}
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                fontSize: "13px",
                fontWeight: 500,
                width: 190,
                minWidth: 190,
              },
              headerStyle: {
                backgroundColor: "#01aac1",
                width: 150,
                minWidth: 150,
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
              },
            },
            {
              title: "Loaded Weight",
              field: "availableWeight",
              render: (rowData) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignItems: "center",
                    }}
                  >
                      {rowData.availableWeight?`${rowData.availableWeight} Kg`:`0 Kg`}
                  </div>
                );
              },
              cellStyle: {
                textAlign: isRTL === "rtl" ? "right" : "left",
                color: "black",
                fontSize: "13px",
                fontWeight: 500,
                width: 190,
                minWidth: 190,
              },
              headerStyle: {
                backgroundColor: "#01aac1",
                width: 150,
                minWidth: 150,
                fontWeight: "bold",
                color: "white",
                fontSize: 18,
              },
            },
          ])
      }
},[staticusers,auth.info,props,trailertypes])
 
const trailerDatasOfDriiver  = (trailertype)=>{

     if(!trailertype)  return {name:"Disclosed"}
     if(!trailertypes?.trailers) return {name:'Loading ...'}
      const driverTrailerType  =  trailertypes.trailers.filter(e=>{
             return trailertype === e.name
       })
      if(driverTrailerType.length ===  0)  return{name: "Not Registered"}
      
      return{
        imageUrl: driverTrailerType[0].image,
        name:driverTrailerType[0].name
      }
}


function getObjectReadyToEstimate(rowData){
  let basicData={
    location: rowData?.location || null,
    baseCity:rowData?.baseCity||null,
    trailerType: rowData.trailerType,
    canBeBooked:rowData.canBeBooked,
    route: rowData?.route,
    routeType: rowData.routeType,
    driver:rowData?.driver,
    id: rowData?.id,
    travelRadius: rowData?.travelRadius,
    frequentRouteDates: rowData?.frequentRouteDates || null,
    fleetadmin:rowData.fleetadmin,
    ratePerMile:rowData.ratePerMile,
    availableWeight:rowData.availableWeight
  }

  if (rowData.routeType === "Available Capacity") {
      // line segment object pushed to locs
      basicData["origin"]={lat: rowData?.route.lat, lng: rowData?.route.lng, add: rowData?.route.add}
      basicData["destination"]=null
  }


  if (rowData.routeType === "Future Capacity"&&rowData?.route?.emptyRouteFrom) {

      basicData["origin"]=rowData.route.emptyRouteFrom
      basicData["destination"]=rowData.route.emptyRouteTo
  }

  if (rowData.routeType === "Preferred Lane") {
      
      basicData["origin"]=rowData.route.cityOne
      basicData["destination"]=rowData.route.cityTwo
  
  }

  if (rowData.routeType === "Future Capacity" && rowData?.location?.lat && rowData?.baseCity?.lat) {


      basicData["origin"]=rowData?.location
      basicData["destination"]=rowData?.baseCity
      
    
  }

  return basicData
}

  // if (props?.role?.usertype === "shipper" || auth.info?.profile?.brokerId) {
  //   columns.unshift();
  // }

  function checkTrailerType(trailerType, driver) {
    return (
      trailerType === driver.trailerType ||
      trailerType === "Select Trailer Type"
    );
  }

  function calculateTotalDistance(route) {
    if(!route[0].latitude||!route[1].latitude||!route[0].longitude||!route[1].longitude) return 0
    
    return geolib.getDistance(route[0], route[1]);
  }

  useEffect(() => {

    let tracking_data=[]
    if(props.data.length>0){
          for(const data of props.data){
            if(data.route.cityOne&&data.route.cityTwo){
              let trackArray={
                       id:data.id,
                      address: [
                        { latitude: data.route.cityOne.lat, longitude: data.route.cityOne.lng}, 
                        { latitude: data.route.cityTwo.lat, longitude: data.route.cityTwo.lng}, 
                         ] 
                }
              tracking_data.push(trackArray)
            }
            else if(data.route?.emptyRouteTo&&data.route?.emptyRouteFrom){
              let trackArray={
                  id:data.id,  
                  address: [ 
                        { latitude: data.route.emptyRouteFrom.lat, longitude: data.route.emptyRouteFrom.lng}, 
                        { latitude: data.route.emptyRouteTo.lat, longitude: data.route.emptyRouteTo.lng}, 
                  ]
                }
              tracking_data.push(trackArray)
            }

            else if(data.routeType==="Future Capacity"&&!data.route?.emptyRouteTo){
                const hostLocation={latitude:data.location.lat,longitude:data.location.lng}
                const dropLocation={latitude:data.baseCity.lat,longitude:data.baseCity.lng}
                let trackArray={
                  id:data.id,
                 address: [hostLocation, dropLocation]
                  }
                tracking_data.push(trackArray)
            }

            
          }
    }

    if (props.data.length > 0 && props.pickupAddress && props.dropAddress&&!props.selectedDate) {
        setReset(false);
        const pickupAddress ={latitude: props.pickupAddress.coords.lat, longitude:props.pickupAddress.coords.lng} ;
        const dropAddress ={latitude: props.dropAddress.coords.lat, longitude:props.dropAddress.coords.lng} ;
        
        const trailerType = props.trailerType;

          const viableTruckRoutes = evaluateAndSortTruckRoutes(pickupAddress, dropAddress, tracking_data);
          let filteredDatas=[]
          for(const data of viableTruckRoutes){
              let dataExist=props.data.filter(e=>{
                 return e.id===data.id
              })
              if(dataExist.length>0) {
                  if(trailerType === dataExist[0].trailerType ||trailerType === "Select Trailer Type"){
                    filteredDatas.push({...dataExist[0],canBeBooked:data?.canBeBooked})
                  }
              }
          }
          const alwaysAvailebleRoutes=[]
         for(const data of props.data){
            if(data.routeType==="Available Capacity"&&data.travelRadius&&props.dropAddress&&props.pickupAddress){
                let travelRadius=Number(data.travelRadius)*1609.34
                const hostLocation={latitude:data.route.lat,longitude:data.route.lng}
                const pickLocation={latitude:props.pickupAddress.coords.lat,longitude:props.pickupAddress.coords.lng}

                const DistancePickFromLocation=calculateTotalDistance([hostLocation,pickLocation])
                if(travelRadius>=DistancePickFromLocation)  alwaysAvailebleRoutes.push({...data,canBeBooked:true})
              }
    
              else if(data.routeType==="Available Capacity"&&!data.travelRadius){
                alwaysAvailebleRoutes.push({...data,canBeBooked:true})
              }
           }
         if(alwaysAvailebleRoutes.length>0) setFilter([...alwaysAvailebleRoutes,...filteredDatas])
         else setFilter(filteredDatas)
    }

    if(props.selectedDate&&!(props.pickupAddress && props.dropAddress)&&props.data.length > 0 ){  
       setReset(false); 
      setFilter(Object.values( checkDateAndTrailerType( props.data)))
    }  

    if(props.selectedDate&&props.data.length > 0 && props.pickupAddress && props.dropAddress){
        setReset(false);
        const pickupAddress ={latitude: props.pickupAddress.coords.lat, longitude:props.pickupAddress.coords.lng} ;
        const dropAddress ={latitude: props.dropAddress.coords.lat, longitude:props.dropAddress.coords.lng} ;
        
          const viableTruckRoutes = evaluateAndSortTruckRoutes(pickupAddress, dropAddress, tracking_data);
          let filteredDatas=[]
          for(const data of viableTruckRoutes){
              let dataExist=props.data.filter(e=>{
                return e.id===data.id
              })
              if(dataExist.length>0) filteredDatas.push({...dataExist[0],canBeBooked:data?.canBeBooked})
          }

          const alwaysAvailebleRoutes=[]
          for(const data of props.data){
             if(data.routeType==="Available Capacity"&&data.travelRadius&&props.dropAddress&&props.pickupAddress){
                 let travelRadius=Number(data.travelRadius)*1609.34
                 const hostLocation={latitude:data.route.lat,longitude:data.route.lng}
                 const pickLocation={latitude:props.pickupAddress.coords.lat,longitude:props.pickupAddress.coords.lng}
 
                 const DistancePickFromLocation=calculateTotalDistance([hostLocation,pickLocation])
                 if(travelRadius>=DistancePickFromLocation)  alwaysAvailebleRoutes.push({...data,canBeBooked:true})
               }
               else if(data.routeType==="Available Capacity"&&!data.travelRadius){
                 alwaysAvailebleRoutes.push({...data,canBeBooked:true})
               }
            }
          
          let dataReadyToBePushed=[]
          
          if(alwaysAvailebleRoutes.length>0&&Object.values( checkDateAndTrailerType(alwaysAvailebleRoutes)).length>0){
            for (const data of Object.values( checkDateAndTrailerType(alwaysAvailebleRoutes)) ){
              dataReadyToBePushed.push(data)
            }
        }

          if(filteredDatas.length>0&&Object.values( checkDateAndTrailerType(filteredDatas)).length>0){
              for (const data of Object.values( checkDateAndTrailerType(filteredDatas)) ){
                dataReadyToBePushed.push(data)
              }
          }

          setFilter(dataReadyToBePushed)    

    }

    if(props.data.length > 0 && props.trailerType&&!props.selectedDate&&!(props.pickupAddress && props.dropAddress)){
       const trailerType = props.trailerType;
       const targetedDatas={}
       setReset(false);
       for(const data of props.data){
          if(checkTrailerType(trailerType, data)&&!targetedDatas.hasOwnProperty(data.id)){
            targetedDatas[data.id]=data
          }
       }
       setFilter(Object.values( targetedDatas))

    }
  }, [
    props.pickupAddress,
    props.dropAddress,
    props.selectedDate,
    props.boardType,
    props.trailerType
  ]);

  const checkDateAndTrailerType=(data)=>{
    let filteredData = {};
    const trailerType = props.trailerType;
    data.forEach((driver) => {
      const selectedDate = props.selectedDate? props.selectedDate: null;
      if (selectedDate) {
        // if (!driver?.route?.lat) {
          const foundDate =
            driver?.frequentRouteDates &&
            driver?.frequentRouteDates?.length > 0 &&
            driver?.frequentRouteDates?.includes(
              moment(selectedDate).format("dddd")
            );
          
          if (foundDate) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }
  
          if (
            driver?.route?.routedate &&
            moment(new Date(selectedDate)).format("L") ===
            moment(new Date(driver?.route?.routedate)).format("L")
          ) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }
  
          if (
            (driver?.routeType === "Future Capacity" && !driver?.route?.emptyRouteFrom)&&
            moment(new Date(selectedDate)).format("L") ===
              moment(new Date(driver?.route)).format("L")
          ) {
            if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }

          if(driver?.routeType === "Available Capacity" && moment(new Date(selectedDate)).isAfter(moment(new Date()).add(3, 'hours')) &&  moment(new Date(selectedDate)).format("L") === moment(new Date()).format("L")){
            if (checkTrailerType(trailerType, driver) && !filteredData.hasOwnProperty(driver.id)) {
              filteredData[driver.id] = driver;
            }
          }


        // } else {
        //   if (checkTrailerType(trailerType, driver)&&!filteredData.hasOwnProperty(driver.id)) {
        //     filteredData[driver.id] = driver;
        //   }
        // }
      }
    });

    return filteredData

  }
  
  
// Function to evaluate and sort truck routes based on user input
function evaluateAndSortTruckRoutes(userPickUp, userDropOff, truckRoutes) {
  function calculateBearing(point1, point2) {
    return geolib.getRhumbLineBearing(point1, point2);
  }

  function isDirectionAligned(userRoute, truckRoute) {
    const userAvgBearing = calculateAverageBearing(userRoute);
    const truckAvgBearing = calculateAverageBearing(truckRoute);

    const firstCondition= Math.abs(userAvgBearing - truckAvgBearing) < 15
    const secondCondition = Math.abs(userAvgBearing - truckAvgBearing) > 165
    const thirdCondition = Math.abs(userAvgBearing - truckAvgBearing) < 195
    return firstCondition || (secondCondition && thirdCondition);
  }

  function calculateAverageBearing(route) {
    let totalBearing = 0;
    for (let i = 0; i < route.length - 1; i++) {
      totalBearing += calculateBearing(route[i], route[i + 1]);
    }
    return totalBearing / (route.length - 1);
  }

  function calculateProximityScore(userRoute, truckRoute) {
    let minDistance = Infinity;
    const weightFactors = [1, 0.8, 0.6]; // Higher weight closer to start/end
    for (let i = 0; i < userRoute.length; i++) {
      for (const point of truckRoute) {
        const distance = geolib.getDistance(userRoute[i], point);
        minDistance = Math.min(minDistance, distance * weightFactors[i]);
      }
    }

    // Adjust threshold as needed (50 km here)
    const threshold = 50 * 1000;
    return minDistance <= threshold;
  }

  function isRouteSegmentConsistent(userBearing, truckSegment) {
    const truckSegmentBearing = calculateBearing(
      truckSegment[0],
      truckSegment[1]
    );
    return 90 < Math.abs(userBearing - truckSegmentBearing) <= 30; // Allow some tolerance
  }

  function isRouteConsistent(userRoute, truckRoute) {
    const userBearing = calculateBearing(userRoute[0], userRoute[1]);
    for (let i = 0; i < truckRoute.length - 1; i++) {
      const truckSegment = truckRoute.slice(i, i + 2); // Consider each segment
      if (!isRouteSegmentConsistent(userBearing, truckSegment)) {
        return false;
      }
    }
    return true;
  }

  const userRoute = [userPickUp, userDropOff];
  const viableRoutes = truckRoutes.filter((truckRoute) => {
    const truckRouteDistance = calculateTotalDistance(truckRoute.address);
    const userRouteDistance = calculateTotalDistance(userRoute);
    const toleranceLowerBound = userRouteDistance * 0.9;
    const toleranceUpperBound = userRouteDistance * 1.1;
    const distanceCheck =
      truckRouteDistance >= toleranceLowerBound &&
      truckRouteDistance <= toleranceUpperBound;
    const directionalityCheck = isDirectionAligned(
      userRoute,
      truckRoute.address
    );
    const proximityCheck = calculateProximityScore(
      userRoute,
      truckRoute.address
    );
    const consistencyCheck = isRouteConsistent(userRoute, truckRoute.address);


    return (
      //distanceCheck &&
      directionalityCheck &&
      proximityCheck &&
      consistencyCheck
    );
  });
  if (viableRoutes.length > 0) {
    let flaggedRoutes = [];
    for (const Routes of viableRoutes) {
      Routes["canBeBooked"] = true;
      flaggedRoutes.push(Routes);
    }
    return flaggedRoutes;
  }
  return viableRoutes;
}
  
  const clearFilterAndMatch = () => {
    setReset(true);
    setFilter([]);
    props.setDropAddress(null);
    props.setPickupAddress(null);
    props.setSelectedDate(null);
    props.setTrailerType("Select Trailer Type");
  };


  return (
    <div style={{ width: "100%" }}>
      {props.role ? (
        <GridContainer
          spacing={2}
          className={classes.matchAndClearContainer}
          style={{ marginBottom: "10px", paddingTop: "15px" }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            <Button
              color="primaryButton"
              size="lg"
              rel="noopener noreferrer"
              className={classes.items}
              onClick={clearFilterAndMatch}
              style={{
                height: 40,
                marginLeft: "10px",
                width: "150px",
                minWidth: "200",
                backgroundColor: "#01aac1",
              }}
            >
              <i
                className="fas fa-times-circle"
                style={
                  isRTL === "rtl"
                    ? { marginLeft: "10px" }
                    : { marginRight: "10px" }
                }
              />
              {t("clear_button")}
            </Button>

            <Button
              color="primaryButton"
              size="lg"
              rel="noopener noreferrer"
              className={classes.items}
              style={{
                height: 40,
                marginLeft: "10px",
                width: "150px",
                minWidth: "200",
                backgroundColor: "#01aac1",
              }}
              onClick={() => props.setBoardType("On Map")}>

                View On a Map
            </Button>

          </div>
        </GridContainer>
      ) : null}
      <MaterialTable
        title={"Public Capacity Board"}
        columns={columns}
        data={reset && props?.data?.length > 0 ? props.data : filter}
        options={{
          exportButton: settings.AllowCriticalEditsAdmin,
          sorting: true,
        }}
        localization={{
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: "",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            // labelRowsSelect: (t('rows')),
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
          body: {
            emptyDataSourceMessage: !props.role
              ? t("sign_up_or_login")
              : t("no_online_drivers"),
          },
        }}
        style={{  
           backgroundColor: "rgba(255, 255, 255,0.1)",
           backdropFilter: 'blur(10px)', width: "100%", 
           overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      />

    </div>
  );
};

export default PublicTruckBoard;
/**
 * const geolib = require('geolib');


 */
