import React,{ useState, useEffect, useContext,useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControl, Grid, IconButton, Link, Modal, TextField } from '@material-ui/core';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';
import LinkIcon from '@mui/icons-material/Link';
import { Input, InputLabel, Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  carriersStyle:{
    padding: '5px',
    "&:hover,&:focus": {
      backgroundColor: '#F7F6F6',
      width: '100%',
    },
  }
}));

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const classes = useStyles();

  const { t,i18n } = useTranslation();
  const auth = useSelector(state => state.auth);

  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    AddBroker,
    updateSharedBrokerStatus, 
    deleteSharedBroker,
    fetchSharedCarriersOfBroker,
    DispatcherVsAgentAdd,
    DispatcherVsAgentFetch,
    DispatcherVsAgentDelete,
     DispatcherVsAgentUpdate,

     fetchUsersActiveHistory,
  } = api;
  const rootRef = useRef(null);
  const [data, setData] = useState([]);
  const sharedCariers = useSelector(state => state.usersdata.carrierBrokers);
  const allUsers=useSelector(state => state.usersdata.users)
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const  dispatcherAgents = useSelector(state => state.usersdata. dispatcherAgents);

  const [allCarrierData,setAllCarrierData]=useState([])

  useEffect(()=>{

    if(isSuperDispatcher()){
      dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid,target:"carrier"}))
    }
    else{
      dispatch(fetchSharedCarriersOfBroker({brokerId:auth.info.uid}));

    }
},[dispatch,fetchSharedCarriersOfBroker,DispatcherVsAgentFetch,auth.info]);



function isSuperDispatcher(){
  return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
}

  const fetchCarrierData = async (carrierOnly) => {
   
    let availableCarriers = [];
 
    for (const carrier of carrierOnly) {
             try {
                const res = await dispatch(fetchUsersActiveHistory(carrier.id));
                if (res && res?.activeHistoryPerWeek) {
                  availableCarriers.push({ ...carrier, activeHistory: res.activeHistoryPerWeek });
                } else {
                  availableCarriers.push({ ...carrier });
                }
              } catch (error) {
                 console.error(error);
              }
        
      }    


    setAllCarrierData(availableCarriers);
  };


  useEffect(()=>{

    if(allUsers&&allUsers.length>0){
          let carrierOnly= allUsers.filter(e=>{
            return e.usertype==='fleetadmin'&&e.approved
          })
          if(!carrierOnly || carrierOnly.length===0){
            setAllCarrierData([])
          }
          else{
            fetchCarrierData(carrierOnly)
          }

    }
  },[allUsers])


  useEffect(()=>{

    const filteredDispatcherAgents = dispatcherAgents ? dispatcherAgents.filter(agent => agent !== undefined) : [];
    const filteredSharedCarierss = sharedCariers ? sharedCariers.filter(broker => broker !== undefined) : [];
    
    if(isSuperDispatcher() && filteredDispatcherAgents.length > 0){
      setData(filteredDispatcherAgents)
    }
    else if(!isSuperDispatcher() && filteredSharedCarierss.length > 0){
        setData(filteredSharedCarierss);
  }
    else{
      setData([]);
    }
    loaded.current = true;
  },[sharedCariers,auth.info.profile.usertype, auth.info.uid,dispatcherAgents]);

  const [searchValue,setSearchValue]=useState("")
  const [showSearchModel,setShowSearchModel]=useState(false)

  const openSearchModal=(rowData)=>{
     
    setShowSearchModel(true)
  }
  const handleSearchModal = (e) => {
    setShowSearchModel(false);
  };

  const sortCarriersBasedOnSharedCompanies = (AllData) => {
    return AllData.sort((a, b) => {
      let aNumberOfCompanies = 0;
      let bNumberOfCompanies = 0;
  
      if (a.SharedBrokers && a.SharedBrokers.length > 0) {
        aNumberOfCompanies += a.SharedBrokers.length;
      }
      if (a.SharedDispatcher && a.SharedDispatcher.length > 0) {
        aNumberOfCompanies += a.SharedDispatcher.length;
      }
      if (a.SharedCarriers && a.SharedCarriers.length > 0) {
        aNumberOfCompanies += a.SharedCarriers.length;
      }
  
      if (b.SharedBrokers && b.SharedBrokers.length > 0) {
        bNumberOfCompanies += b.SharedBrokers.length;
      }
      if (b.SharedDispatcher && b.SharedDispatcher.length > 0) {
        bNumberOfCompanies += b.SharedDispatcher.length;
      }
      if (b.SharedCarriers && b.SharedCarriers.length > 0) {
        bNumberOfCompanies += b.SharedCarriers.length;
      }
  
      return bNumberOfCompanies - aNumberOfCompanies;
    });
  };

  
  const sortBaseedOnActiveHistory = (AllData) => {
    return AllData.sort((a, b) => {
      if (a.activeHistory && b.activeHistory) {
        return b.activeHistory - a.activeHistory;
      } else if (a.activeHistory) {
        return -1;
      } else if (b.activeHistory) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const [unSelecetedCarriers,setUnSelecetedCarriers] = useState(null)
  useEffect(()=>{

    
    if(allCarrierData&&allCarrierData.length>0){
    
      if(isSuperDispatcher()){
          if(dispatcherAgents&&dispatcherAgents.length>0){
            let availableCarriers=[]
              for (const carrier of allCarrierData){
                    let Carriers=dispatcherAgents.filter(e=>{
                      return e?.email==carrier?.email
                    })
                  if(Carriers.length===0) availableCarriers.push(carrier)
                    
              }
              setUnSelecetedCarriers(availableCarriers)
      
          }
          else {
            setUnSelecetedCarriers(allCarrierData)
          }
        
      }
      else{
        if(sharedCariers&&sharedCariers.length>0){
          
          let availableCarriers=[]
           for (const carrier of allCarrierData){
                 let Carriers=sharedCariers.filter(e=>{
                   return e?.email==carrier?.email
                 })
                if(Carriers.length===0) availableCarriers.push(carrier)
                 
           }
           setUnSelecetedCarriers(availableCarriers)
   
        }
        else{
          setUnSelecetedCarriers(allCarrierData)
        }
        
      }

    }

  },[allCarrierData,dispatcherAgents,sharedCariers])


  const [filteredData,setFilteredData]=useState([])
  useEffect(()=>{
    if(unSelecetedCarriers&&unSelecetedCarriers.length>0){
      let filtering=unSelecetedCarriers.filter(e=>{
        
        if(e.carrierData?.dotNumber){
          return  e.carrierData?.dotNumber.toString().includes(searchValue.trim())|| 
                  e.companyName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                  e.carrierData?.legalName?.toLowerCase().includes(searchValue.trim().toLowerCase())
        }
        else{
          return e?.firstName.toLowerCase().includes(searchValue.trim())||
                  e.companyName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                  e?.lastName?.toLowerCase().includes(searchValue.trim().toLowerCase())
        }
      })
    setFilteredData(sortBaseedOnActiveHistory(filtering))
    }
    else{
      setFilteredData([])
    }

  },[searchValue,unSelecetedCarriers])


const [newData,setNewData]=useState(null)
const AddCarrierData=(data)=>{
  setNewData(data)
  setShowSearchModel(false);
}

const [columns,setColumns]=useState([])

useEffect(()=>{
    if(isSuperDispatcher()){
      setColumns([
        { title: 'Company Name', field: 'companyName', editable:'onAdd', 
        render: rowData =><div>{rowData.approved?
            <Link style={{textDecoration:"none"}} href={`/user/${rowData.id}?redirected=my-carriers`}>
                {rowData.companyName?rowData.companyName:`${rowData.firstName} ${rowData.lastName}`}
          </Link>
          :<span style={{color:"red"}}>
                {rowData.id?
                     <>
                      {rowData?.companyName?rowData?.companyName:`${rowData?.firstName} ${rowData?.lastName}`}
                     </>
                 :
                 <div>{rowData.inviteName||rowData.email} (Invited)</div>
                }
                 
            </span>}
        </div>,
        editComponent: (props) => (
            <button onClick={() => openSearchModal(props.rowData)}>
                {newData?.companyName?
                   <>  
                      {newData.companyName}
                   </>
                   :
                   newData?.inviteName||"Add Carrier"
                }
            </button>
          ),
          cellStyle:{ paddingLeft:isRTL ==='rtl'? 50 : 15,width:150,minWidth:150},
       },
        
        { title:"Country",  field: 'country', editable: 'never', 
          render: rowData =>
            <div>
                {rowData?.selectedcountry?.label}
            </div>,
           cellStyle:{ textAlign:'center',width:180,minWidth:180},
          headerStyle:{textAlign:'center'},
         },
         { title: "Approved By Me",  field: 'isDispatcherActive', type:'boolean',initialEditValue: true,
              headerStyle:{textAlign:'center',width:180,minWidth:180},
              cellStyle:{ textAlign:'center',width:180,minWidth:180} 
          },
         { title:"Approved By Carrier",  field: 'aprovedByCarrier', type:'boolean',  editable: 'never', 
           cellStyle:{ textAlign: 'center'},
           headerStyle:{textAlign:'center'},
         },
             
      ])
    }
     else{
      setColumns([
        { title: 'Company Name', field: 'companyName', editable:'onAdd', 
        render: rowData =><div>{rowData.approved?
            <Link style={{textDecoration:"none"}} href={`/user/${rowData.id}?redirected=my-carriers`}>
                {rowData.companyName?rowData.companyName:`${rowData.firstName} ${rowData.lastName}`}
          </Link>
          :<span style={{color:"red"}}>
                {rowData.id?
                     <>
                      {rowData?.companyName?rowData?.companyName:`${rowData?.firstName} ${rowData?.lastName}`}
                     </>
                 :
                  <div>{rowData.inviteName||rowData.email} (Invited)</div>
                 }
                 
            </span>}
        </div>,
        editComponent: (props) => (
            <button onClick={() => openSearchModal(props.rowData)}>
                {newData?.companyName?
                   <>  
                      {newData.companyName}
                   </>
                   :
                   newData?.inviteName||"Add Carrier"
                }
            </button>
          ),
          cellStyle:{ paddingLeft:isRTL ==='rtl'? 50 : 15,width:150,minWidth:150},
       },
      
        { title:"Country",  field: 'country', editable: 'never', 
        render: rowData =>
          <div>
              {rowData?.selectedcountry?.label}
          </div>,
         cellStyle:{ textAlign:'center',width:180,minWidth:180},
        headerStyle:{textAlign:'center'},
       },

         { title:"Approved By Carrier",  field: 'isCarrierActive', type:'boolean',  editable: 'never', 
           cellStyle:{ textAlign: 'center'},
           headerStyle:{textAlign:'center'},
         },
         { title: "Approved By Me",  field: 'aprovedByBroker', type:'boolean',initialEditValue: true,
              headerStyle:{textAlign:'center',width:180,minWidth:180},
              cellStyle:{ textAlign:'center',width:180,minWidth:180} 
          },
          { title:"View on Map ",  field: 'viewOnMap', editable: 'never', 
          render: rowData =>
            <div>{rowData?.isCarrierActive&&rowData?.aprovedByBroker&&rowData.approved?
              <Link style={{textDecoration:"none"}} href={`/fleetmap?carrier=${rowData.id}&&redirected=my-carriers`}>
                <Button style={{backgroundColor:"#01aac1",color:"white"}}>View</Button>
              </Link>
             : <Button disabled>View</Button>
             }
                
            </div>,
           cellStyle:{ textAlign:'center',width:180,minWidth:180},
          headerStyle:{textAlign:'center'},
         }
        
    
      ])
    }
},[auth,newData])
const [openInvitation,setOpenInvitation]=useState(false)
const [emailToBeInvite,setEmailToBeInvite]=useState("")
const [nameToBeInvite,setNameToBeInvite]=useState("")
const [emailError,setEmailError]=useState("")
const OpenInviteViaEmail=()=>{
  setOpenInvitation(true)
  setShowSearchModel(false);
}
const closeInviteViaEmail=()=>{
  setOpenInvitation(false)
  setShowSearchModel(false);
}

function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const handlePress = (e) => {
  if(e.key === 'Enter'){
    InviteViaEmail()
  }
}

const InviteViaEmail=()=>{


  if(isValidEmail(emailToBeInvite)){
    if(nameToBeInvite&&nameToBeInvite.length>0){
          setNewData({email:emailToBeInvite,type:"invitation",inviteName:nameToBeInvite})
          setOpenInvitation(false)
          setShowSearchModel(false);
    }
    else{
        setEmailError("Company Name is Required")
        setTimeout(()=>{
          setEmailError("")
        },5000)
    }
  }
  else{
    setEmailError("Invalid email")
    setTimeout(()=>{
      setEmailError("")
    },5000)
  }
  setEmailToBeInvite('')
  setNameToBeInvite('')
}

return (
    !loaded.current? <CircularLoading/>:
    <>
        <MaterialTable
          title={"Manage Freight Carriers"}
          columns={columns}
          style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
          data={data||[]}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
          }}
          localization={{body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
              (t('blank_message'))
          ),
          editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
              }, 
            },
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: (t('actions')) 
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
          }}
          editable={{
            onRowAdd: newDatas =>
            new Promise((resolve,reject) => {
              setTimeout(() => {
                   if(isSuperDispatcher()){
                    newDatas['dispacherId']=auth.info.uid
                    newDatas['target']='carrier'
                    newDatas['data']=newData
                    
                    dispatch(DispatcherVsAgentAdd(newDatas));
                    dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid,target:"carrier"}))
                    resolve();
                    setNewData(null)
                  }
                  else{
                     newDatas['BrokerId'] = auth.info.uid;
                     newDatas['agent'] = auth.info.profile.usertype;
                     newDatas['createdAt'] = new Date().toISOString();
                     newDatas['data']=newData
                     dispatch(AddBroker(newDatas));
                     dispatch(fetchSharedCarriersOfBroker({brokerId:auth.info.uid}));
                     resolve();
                     setNewData(null)

                   }
                setNewData(null)   
              }, 600);
            }),
            onRowUpdate: (newData,) =>
            new Promise(resolve => {
                setTimeout(() => {
                  if(isSuperDispatcher()){
                    newData['dispacherId']=auth.info.uid
                    newData['target']='carrier'
                    dispatch( DispatcherVsAgentUpdate(newData))
                    dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid,target:"carrier"}))
                    
                    resolve();
                  }
                  else{
                    newData["BrokerId"]=auth.info.uid
                    newData["agent"]=auth.info.profile.usertype
                    dispatch(updateSharedBrokerStatus(newData));
                    dispatch(fetchSharedCarriersOfBroker({brokerId:auth.info.uid}));
                    resolve();
                    
                  }                
                }, 600);
              }),
            onRowDelete: oldData =>
              settings.AllowCriticalEditsAdmin?
              new Promise(resolve => {
                setTimeout(() => {
                    if(isSuperDispatcher()){
                      oldData['dispatcherId']=auth.info.uid
                      oldData['target']='carrier'
                      dispatch(DispatcherVsAgentDelete(oldData))
                      dispatch(DispatcherVsAgentFetch({dispatcherId:auth.info.uid,target:"carrier"}))
                      
                    }
                    else{
                      oldData["BrokerId"]=auth.info.uid
                      oldData["agent"]=auth.info.profile.usertype
                      dispatch(deleteSharedBroker(oldData));
                      
                      dispatch(fetchSharedCarriersOfBroker({brokerId:auth.info.uid}));
                    }
                    resolve();
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              })
              , 
          }}
        />

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openInvitation}
        onClose={closeInviteViaEmail}
        className={classes.modal}
        container={() => rootRef.current}
      >
           <Grid
            container
            spacing={1}
            className={classes.paper}
              >
            <Grid item xs={12} style={{width:"100%"}}>
                 <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"15px",}}>
                      <div>
                          <TextField 
                              placeholder='Company Name'
                              onChange={(e)=>setNameToBeInvite(e.target.value)}
                              value={nameToBeInvite} 
                              onKeyDown={handlePress}
                              style={{}}
                              />
                      </div>
                      <div>
                        <TextField 
                          placeholder='Email'
                          onChange={(e)=>setEmailToBeInvite(e.target.value)}
                          value={emailToBeInvite} 
                          onKeyDown={handlePress}
                          />

                      </div>
                       <div>
                       <Tooltip title="Invite">
                          <Button style={{color:"red"}} onClick={InviteViaEmail}>Invite</Button>
                       </Tooltip>
                    </div>
                  </div>
                  {emailError&&<div style={{color:"red",textAlign:"center"}}>{emailError}</div>}
                  
            </Grid>
          </Grid>
         
      </Modal>
         
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={showSearchModel}
        onClose={handleSearchModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
        >
          <Grid item xs={12} style={{textAlign:"center"}}>
               <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

                  <TextField 
                    value={searchValue} 
                    placeholder='Search...'
                    onChange={(e)=>setSearchValue(e.target.value)}
                    style={{width:"90%"}}
                    label="Search For Active Carriers" 
                    variant="standard"
                    />
                    
                </div>
          </Grid>

          <Grid item xs={12} style={{height:"58vh",overflow:"auto",marginTop:"8px",marginBottom:"8px"}}>
              {filteredData.map(e=>{
                 return <div key={e.id} onClick={()=>AddCarrierData(e)} style={{display:"flex",justifyContent:"flex-start",gap:"10px",marginBottom:"20px",cursor:"pointer"}} className={classes.carriersStyle}>
                           <div><HdrWeakIcon style={{fontSize:"15px",color:"red"}}/> </div>
                           <div>
                              <div style={{ font: '16px Open Sans, sans-serif',marginBottom:"6px"}}>{e.companyName}</div>
                              {e.carrierData.legalName?
                              <>
                                  <div style={{opacity:0.8,fontSize:"12px",fontWeight:600,paddingLeft:"10px"}}>{e?.selectedcountry?.label}</div>
                                  <div style={{opacity:0.5,fontSize:"12px"}}> {e.carrierData.legalName}</div>
                                  <div style={{opacity:0.5,fontSize:"12px"}}>Dot Number: {e.carrierData.dotNumber}</div>
                                  <div><Link href={`/user/${e.id}?redirected=my-carriers`}>See more</Link></div>
                              </>
                              :
                              <> 
                                <div  style={{opacity:0.8,fontSize:"12px",fontWeight:600,paddingLeft:"10px"}}>{e?.selectedcountry?.label}</div>
                                <div  style={{opacity:0.5,fontSize:"12px"}}>{e.firstName} {e.lastName}</div>
                                <div style={{opacity:0.5,fontSize:"12px"}}>No Dot Number is Added</div>
                                <div><Link href={`/user/${e.id}?redirected=my-carriers`}>See more</Link></div>
                               </>
                          
                              }
                           </div>
                   
                   </div>
              })}
              {filteredData.length===0&&<div style={{fontSize:"20px",opacity:0.5,display:"flex",justifyContent:"center",alignItems:"center",height:'100%'}}>No Company is found</div>}
          </Grid>

          <div style={{ display:"flex",justifyContent:"space-between",alignItems:"center",gap:"30px"}}>
               <div>
                  <Button
                    onClick={handleSearchModal}
                    variant="contained"
                    color="dangerColor">
                    Cancel
                  </Button>
               </div>
                <div>
                    <Button style={{background:"red",color:"white"}} onClick={OpenInviteViaEmail}>Invite New</Button>
                </div>
          </div>
          
        
          
        </Grid>
      </Modal>
    
    </>
  );
}
