import React,{ useState, useEffect, useContext,useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import AlertDialog from 'components/AlertDialog';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Link } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, IconButton, Modal, TextField } from '@material-ui/core';
import HdrWeakIcon from '@mui/icons-material/HdrWeak';
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  carriersStyle:{
    padding: '5px',
    "&:hover,&:focus": {
      backgroundColor: '#F7F6F6',
      width: '100%',
    },
  }
}));

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();

  const {
      fetchUser,
     DispatcherVsAgentDelete,
    DispatcherVsAgentAdd,
    DispatcherVsAgentFetch,
     DispatcherVsAgentUpdate
  } = api;
  const [data, setData] = useState([]);
  //const sharedBrokers = useSelector(state => state.usersdata.carrierBrokers);
  const  dispatcherAgents = useSelector(state => state.usersdata.dispatcherAgents);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const allUsers=useSelector(state => state.usersdata.staticusers)
  const rootRef = useRef(null);

  useEffect(()=>{
  
    if(isShipper()){
      dispatch(DispatcherVsAgentFetch({shipperId:auth.info.uid}))
    }
    else  {
      dispatch(DispatcherVsAgentFetch({carrerId:auth.info.uid}))
    }
    
},[dispatch,auth.info]);

  useEffect(()=>{
    // if(sharedBrokers){
    //   setData(sharedBrokers);
    // }else 
    if( dispatcherAgents){
      setData( dispatcherAgents);
    }
    else{
      setData([]);
    }
    loaded.current = true;
  },[auth.info.profile.usertype, auth.info.uid, dispatcherAgents]);

function isShipper(){
  return auth.info.profile.usertype==='shipper'
}

const [newData,setNewData]=useState(null)
const [showSearchModel,setShowSearchModel]=useState(false)
const [searchValue,setSearchValue]=useState("")
const [AllDispatcherData,setAllDispatcherData]=useState([])

useEffect(()=>{

  if(allUsers&&allUsers.length>0){
        let dispatcherOnly= allUsers.filter(e=>{
          return e.usertype==='dispatcher'&&e.independent
        })
        
        if(dispatcherAgents&&dispatcherAgents.length>0){
              let availableDispatchers=[]
                for (const broker of dispatcherOnly){
                      let dispatchers=dispatcherAgents.filter(e=>{
                        return e?.email==broker?.email
                      })
                    if(dispatchers.length===0) availableDispatchers.push(broker)
                      
                }
              setAllDispatcherData(availableDispatchers)
        
            }
            else {
              setAllDispatcherData(dispatcherOnly)
            }      
  }
},[allUsers,dispatcherAgents])


const [columns,setColumns]=useState([
  { title: t('first_name'), field: 'firstName', editable:'never', 
    render: rowData =><div style={{color:!rowData.approved&&"red"}}> {rowData.firstName}</div>,
    cellStyle:{ paddingLeft:isRTL ==='rtl'? 50 : 15,width:150,minWidth:150},
  },
  { title: t('last_name'), field: 'lastName',  editable:'never', 
    render: rowData =><div style={{color:!rowData.approved&&"red"}}> {rowData.lastName}</div>,
     cellStyle:{ paddingLeft:isRTL ==='rtl'? 30 : 15,width:150,minWidth:150}
    },
  { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",
      headerStyle:{textAlign:'center'},
      cellStyle:{ paddingLeft:isRTL ==='rtl'? 30 : 15,width:180,minWidth:180} 
  },
  {
    title:'Telegram Username',field: "TelegramId",editable: "never",
    render: (rowData) =>{
      return(
          <div>{rowData?.TelegramId?.username?<a target='_blank' href={`https://web.telegram.org/k/#@${rowData?.TelegramId?.username}`}>{rowData?.TelegramId.username}</a>:"Not Added"}</div>
      ) 
      },
      headerStyle: {textAlign: 'center'},
      cellStyle: {paddingLeft:isRTL ==='rtl'? 30 : 15,width:180,minWidth:180, textAlign: 'center'},
  },
   { title:"Approved By Dispatcher",  field: 'isBrokerActive', type:'boolean', editable: 'never', 
       cellStyle:{ textAlign: 'center'},
       headerStyle:{textAlign:'center'},
    },
   { title: isShipper()?"Approved By Broker":"Approved By Carrier",  field: 'aprovedByBroker', type:'boolean',initialEditValue: true,
        headerStyle:{textAlign:'center',width:180,minWidth:180},
        cellStyle:{ textAlign:'center',width:180,minWidth:180} 
    },
    

])

const location=useLocation()
function giveMeIdThenIwillGiveYouName(id){
  if(!allUsers) return ""

  let targetUser=allUsers.filter(e=>{
     return e.id===id
  })
  if(targetUser.length===0) return ""
  return targetUser[0].firstName+" "+targetUser[0].lastName
}

const openSearchModal=(rowData)=>{
  setShowSearchModel(true)
}

useEffect(()=>{
   if(isShipper()){
    setColumns([
      { title: t('first_name'), field: 'firstName', editable:'onAdd', 
      render: rowData =><div style={{color:!rowData.approved&&"red"}}> 
            {rowData.approved&&rowData.id?
              <Link href={`/user/${rowData.id}?redirected=third-party-dispatch`}>{rowData.firstName}</Link>
                :
                rowData?.firstName
              }
            {!rowData.id&&<div>{rowData.inviteName} (Invited)</div>}
        </div>,
        editComponent: (props) => (
          <button onClick={() => openSearchModal(props.rowData)}>
              {newData?.firstName?
                 <>  
                    {newData?.firstName}
                 </>
                 :
                 newData?.inviteName||'Dispatch Agencies'
              }
          </button>
        ),
      cellStyle:{ paddingLeft:isRTL ==='rtl'? 50 : 15,width:150,minWidth:150},
      },
      { title: t('last_name'), field: 'lastName',  editable:'never', 
        render: rowData =><div style={{color:!rowData.approved&&"red"}}> 
              {rowData.approved?
                <Link href={`/user/${rowData.id}?redirected=third-party-dispatch`}>{rowData.lastName}</Link>
                  :
                  rowData.lastName
              }
          </div>,
      
         cellStyle:{ paddingLeft:isRTL ==='rtl'? 30 : 15,width:150,minWidth:150}
        },
        { title:"Country",  field: 'country', editable: 'never', 
          render: rowData =>
            <div>
                {rowData?.selectedcountry?.label}
            </div>,
           cellStyle:{ textAlign:'center',width:180,minWidth:180},
          headerStyle:{textAlign:'center'},
         },
       { title:"Approved By Dispatcher",  field: 'isBrokerActive', type:'boolean', editable: 'never', 
           cellStyle:{ textAlign: 'center'},
           headerStyle:{textAlign:'center'},
        },
       { title: isShipper()?"Approved By Me":"Approved By Me",  field: 'aprovedByBroker', type:'boolean',initialEditValue: true,
            headerStyle:{textAlign:'center',width:180,minWidth:180},
            cellStyle:{ textAlign:'center',width:180,minWidth:180} 
        },
        
        { title:"View on Map ",  field: 'viewOnMap', editable: 'never', 
          render: rowData =>
            <div>{rowData?.isBrokerActive&&rowData?.aprovedByBroker&&rowData.approved?
              <Link style={{textDecoration:"none"}} href={`/fleetmap?carrier=${rowData.id}&&redirected=dispatch-agencies`}>
                <Button style={{backgroundColor:"#01aac1",color:"white"}}>View</Button>
              </Link>
             : <Button disabled>View</Button>
             }
                
            </div>,
           cellStyle:{ textAlign:'center',width:180,minWidth:180},
          headerStyle:{textAlign:'center'},
         }
       
 
  ])
   }
   else{
    setColumns([
      { title: t('first_name'), field: 'firstName', editable:'onAdd', 
      render: rowData =><div style={{color:!rowData.approved&&"red"}}> 
            {rowData.approved&&rowData.id?
              <Link href={`/user/${rowData.id}?redirected=third-party-dispatch`}>{rowData.firstName}</Link>
                :
                rowData?.firstName
              }
            {!rowData.id&&<div>{rowData.inviteName} (Invited)</div>}
        </div>,
        editComponent: (props) => (
          <button onClick={() => openSearchModal(props.rowData)}>
              {newData?.firstName?
                 <>  
                    {newData?.firstName}
                 </>
                 :
                 newData?.inviteName|| "Dispatch Agencies"
              }
          </button>
        ),
      cellStyle:{ paddingLeft:isRTL ==='rtl'? 50 : 15,width:150,minWidth:150},
      },
      { title: t('last_name'), field: 'lastName',  editable:'never', 
        render: rowData =><div style={{color:!rowData.approved&&"red"}}> 
              {rowData.approved?
                <Link href={`/user/${rowData.id}?redirected=third-party-dispatch`}>{rowData.lastName}</Link>
                  :
                  rowData.lastName
              }
          </div>,
      
         cellStyle:{ paddingLeft:isRTL ==='rtl'? 30 : 15,width:150,minWidth:150}
        },
        
      { title:"Country",  field: 'country', editable: 'never', 
          render: rowData =>
            <div>
                {rowData?.selectedcountry?.label}
            </div>,
           cellStyle:{ textAlign:'center',width:180,minWidth:180},
          headerStyle:{textAlign:'center'},
         },
      { title:"Approved By Me",  field: 'aprovedByCarrier', type:'boolean',initialEditValue: true,
           cellStyle:{ textAlign: 'center'},
           headerStyle:{textAlign:'center'},
        },
       { title: "Approved By Dispatcher",  field: 'isDispatcherActive', type:'boolean',editable: 'never',
              headerStyle:{textAlign:'center',width:180,minWidth:180},
              cellStyle:{ textAlign:'center',width:180,minWidth:180} 
          },
        
        
 
  ])
   }
},[auth.info,location.pathname,allUsers,newData])
/**
 *  { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",
          headerStyle:{textAlign:'center'},
          cellStyle:{ paddingLeft:isRTL ==='rtl'? 30 : 15,width:180,minWidth:180} 
      },
 */

  const sortCarriersBasedOnSharedCompanies = (AllData) => {
        return AllData.sort((a, b) => {
          let aNumberOfCompanies = 0;
          let bNumberOfCompanies = 0;
      
          if (a.SharedBrokers && a.SharedBrokers.length > 0) {
            aNumberOfCompanies += a.SharedBrokers.length;
          }
          if (a.SharedDispatcher && a.SharedDispatcher.length > 0) {
            aNumberOfCompanies += a.SharedDispatcher.length;
          }
          if (a.SharedCarriers && a.SharedCarriers.length > 0) {
            aNumberOfCompanies += a.SharedCarriers.length;
          }
      
          if (b.SharedBrokers && b.SharedBrokers.length > 0) {
            bNumberOfCompanies += b.SharedBrokers.length;
          }
          if (b.SharedDispatcher && b.SharedDispatcher.length > 0) {
            bNumberOfCompanies += b.SharedDispatcher.length;
          }
          if (b.SharedCarriers && b.SharedCarriers.length > 0) {
            bNumberOfCompanies += b.SharedCarriers.length;
          }
      
          return bNumberOfCompanies - aNumberOfCompanies;
        });
      };

  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  }
  
  const [filteredData,setFilteredData]=useState([])
useEffect(()=>{
  if(AllDispatcherData.length>0){
    let filtering=AllDispatcherData.filter(e=>{
      
      return e?.firstName?.toString().includes(searchValue.trim())||e?.lastName?.toLowerCase().includes(searchValue.trim().toLowerCase())
    })
    setFilteredData(sortCarriersBasedOnSharedCompanies(filtering))
  }else{
    setFilteredData([])
  }

},[searchValue,AllDispatcherData]);

  const [openInvitation,setOpenInvitation]=useState(false)
  const [emailToBeInvite,setEmailToBeInvite]=useState("")
  const [nameToBeInvite,setNameToBeInvite]=useState("")
  const [emailError,setEmailError]=useState("")
  const OpenInviteViaEmail=()=>{
    setOpenInvitation(true)
    setShowSearchModel(false);
  }
  const closeInviteViaEmail=()=>{
    setOpenInvitation(false)
    setShowSearchModel(false);
  }
  
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  const AddDispatcherData=(data)=>{
    setNewData(data)
    setShowSearchModel(false);
  }
  
  const InviteViaEmail=()=>{
    if(isValidEmail(emailToBeInvite)){
      if(nameToBeInvite&&nameToBeInvite.length>0){
        setNewData({email:emailToBeInvite,type:"invitation",inviteName:nameToBeInvite})
        setOpenInvitation(false)
        setShowSearchModel(false);
       }
      else{
          setEmailError("Company Name is Required")
          setTimeout(()=>{
            setEmailError("")
          },5000)
      }
      setShowSearchModel(false);
    }
    else{
      setEmailError("Invalid email")
      setTimeout(()=>{
        setEmailError("")
      },5000)
    }
  }
  
  const handleSearchModal = (e) => {
    setShowSearchModel(false);
  };
  const handlePress = (e) => {
    if(e.key === 'Enter'){
      InviteViaEmail()
    }
  }


return (
    !loaded.current? <CircularLoading/>:
    <>
        <MaterialTable
          title={auth.info?.profile?.usertype==='fleetadmin'?'Hire Dispatch Agency':"Manage Dispatch Agencies"}
          columns={columns}
          style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
          data={data}
          options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
          }}
          localization={{body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
              (t('blank_message'))
          ),
          editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
              }, 
            },
            toolbar: {
              searchPlaceholder: (t('search')),
              exportTitle: (t('export')),
            },
            header: {
              actions: (t('actions')) 
          },
          pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
          },
          }}
          editable={{
            onRowAdd: newDatas =>
            new Promise((resolve,reject) => {
              if(!isValidEmail(newData.email)){
                      setCommonAlert({open:true,msg:"Invalid Email"})
                      if(isShipper()){
                        dispatch(DispatcherVsAgentFetch({shipperId:auth.info.uid}))
                       }
                      else  {
                        dispatch(DispatcherVsAgentFetch({carrerId:auth.info.uid}))
                      }
                      resolve();
                }
              else{
                setTimeout(() => {
                  newDatas['createdAt'] = new Date().toISOString();
                  if(isShipper()){
                    newDatas['BrokerId']=auth.info.uid
                    newDatas['agent'] = "shipper"
                    newDatas['email'] = newData.email
                    newDatas['inviteName'] = newData.inviteName
                    dispatch(DispatcherVsAgentAdd(newDatas));

                   }
                   else{
                     if(auth.info.profile.usertype==="dispatcher"&&!auth.info.profile.independent) newDatas['carrierId'] =auth.info.profile.carrierId
                     else if(auth.info.profile.usertype==="fleetadmin") newDatas['carrierId'] = auth.info.uid;
                     newDatas['agent']='carrier'
                     newDatas['email'] = newData.email
                     newDatas['inviteName'] = newData.inviteName
                     dispatch(DispatcherVsAgentAdd(newDatas));
                    }
                    
                    setTimeout(()=>{
                      if(isShipper()){
                        dispatch(DispatcherVsAgentFetch({shipperId:auth.info.uid}))
                      }
                      else {
                        dispatch(DispatcherVsAgentFetch({carrerId:auth.info.uid}))
                      }
                      
                                       
                      resolve();

                    },1000)
                }, 600);
              }
            }),

            onRowUpdate: (newDatas) =>
              new Promise(resolve => {
                setTimeout(() => {
                  
                  if(isShipper()){
                    newDatas['BrokerId']= auth.info.uid
                    newDatas['agent']= 'shipper'
                    dispatch( DispatcherVsAgentUpdate(newDatas))
                    dispatch(DispatcherVsAgentFetch({shipperId:auth.info.uid}))
                  }
                  else{
                    if(auth.info.profile.usertype==="dispatcher") newDatas['carrierId'] =auth.info.profile.carrierId
                    else  newDatas['carrierId'] = auth.info.uid;
                    newDatas['agent']= 'carrier'
                    dispatch( DispatcherVsAgentUpdate(newDatas))
                    dispatch(DispatcherVsAgentFetch({carrerId:auth.info.uid}))
                    
                  }
             
                  resolve();
                }, 600);
              }),

            onRowDelete: oldData =>
              settings.AllowCriticalEditsAdmin?
              new Promise(resolve => {
                setTimeout(() => {
                  if(isShipper()){
                    oldData['BrokerId']= auth.info.uid;
                    oldData['agent']='shipper'
                    dispatch( DispatcherVsAgentDelete(oldData))
                    dispatch(DispatcherVsAgentFetch({shipperId:auth.info.uid}))
                   

                  }
                  else {
                      if(auth.info.profile.usertype==="dispatcher") oldData['carrierId'] =auth.info.profile.carrierId
                      else  oldData['carrierId'] = auth.info.uid;
                      oldData['agent']='carrier'
                      dispatch( DispatcherVsAgentDelete(oldData))
                      dispatch(DispatcherVsAgentFetch({carrerId:auth.info.uid}))

                    }
               
                    resolve();
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              })
              , 
          }}
        />

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openInvitation}
        onClose={closeInviteViaEmail}
        className={classes.modal}
        container={() => rootRef.current}
      >
           <Grid
            container
            spacing={1}
            className={classes.paper}
              >
            <Grid item xs={12} style={{textAlign:"center"}}>
                 <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"15px",}}>
                      <div>
                          <TextField 
                              placeholder='Company Name'
                              onChange={(e)=>setNameToBeInvite(e.target.value)}
                              value={nameToBeInvite} 
                              onKeyDown={handlePress}
                              style={{}}
                              />
                      </div>
                      <div>
                        <TextField 
                          placeholder='Email'
                          onChange={(e)=>setEmailToBeInvite(e.target.value)}
                          value={emailToBeInvite} 
                          onKeyDown={handlePress}
                          />

                      </div>
                       <div>
                       <Tooltip title="Invite">
                          <Button style={{color:"red"}} onClick={InviteViaEmail}>Invite</Button>
                       </Tooltip>
                    </div>
                  </div>
                  {emailError&&<div style={{color:"red"}}>{emailError}</div>}
                  
            </Grid>
          </Grid>
         
      </Modal>
         
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={showSearchModel}
        onClose={handleSearchModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
        >
          <Grid item xs={12} style={{textAlign:"center"}}>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      <TextField 
                        value={searchValue} 
                        placeholder='Search...'
                        onChange={(e)=>setSearchValue(e.target.value)}
                        style={{width:"90%"}}
                        label="Search For Active Dispatch Agency" 
                        variant="standard"
                        />
                  </div>
          </Grid>

          <Grid item xs={12} style={{height:"60vh",overflow:"auto",marginTop:"15px"}}>
              {filteredData.map(e=>{
                 return <div onClick={()=>AddDispatcherData(e)} style={{display:"flex",justifyContent:"flex-start",gap:"10px",marginBottom:"20px",cursor:"pointer"}} className={classes.carriersStyle}>
                           <div><HdrWeakIcon style={{fontSize:"15px",color:"red"}}/> </div>
                           <div>
                              <div>{e.firstName} {e.lastName}</div>
                              <div style={{opacity:0.5,fontSize:"12px"}}>{e?.selectedcountry?.label}</div>
                              <div><Link href={`/user/${e.id}?redirected=third-party-dispatch`}>See more</Link></div>
                           </div>
                   
                   </div>
              })}
              {filteredData.length===0&&<div style={{fontSize:"20px",opacity:0.5,display:"flex",justifyContent:"center",alignItems:"center",height:'100%'}}>No Company is found</div>}
          </Grid>

          <div style={{ display:"flex",justifyContent:"space-between",alignItems:"center",gap:"30px"}}>
               <div>
                  <Button
                    onClick={handleSearchModal}
                    variant="contained"
                    color="dangerColor">
                    Cancel
                  </Button>
               </div>
                <div>
                    <Button style={{background:"red",color:"white"}} onClick={OpenInviteViaEmail}>Invite New</Button>
                </div>
          </div>
          
        
          
        </Grid>
      </Modal>


     <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </>
  );
}
