import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import AdjustIcon from '@mui/icons-material/Adjust';
import Slider from 'react-slick';

export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists,
    fetchUsersOnce
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);

  useEffect(()=>{
    dispatch(fetchUsersOnce());
},[dispatch,fetchUsersOnce]);
  useEffect(()=>{
    if(staticusers){
      setData(staticusers.filter(user => user.usertype ==='shipper'));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers]);


  function ExtractNamesOfCarriers(carriers){
    let carrierDatas=[] 
    for( const carrier of carriers){
      const filterUser=staticusers.filter(user => user.id ===carrier.id)
      if(filterUser.length>0){
        let carrierConditions={
           name:`${filterUser[0].firstName} ${filterUser[0].lastName}`,
           aprovedByBroker:carrier.aprovedByBroker,
           approved:carrier.approved,
        }
        carrierDatas.push(carrierConditions)
      }
    }
    return carrierDatas
  }
  const SharedCarriersSlider=({slides})=>{
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    const scrollbarStyles = {
      width: '300px',
      overflowX: 'hidden',
      padding: '3px 5px 0 5px',
      textAlign: 'center',
      overflowY: 'auto',
       scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '::webkitScrollbar': {
        display: 'none',
        width:'0px'
      },
    };
    return(
      <Slider {...settings} style={scrollbarStyles} >
          {slides.map((slide,index)=>{
              return (
                <div key={index} style={{height:"400px",background:"red",width:"100%",display:"flex",justifyContent:"flex-start",alignItems:"center",flexDirection:"column",marginTop:"10px"}}>
                  <div style={{fontWeight:"600", fontSize: '14px'}}>{slide.name}</div>
                  <div style={{display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'center', margin: 10, fontSize: '13px'}}>
                      <div>
                        Broker
                        <div>
                          <AdjustIcon style={{color:slide.aprovedByBroker?"green":"red",fontSize:"16px"}}/>
                        </div>
                      </div>
                      <div>
                        Carrier
                        <div><AdjustIcon style={{color:slide.approved?"green":"red",fontSize:"16px"}}/></div>
                      </div>
                  </div>
                  <div style={{marginTop:"5px",fontWeight:"800",color:"#000"}}>{index+1} of {slides.length}</div>
                </div>
                )
          })}
      </Slider>
    )
  }
  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},
    { title: t('first_name'), field: 'firstName',
     render: rowData =><div style={{color:!rowData.approved&&"red"}}> {rowData.firstName}</div>,
     cellStyle:{textAlign:isRTL=== 'rtl' ?'center':'left'}},
    { title: t('last_name'), field: 'lastName',
      render: rowData =><div style={{color:!rowData.approved&&"red"}}> {rowData.lastName}</div>,
      cellStyle:{textAlign:isRTL=== 'rtl' ?'center':'left'}},
    { title: t('mobile'), field: 'mobile', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'center'}},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'},headerStyle:{textAlign:'center'}},
    { title: t('profile_image'),  field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},
    { title: t('account_approve'),  field: 'approved', type:'boolean',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},
    { title: t('bankName'),  field: 'bankName',  hidden: settings.bank_fields===false? true: false,initialEditValue: '',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'center'} },
    { title: t('bankCode'),  field: 'bankCode', hidden: settings.bank_fields===false? true: false, initialEditValue: '',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'center'} },
    { title: t('bankAccount'),  field: 'bankAccount',  hidden: settings.bank_fields===false? true: false,initialEditValue: '',cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'center'} },
    { title: "Shared Carriers",  field: 'SharedCarriers',editable:'never',
      render:rowData=><div>
           {rowData?.SharedCarriers?.length>0?
              <div>
                  {ExtractNamesOfCarriers(rowData.SharedCarriers).length>0?
                    <SharedCarriersSlider slides={ExtractNamesOfCarriers(rowData?.SharedCarriers)}/>
                     :
                    <div>Not Added Yet</div>

                   }
              </div>
              :
              <div>Not Added Yet</div>
            }
      </div>,
      cellStyle:{textAlign:'center',width:300,minWidth:300, backgroundColor: '#01aac1', color: 'white'} ,
      headerStyle:{textAlign:'center',width:300,minWidth:300,backgroundColor: '#01aac1',color: 'white', fontWeight: '900', fontSize: '16px'} ,
    },
  
  ];


  return (
    !loaded.current? <CircularLoading/>:
    <MaterialTable
      title={t('shippers')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
      data={data}
      options={{
        exportButton: settings.AllowCriticalEditsAdmin,
        sorting: true,
      }}
      localization={{ body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
        editRow: { 
          deleteText: (t('delete_message')),
          cancelTooltip: (t('cancel')),
          saveTooltip: (t('save')) 
          }, 
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        labelRowsSelect: (t('rows')),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve,reject) => {
            setTimeout(() => {
              checkUserExists(newData).then((res) => {
                if (res.users && res.users.length > 0) {
                  alert(t('user_exists'));
                  reject();
                }
                else if(res.error){
                  alert(t('email_or_mobile_issue'));
                  reject();
                }
                else{
                  newData['regType'] = 'admin';
                  newData['usertype'] = 'shipper';
                  newData['createdAt'] = new Date().toISOString();
                  const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                  const reference = [...Array(5)].map(_ => c[~~(Math.random()*c.length)]).join('');
                  dispatch(addUser(newData));
                  dispatch(fetchUsersOnce());
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
              dispatch(fetchUsersOnce());
            }, 600);
          }),
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
              dispatch(fetchUsersOnce());
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
          , 
      }}
    />
  );
}
