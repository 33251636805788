import React, { useState, useEffect, useRef, useContext } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSelector,useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from 'common';
import CustomInput from './CustomInput/CustomInput';

export default function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  const { t, i18n  } = useTranslation();
  const { api } = useContext(FirebaseContext);
  const {
    fetchCancelReasons
  } = api;
  const dispatch = useDispatch();

  const isRTL = i18n.dir();
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);
  const options = useSelector(state => state.cancelreasondata.simple);

  useEffect(()=>{
    dispatch(fetchCancelReasons())
  },[fetchCancelReasons,dispatch])

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      
      style={{direction:isRTL==='rtl'? 'rtl':'ltr'}}
    >
      <DialogTitle id="confirmation-dialog-title" style={{textAlign:isRTL==='rtl'? 'right':'left'}}>{t('select_reason')}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
          ))}
        </RadioGroup>
        <CustomInput
          labelText="Enter reason manually"
          onChange={handleChange}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text'
          }}
          value={value}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleOk} color="primary">
          {t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};