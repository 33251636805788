export const colors = {
    LandingPage_Front : '#ffffff',
    LandingPage_Background: '#ffffff',
    LandingPage_Description: '#373737',
    Button_Primary: '#01aac1',
    Button_Secondary: '#01d7f4', 
    Header_Background: "#01aac1",
    Header_Content: '#000000',
    AppMenu_Header: '#474747',
    ResponsiveDrawer_Header: '#444444',
}