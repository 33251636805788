import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import AdjustIcon from '@mui/icons-material/Adjust';
import { Button, CircularProgress, Grid, Modal, Typography } from '@material-ui/core';
import AlertDialog from 'components/AlertDialog';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius:15,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));
export default function Users() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editRouteType,
    fetchRouteTypes
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const routeTypes = useSelector((state) => state.routetypes.routes);

  const dispatch = useDispatch();
  const loaded = useRef(false);

  useEffect(() => {
    dispatch(fetchRouteTypes());
  }, [dispatch, fetchRouteTypes]);


  const rootRef = useRef(null);
  const classes = useStyles();
  const [open,setOpen] = useState(false);
  const [rowIndex,setRowIndex] = useState();
  const [modalType,setModalType] = useState();

  
  const handleClose = () => {
    setOpen(false);
  }

  const [selectedImage, setSelectedImage] = useState(null); 
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  }

  
  const [userData, setUserData] = useState();
  const [profileModal, setProfileModal] =  useState(false);
  const [imageData, setImageData] =  useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [loading, setLoading] = useState(false);

  const columns = [
    { title: t('name'), field: 'name', cellStyle:isRTL ==='rtl'? {paddingRight: 180 , textAlign: 'right' }:{ paddingLeft: 180}, headerStyle:isRTL==='rtl'?{paddingRight: 180}:{ paddingLeft: 180}},
    { title: t('image'),  field: 'image',cellStyle:{ textAlign: 'center'},render: rowData => rowData.image? <button onClick={()=>{onClick(rowData)}}><img alt='CarImage' src={rowData.image} style={{width: 50}}/></button>:null},
    { title: t('extra_info'), field: 'extra_info' , cellStyle:{ textAlign:isRTL ==='rtl'? 'right' : 'left'}}
  ];
  
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleSetProfileModal = (e) =>{
    e.preventDefault();
    if(selectedImage){
      setLoading(true);
      let finalData = userData;
      finalData.image = selectedImage;
     dispatch(editRouteType(finalData, "UpdateImage"));
      setProfileModal(false); 
      setTimeout(()=>{
        setSelectedImage(null);
        setLoading(false); 
      },10000);
    }
    else{
      setCommonAlert({ open: true, msg: t('choose_image_first') })
    }
  }

  const onClick = (rowData) => {
    setImageData(rowData.image);
    setProfileModal(true);
    setUserData(rowData);
  };


  return (
    !routeTypes? <CircularLoading/>:
    <>
        <MaterialTable
        title={t('shippers')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
        data={routeTypes}
        options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
        }}
        localization={{ body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
            (t('blank_message'))
        ),
            editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
            }, 
            },
            toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
            },
            header: {
            actions: (t('actions')) 
        },
        pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
        },
        }}
        editable={{
            onRowAdd: newData =>
            settings.AllowCriticalEditsAdmin?
              new Promise(resolve => {
                setTimeout(() => {
                  let name=newData?.name
                  if(name&&name?.trim()){
                    newData['name']=name.trim()
                    newData['createdAt'] = new Date().toISOString();
                    dispatch(editRouteType(newData,"Add"));
                    resolve();

                  }else{
                    setCommonAlert({ open: true, msg: 'please use valid name' })
                    resolve();
                  }
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              settings.AllowCriticalEditsAdmin?
              new Promise(resolve => {
                setTimeout(() => {
                  let name=newData?.name
                  if(name&&name?.trim()){
                    newData['name']=name.trim()
                      resolve();
                      dispatch(editRouteType(newData,"Update"));
                    }else{
                      setCommonAlert({ open: true, msg: 'please use valid name' })
                      resolve();
                    }
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              }),
            onRowDelete: oldData =>
              settings.AllowCriticalEditsAdmin?
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  dispatch(editRouteType(oldData,"Delete"));
                }, 600);
              })
              :
              new Promise(resolve => {
                setTimeout(() => {
                  resolve();
                  alert(t('demo_mode'));
                }, 600);
              })
          }}
        />
        
        <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
      <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
                {t('car_image')}

              <input
                  type="file"
                  style={{marginLeft:10}}
                  name= {t('image')}
                  onChange={(event) => {
                      setSelectedImage(event.target.files[0]);
                  }}
              />
            </Typography>
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage  && !loading ? 
                    <Tooltip title={t('cancel')}>
                      <CancelIcon onClick={()=>setSelectedImage(null)} style={{ fontSize: 30,backgroundColor:'red',borderRadius:50,color:"white" }}  />
                    </Tooltip>
                  : null }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage ? 
                   <img alt="not fount" width={"200px"} height={"200px"}  src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                   :
                    <img alt="not fount" width={"200px"} height={"200px"}  src={imageData} style={{marginTop:10}}/>
                  }
                <br />
                </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
          {loading ? 
            <Grid
              container
              spacing={0}
              alignItems="center"
              justify="center"
              style={{ minHeight: '5vh' }} >
              <CircularProgress/>
            </Grid>
            :
            <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button onClick={handleProfileModal} variant="contained" color="dangerColor">
              {t('cancel')}
            </Button>
            <Button onClick={handleSetProfileModal} variant="contained" color="secondaryButton" style={{marginLeft:10}}>
              {t('save')}
            </Button>
            </Grid>
          }
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
   
    
    </>
  );
}
