import React, { useContext, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import IconButton from '@material-ui/core/IconButton';
import HeroImg from '../assets/img/BG_HERO_DESIGN.png';
import styled from "styled-components";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box, CircularProgress, TextField } from "@mui/material";
import { Button, Typography } from "@material-ui/core";
import { FirebaseContext } from "common";
import {  useDispatch } from "react-redux";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function ContactUs(props) {
    const { api } = useContext(FirebaseContext);
    const { MinutesPassed } = api;
    const {
        sendEmailFromContactUs,
      } = api;

    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir();
    const classes = useStyles();
    const { ...rest } = props;

    const backgroundColor = 'transparent';
    const footerColor = 'white';
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = (e) => {
        e.preventDefault();
        setError('')
        if (!email || !message || !name) {
          setError("Please fill all fields");
          return;
        }
        setLoading(true);
        dispatch(sendEmailFromContactUs({ name, email, message }))
          .then((res) => {
            setLoading(false);
            if(res.error){
                setError(res.error)
                setTimeout(()=>{
                    setError('')
                   },2000)
        
            }
            else{
                setEmail("")
                setName("")
                setMessage("")
                setSuccess("Email sent successfully")
                setTimeout(()=>{
                    setSuccess('')
                },2000)
            }
          })
          .catch((error) => {
            setLoading(false);
            setError("Error sending email. Please try again later.");
            setTimeout(()=>{
                setError('')
               },2000)
    
          });

         
      };


  return (
        <div>
            <Header
                color="landing"
                routes={dashboardRoutes}
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />

            <Div12 style={{
                    backgroundImage: `url(${HeroImg})`,
                     backgroundPosition: 'center',
                     backgroundSize: 'cover',
                    //  backgroundRepeat: 'repeat'
                }}>
                <CustomBox>
                    <h2 style={{textAlign:'center',margin: "0px 0 19px 0",color: "white",fontWeight: "800"}}>
                        Contact Us
                    </h2>
                    <CustomForm onSubmit={sendEmail}>
                        <div>
                            <TextField style={{width:"100%"}} value={name} onChange={(e)=>setName(e.target.value)} placeholder="Enter your name" />
                        </div>
                        <div>
                            <TextField  type="email" style={{width:"100%"}} value={email} onChange={(e)=>setEmail(e.target.value)}  placeholder="Enter your Email" />
                        </div>
                        <div>
                            <TextField   style={{width:"100%"}} rows={10}  multiline
                            value={message} onChange={(e)=>setMessage(e.target.value)} 
                            placeholder="Enter your message here" />
                        </div>
                        <div style={{ width: "100%",textAlign:"center" }}>
                            {loading ? (
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <CircularProgress style={{color:"black"}} size={25}/>
                            </div>
                            ) : (
                            <Button style={{background:"#08e2ff",width:'100%'}} type="submit">Send</Button>
                            )}
                        </div>
                        {error && (
                            <p
                            style={{
                                fontSize: 15,
                                color: "red",
                                paddingLeft: "5px",
                                fontFamily: "nyala",
                                textAlign:"center"
                            }}
                            >
                            {error}
                            </p>
                        )}
                        {success && (
                            <p
                            style={{
                                fontSize: 15,
                                color: "green",
                                paddingLeft: "5px",
                                fontFamily: "nyala",
                                textAlign:"center"
                            }}
                            >
                            {success}
                            </p>
                        )}


                    </CustomForm>
                </CustomBox>

                <div style={{color:"white"}}>
                    <h2 style={{textAlign:'center',margin: "0px 0 0px 0",color: "white",fontWeight: "800"}}>
                        Contact Info
                    </h2>

                    <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                            <p className={classes.description} style={{ fontWeight:"500",color:"white",textAlign: isRTL === 'rtl' ? 'right' : 'left', fontSize: 20, fontWeight: 'bold',marginTop:30 }}>
                            Freight Canvas</p>
                            <p className={classes.description} style={{ fontWeight:"500",color:"white",textAlign: isRTL === 'rtl' ? 'right' : 'left',fontSize: 16 }}>
                            <LocationOnIcon style={{color:"red",fontSize:"19px"}}/> Minneapolis, MN
                            </p>
                            
                                <p><span className={classes.description}  style={{color:"white"}}>Email: </span>
                                    <a href={"mailto:" + "info@freightcanvas.com"} className={classes.description}  style={{color:"white"}} >info@freightcanvas.com</a>
                                </p>
                            
                                <p className={classes.description}  style={{color:"white"}}>Phone: +1 952-855-2202</p>
                                

 

                    </Box>
                </div>
            </Div12>

            <Footer backgroundColor={backgroundColor} color={footerColor} />
        </div>
    );
}

const Div12 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 100%;
  height:100%;
  min-height:80vh;
  gap:90px;
  margin:60px 0px 0 0px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-direction:column;
  }
`;

const CustomBox=styled.div`
   width:500px;
   @media (max-width: 600px) {
     width: 80%;
  }
`
const CustomForm=styled.form`
   background:rgba(255,255,255,0.8);
   padding:20px;
   border-radius:10px;
   display:flex;
   gap:20px;
   flex-direction:column;
`