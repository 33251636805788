/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { Info, AccountBox } from "@material-ui/icons";
import ViewListIcon from '@material-ui/icons/Home';
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Select, MenuItem } from '@material-ui/core';
import moment from 'moment/min/moment-with-locales';
import EmailIcon from '@material-ui/icons/Email';
const useStyles = makeStyles(styles);
import "./header.css"
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { FirebaseContext } from 'common';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import styled from "styled-components";
import InfoIcon from '@mui/icons-material/Info';
import FactCheckIcon from '@mui/icons-material/FactCheck';
const Div = styled.div`
  font-family: Montserrat, sans-serif;
  margin-top: 10px;
  border-radius: 9px;
  background-color: #337ab7;
  // justify-content: center;
  padding: 5px 50px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 5px 20px;
  }
`;


export default function HeaderLinks(props) {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const languagedata = useSelector(state => state.languagedata);
  const { i18n, t } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);
  let history = useHistory();
  const { api } = useContext(FirebaseContext);
  const {
    fetchUsersOnce
  } = api;
  const dispatch = useDispatch();
  const staticusers = useSelector((state) => state.usersdata.staticusers);

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  const [langSelection, setLangSelection] = useState();
  const [multiLanguage, setMultiLanguage] = useState();

  const handleLanguageSelect = (event) => {
    i18n.addResourceBundle(multiLanguage[event.target.value].langLocale, 'translations', multiLanguage[event.target.value].keyValuePairs);
    i18n.changeLanguage(multiLanguage[event.target.value].langLocale);
    setLangSelection(event.target.value);
    moment.locale(multiLanguage[event.target.value].dateLocale);
  };

  useEffect(() => {
    if (languagedata.langlist) {
      for (const key of Object.keys(languagedata.langlist)) {
        if (languagedata.langlist[key].langLocale === i18n.language) {
          setLangSelection(key);
        }
      }
      setMultiLanguage(languagedata.langlist);
    }

  }, [languagedata.langlist]);

  const location = useLocation()

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.info]);

  function isAgentFleetAdminOrDipatcherThenHasNoDriver() {
    if (!staticusers) return false
    let isFleetAdmin = auth.info && auth.info.profile && auth.info.profile.usertype === 'fleetadmin';
    let isDispatcher = auth.info && auth.info.profile && auth.info.profile.usertype === 'dispatcher' && !auth.info?.profile?.independent && auth.info.carrierId;

    if (!isFleetAdmin && !isDispatcher) return false
    let driverExist = staticusers.filter(e => {
      return e.approved && e.emailVerified && e.usertype === 'driver' && (e.fleetadmin === auth.info.uid || e.fleetadmin === auth.info.profile?.carrierId)
    }).length === 0
    return driverExist
  }

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser = staticusers.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}


  const isSpecialAdmin=()=>{
     
    return auth.info?.profile?.specialAdmin || giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)?.specialAdmin
  }

  return (
    <List className={`${classes.list} headerButtons`}>
        {loggedIn &&<ListItem className={classes.listItem}> 
           
            <Button
              color="transparent"
              className={classes.navLink}
              onClick={(e) => { e.preventDefault(); history.push( auth.info?.profile?.usertype==='driver' ? '/bookings' :isAgentFleetAdminOrDipatcherThenHasNoDriver() ? "/drivers" : '/fleetmap') }}
              style={{ color: location.pathname === '/login' ? "#01aac1" : '' }}
            >
                <DashboardIcon className={classes.icons} /> Dashboard
            </Button>
        </ListItem>
            }

        {loggedIn && !isSpecialAdmin() && 
         <ListItem className={classes.listItem}>
            <Button
              color="transparent"
              className={classes.navLink}
              onClick={(e) => { e.preventDefault(); history.push(loggedIn ? '/truckboard' : '/') }}
              style={{ color: loggedIn ? location.pathname === '/truckboard' && "#01aac1" : location.pathname === '/' && "#01aac1" }}
            >
            
                <><DataThresholdingIcon className={classes.icons} />Capacity Board</>
                

            </Button>

          </ListItem>
          }
      
      {loggedIn&& !isSpecialAdmin() &&<ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => { e.preventDefault(); history.push(loggedIn ? '/loadboard' : '/') }}
          style={{ color: loggedIn ? location.pathname === '/loadboard' && "#01aac1" : location.pathname === '/' && "#01aac1" }}
        >
          
            <><FactCheckIcon className={classes.icons} />Load Board</>

        </Button>

      </ListItem>
          }
      
      {/* <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => { e.preventDefault(); history.push('/about') }}
          style={{ color: location.pathname === '/about' ? "#01aac1" : '' }}
        >
          <Info className={classes.icons} />About
        </Button>
      </ListItem> */}
   
      

      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => { e.preventDefault(); history.push('/about') }}
          style={{ color: location.pathname === '/about' ? "#01aac1" : '' }}
        >
          <InfoIcon className={classes.icons} /> About
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={(e) => { e.preventDefault(); history.push('/contact-us') }}
          style={{ color: location.pathname === '/contact-us' ? "#01aac1" : '' }}
        >
          <EmailIcon className={classes.icons} />Contact Us
        </Button>
      </ListItem>

      <ListItem className={classes.listItem}> 
            {!loggedIn &&
            <Div
            onClick={(e) => { e.preventDefault(); history.push('/login') }}
              style={{ fontWeight:700,backgroundColor:location.pathname === '/login'?'white' : '#337ab7', color: location.pathname === '/login' ? "#01aac1" : '',cursor:"pointer" }}>
              Login
            </Div>}
        </ListItem>

      {/* <ListItem className={classes.listItem}>
        {multiLanguage?
        <Select
          id="booking-type-native1"
          className={classes.input}
          value={langSelection}
          onChange={handleLanguageSelect}
          style={{backgroundColor:'#fff', marginTop:'8px',paddingLeft:'5px',borderRadius:'5px'}}
        >
          {
            Object.keys(multiLanguage).map((key)=> <MenuItem key={key} value={key}>
            {multiLanguage[key].langName}
            </MenuItem>)
          }
        </Select>
        :null}
      </ListItem> */}
    </List>
  );
}
