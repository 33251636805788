
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import HeroImg from '../assets/img/BG_HERO_DESIGN.png';
import { useSelector } from "react-redux";

import styled from "styled-components";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const dashboardRoutes = [];

export default function LandingPage(props) {
  
  const { ...rest } = props;
  const auth = useSelector((state) => state.auth);
  const [loggedIn, setLoggedIn] = useState(false);

  const sectionRef = useRef(null);
  const history = useHistory()
  const scrollToSection = () => {
    // sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    history.push('/about')
  };

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [auth.info]);


  return (

    <Div style={{
      backgroundImage: `url(${HeroImg})`,
      //  backgroundPosition: 'center',
      //  backgroundSize: 'cover',
      //  backgroundRepeat: 'repeat'
    }}>
      <Header
        color="landing"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Div12 suppressContentEditableWarningtyle={{
        backgroundImage: `url(${HeroImg})`,
        //  backgroundPosition: 'center',
        //  backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <Div13>
          <Div14>
            <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 300, color: "white" }}>
              The{" "}
            </span>
            <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 300, color: 'rgba(1,170,193,1)' }}>
              collaboration
            </span>
            <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 300, color: "white" }}>
              {" "}platform transforming{" "}
            </span>
            <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 300, color: "rgba(1,170,193,1)" }}>
              {" "}over-the-road {" "}
            </span>
            <span style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 300, color: "white" }}>
            logistics.
            </span>
          </Div14>
          

          {/* <Div69>
            {loggedIn ?
              null
              :
              <Link style={{ color: "inherit", textDecoration: "none" }} href="/login" >
                <Button style={{ textTransform: "inherit", width: "fit-content", background: '#33d381', color: '#000', font: '14px Open Sans, sans-serif' }}>
                  Get Started
                </Button>
              </Link>
            }
            <Button onClick={scrollToSection} style={{ marginLeft: "10px", textTransform: "inherit", width: "fit-content", background: '#33d381', color: '#000', font: '14px Open Sans, sans-serif' }}>
              Learn More
            </Button>
          </Div69> */}

          <Div17>

            <Img2
              loading="lazy"
              src={require("../assets/img/welcome.png").default}
            />
          </Div17>
        </Div13>
      </Div12>

      <Div37 ref={sectionRef}>
        {/* <Img10
          loading="lazy"
          src={require("../assets/img/DIGITIZED.png").default}
        /> */}
        <Div38 >
          <Div39 >
             All in one platform {" "}
          </Div39>
          <Div40>
              {/* As a Freight Carrier or Freight Broker/ Forwarder, digitize your existing offline logistics network or create new connections with reliable partners. */}
              Manage your fleet, share capacities or loads, build relationship with those that are reliable, and utalize workflows that help all stake-holders be professional and reliable up until delivery.
          </Div40>
        </Div38>
      </Div37>
      <Div41 style={{
        // backgroundImage: `url(${HeroImg})`,
        //  backgroundPosition: 'center',
        //  backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <Div42>
          <Div44>
            <Img11
              loading="lazy"
              src={require("../assets/img/truck-network.png").default}
            />
          </Div44>
          {/* <Div43>It all starts with Carriers sharing their Fleet Map and Truck Board, powered by their dispatch team, F.C driver mobile app, ELD devices, or a combination of all.</Div43> */}
          <Div38>
            <Div39>
              Unique For Each Professional{" "}
              {/* <span style={{ color: "rgba(1,170,193,1)" }}></span> */}
            </Div39>
            <Div40>Each logistics professional will find tools and workflow that increases their reliability, efficiency, and professionalism.</Div40>
          </Div38>
        </Div42>
      </Div41>
      <Div45 style={{
        backgroundImage: `url(${HeroImg})`,
        //  backgroundPosition: 'center',
        //  backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <Div46>
          {/* <Div47>Brokers</Div47>
          <Div47>Freight Forwarders</Div47> */}
          {/* <Div49>Carriers</Div49>
          <Div49>Dispatchers</Div49> */}
          {/* <Div48>Brokers</Div48> */}
          {/* <Div49>Freight Forwarders</Div49> */}
        </Div46>
      </Div45>
      <Div50 style={{
        backgroundImage: `url(${HeroImg})`,
        //  backgroundPosition: 'center',
        //  backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <Div51>
          <Column7>
            <Img13
              src={require("../assets/img/carrier.png").default}
              style={{ width: "100%", objectFit: "contain" }}
              loading="lazy"
            />
          </Column7>
          <Column8>
            <Div52>
              <Div53>
                For Carriers
              </Div53>
              <Div54>
              Our platform integrates with existing Asset trackers/ELD devices to allow carriers to manage and dispatch their fleet, share the real-time location of trucks that have available capacity, and give reliable freight tracking that doesn't depend on drivers' mobile phones.
              </Div54>
            </Div52>
          </Column8>
        </Div51>
      </Div50>


      <Div50 style={{
        backgroundImage: `url(${HeroImg})`,
        //  backgroundPosition: 'center',
        //  backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <DivSpecial>
          <Column8>
            <Div52>
              <Div53>
                For Freight Brokers /
                <br />
                Forwarders{" "}
              </Div53>
              <Div54>
                  Build a reliable network of trusted partners to eliminate uncertainties with new carriers. Efficiently book available trucks and carrier identified capacity that match your load routes to save money. Focus on delivering exceptional service to your local or international customers by utilizing our reliable carrier communication and tracking methods.
              </Div54>
            </Div52>
          </Column8>
          <Column7>
            <Img13
              loading="lazy"
              src={require("../assets/img/broker_ff.png").default}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Column7>
        </DivSpecial>
      </Div50>


      {/* <Div50 style={{
        backgroundImage: `url(${HeroImg})`,
        //  backgroundPosition: 'center',
        //  backgroundSize: 'cover',
        //  backgroundRepeat: 'repeat'
      }}>
        <Div51>
          <Column7>
            <Img13
              loading="lazy"
              src={require("../assets/img/collab_icon.png").default}
              style={{ width: "100%", objectFit: "contain" }}

            />
          </Column7>
          <Column8>
            <Div52>
              <Div53>
                For 
                All
              </Div53>
              <Div54>
                  Consolidate your logistics workflows into Freight Canvas, simplifying operations. Foster long-term partnerships grounded in reliability and transparency to elevate service standards.
              </Div54>
            </Div52>
          </Column8>
        </Div51>
      </Div50> */}

   

      <Div65 >
        <Img16
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/325fba0e926242ddba95c41e4bdd7e7fa674f7ced267a4407cb28eb71ba63684?apiKey=88f42c79671748f8ba99fcb90941995a&"
        />
        
        {loggedIn ?
        <Div66>
          <Div67>Consolidate your over-the-road logistics workflows into Freight Canvas today.</Div67>
          <Div69>
            
              <Link style={{ color: "inherit", textDecoration: "none" }} href="/bookings" >
                <Button style={{ width: "fit-content", background: '#33d381', color: '#000', font: '14px Open Sans, sans-serif' }}>
                  Dashboard
                </Button>

              </Link>
            

          </Div69>
          </Div66>
          :
          
          <Div66>
          <Div67>Consolidate your over-the-road logistics workflows into Freight Canvas today.</Div67>
          <Div68>Try it out for free. No credit card required.</Div68>
          <Div69>
              
              <Link style={{ color: "inherit", textDecoration: "none" }} href="/login" >
                <Button style={{ width: "fit-content", background: '#33d381', color: '#000', font: '14px Open Sans, sans-serif' }}>
                  Sign Up
                </Button>
              </Link>
          

          </Div69>
          </Div66>
          }
        
      </Div65>

      <Footer />


    </Div>
  );
}


const Div = styled.div`
  background-color: #0a315c;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Div12 = styled.div`
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: flex;
  margin-top: 50px;
  width: 100%;
  max-width: 1393px;
  padding: 51px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;
const Div13 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1105px;
  padding-bottom: 80px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div14 = styled.div`
  color: #01aac1;
  text-align: center;
  margin-top: 40px;
  font: 600 54px Montserrat, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  };
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;
const Div15 = styled.div`
  color: #fff;
  text-align: center;
  margin-top: 40px;
  font: 400 20px Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 20px;
  };
  @media (max-width: 600px) {

  }
`;
const Div16 = styled.div`
  border-radius: 9px;
  background-color: #33d381;
  margin-top: 84px;
  width: 240px;
  max-width: 100%;
  align-items: center;
  color: #000;
  justify-content: center;
  padding: 13px 30px;
  font: 400 14px Open Sans, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;
const Div17 = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 505px;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 84px 0 -50px;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin: 40px 0 -100px;
    padding: 0 20px;
  }
`;

const Img2 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  padding: 10px;  // Adjust padding as needed
`;

const Div37 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  align-self: stretch;
  position: relative;
  min-height: 320px;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 36px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 0px;
    padding: 0 20px;
  }
`;

const Img10 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1; /* Ensure the image is behind the text */
`;

const Div38 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 120px;
  width: 100%;
  max-width: 988px;
  z-index: 2; /* Ensure the text is in front of the image */
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div39 = styled.div`
  color: white;
  font: 300 46px Poppins, sans-serif;
  font-weight: bolder;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const Div40 = styled.div`
  color: #d4d4d4;
  font: 400 26px Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 20px;
  }
`;

const Div41 = styled.div`
  align-items: center;
  display: flex;
  margin-top: 30px;
  width: 100%;
  max-width: 1393px;
  justify-content: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const Div42 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1105px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Div43 = styled.div`
  color: #fff;
  text-align: center;
  font: 300 46px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;

const Div44 = styled.div`
  display: flex; /* Corrected typo from 'disply' to 'display' */
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 550px;
  margin-top: 36px;
  margin-bottom: 36px;
  align-items: center;
  justify-content: center;
  padding: 39px 30px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 20px;
    padding: 0 10px;
  }
`;

const Img11 = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Img12 = styled.img`
  aspect-ratio: 0.91;
  object-fit: auto;
  object-position: center;
  width: 82px;
  fill: #fff;
  margin-top: 112px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const Div45 = styled.div`
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  width: 100%;
  font-size: 24px;
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  padding: 0 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    white-space: initial;
    padding: 0 20px;
  }
`;
const Div46 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1014px;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    white-space: initial;
  }
`;
const Div47 = styled.div`
  font-family: Open Sans, sans-serif;
  border-radius: 2px;
  border-color: rgba(51, 122, 183, 1);
  border-style: solid;
  border-width: 5px;
  align-items: center;
  justify-content: center;
  padding: 17px 60px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
const Div48 = styled.div`
  font-family: Open Sans, sans-serif;
  border-radius: 2px;
  background-color: #337ab7;
  align-items: center;
  justify-content: center;
  padding: 17px 60px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
const Div49 = styled.div`
  font-family: Open Sans, sans-serif;
  border-radius: 2px;
  background-color: #337ab7;
  align-items: center;
  justify-content: center;
  padding: 17px 60px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;
const Div50 = styled.div`
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 35px;
  width: 100%;
  max-width: 1375px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 70px;
  }
`;
const Div51 = styled.div`
  gap: 20px;
  margin:0 20px 0 20px;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-wrap:wrap;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

const DivSpecial = styled.div`
gap: 20px;
margin:0 20px 0 20px;
display: flex;
justify-content:center;
align-items: center;
flex-wrap:wrap;
@media (max-width: 991px) {
  flex-direction: column-reverse;
  align-items: center;
  gap: 30px;
}
`;

const Column7 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 450px;
  @media (max-width: 991px) {
    width: 400px;
  }
  @media (max-width: 500px) {
    width: 300px;
  }
`;

const Img13 = styled.img`
  aspect-ratio: 1.72;
  object-fit: auto;
  object-position: center;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Column8 = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
const Div52 = styled.div`
  justify-content: center;
  display: flex;
  padding-left: 24px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div53 = styled.div`
  color: #fff;
  font: 700 30px/30px Poppins, sans-serif;
  text-align:center;
  @media (max-width: 1200px) {
    max-width: 100%;
    font-size: 35px;
    line-height: 44px;
  };
  @media (max-width: 991px) {
    max-width: 100%;
    line-height: 44px;
    font-size:30px
  }
`;
const Div54 = styled.div`
  color: #d4d4d4;
  margin-top: 16px;
  font: 400 19px Open Sans, sans-serif;
  @media (max-width: 1200px) {
    max-width: 100%;
    font-size: 24px;
  };
  @media (max-width: 991px) {
    max-width: 100%;
    font-size:20px
  }
`;


const Div65 = styled.div`
  disply: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-blend-mode: luminosity;
  overflow: hidden;
  align-self: stretch;
  position: relative;
  display: flex;
  margin-top: 100px;
  width: 100%;
  font-weight: 400;
  padding: 36px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 110px;
    margin-bottom: 100px;
    padding: 0 20px;
  }
`;
const Img16 = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;
const Div66 = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 988px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div67 = styled.div`
  color: #fff;
  text-align: center;
  font: 300 40px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
`;
const Div68 = styled.div`
  color: #d4d4d4;
  text-align: center;
  margin-top: 24px;
  font: 24px Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const Div69 = styled.div`
  align-self: center;
  margin-top: 45px;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
