const parallaxStyle = {
  parallax: {
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "left left",
    backgroundSize: "100%",
    backgroundRepeat: "repeat-y",
    backgroundAttachment: "fixed",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    paddingBottom: 20,
    minHeight: "70vh",   
    "@media (max-width: 1200px)": {
      height: "auto",
      flexDirection: "column",
      justifyContent: "space-evenly",
      paddingTop: "10vh",
      paddingBottom: "10vh"
    },
    justifyContent: "space-evenly"
  },
  filter: {
    "&:before": {
      background: "rgb(0 0 0 / 40%)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "flex",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  small: {
    overflow: "hidden",
    position: "relative",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    margin: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
    paddingBottom: 20,
    minHeight: "50vh",   
    "@media (max-width: 1200px)": {
      height: "auto",
      flexDirection: "column",
      justifyContent: "space-evenly",
      paddingTop: "10vh",
      paddingBottom: "10vh"
    },
    justifyContent: "space-evenly"
  }
};

export default parallaxStyle;
