import React, { useEffect, useState } from 'react';
import {Route,Redirect} from 'react-router-dom';
import { useSelector } from "react-redux";
import ResponsiveDrawer from '../components/ResponsiveDrawer';
import CircularLoading from "../components/CircularLoading";

function matchUser(permit, usertype, owner, specialAdmin){
    let permitions = permit? permit.split(',') : [];
    let permitted = false;
    if(permitions.length===0) return false
    for(let i=0;i<permitions.length;i++){
        if(permitions[i]?.startsWith('dispatcher')){
               let splitName = permitions[i].split('-')
               //console.log(splitName,owner);
               if(splitName.length===1){
                    permitted = usertype === permitions[i]?true:false
               }
               else{
                  if(splitName[1]==='independent'){
                        permitted = usertype === splitName[0] &&  owner === 'independent' ?true:false
                  }
                  else if(splitName[1]==='carrier'){
                    permitted = usertype === splitName[0] &&  owner === 'carrier' ?true:false
                   }
                  else if(splitName[1]==='broker'){
                    permitted = usertype === splitName[0] &&  owner === 'broker' ?true:false
                   }
                  else if(splitName[1]==='third'){
                    permitted = usertype === splitName[0] &&  owner === 'dispatcher' ?true:false
                   
                   }
               }
        }
        else if(permitions[i]?.startsWith('fleetadmin')){
            let splitName = permitions[i].split('-')
            //console.log(splitName,owner);
            if(splitName.length===1){
                 permitted = usertype === permitions[i]?true:false
            }
            else{
                permitted = usertype === splitName[0] &&  specialAdmin
            }
     }
        else{
            permitted = usertype === permitions[i]?true:false
        }
        if(permitted) break;
    }
    return permitted;
}

function ProtectedRoute({ component: Component, permit, ...rest }) {
    const auth = useSelector(state => state.auth);
    const [checkedAuth,setCheckedAuth] = useState(false);

    useEffect(()=>{
        if(auth.info && auth.info.profile){
            setCheckedAuth(true);
        }
        if(auth.error && auth.error.msg && !auth.info){
            setCheckedAuth(true);
        }
    },[auth.info,auth.error])

    return(
        checkedAuth?
        <Route {...rest} render={props => (
            auth.info && auth.info.profile?
                matchUser(permit,auth.info.profile.usertype, checkDispatchersOwner(auth.info?.profile?.independent, auth.info?.profile?.carrierId , auth.info?.profile?.brokerId, auth.info?.profile?.dispatcherId)  ,  auth?.info?.profile?.specialAdmin ) ?
                (rest.path === '/truckboard' || rest.path === '/loadboard')?  <Component {...props} /> : 
                    <ResponsiveDrawer>
                        <Component {...props} />
                    </ResponsiveDrawer>
                    
                :<Redirect to="/login" /> 
            :<Redirect to="/login" /> 
        )} />
        :<CircularLoading/>
    )
}

export default ProtectedRoute;

function checkDispatchersOwner(independent, carrierId, brokerId, dispatcherId){
    if(independent){
        return 'independent'
    }
    else if(carrierId){
        return 'carrier'
    }
    else if(brokerId){
        return 'broker'
    }
    else if(dispatcherId){
        return 'dispatcher'
    }
}