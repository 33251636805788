import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/staticPages.js";
import Parallax from "components/Parallax/Parallax";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { Box } from "@mui/material";
import HeroImg from '../assets/img/BG_HERO_DESIGN.png';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);


export default function PrivacyPolicy(props) {
    const { t,i18n } = useTranslation();
    const isRTL = i18n.dir();
  const classes = useStyles();
  const settings = useSelector(state => state.settingsdata.settings);
  const { ...rest } = props;
  const FooterBackground = 'transparent';
  const footerColor = 'white'

  const isSmallScreen = useMediaQuery('(max-height: 730px)')
  const isSmallerScreen = useMediaQuery('(max-height: 1000px)')
  const isMoreSmallScreen = useMediaQuery('(max-height: 1100px)')

  return (
    <div>
       <Header
        color="landing"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />

      <Div12 style={{
                    backgroundImage: `url(${HeroImg})`,
                    //  backgroundPosition: 'center',
                    //  backgroundSize: 'cover',
                    //  backgroundRepeat: 'repeat'
                }}>
            <div style={{color:"white" , 
                      marginLeft: 'auto', marginRight: 'auto', padding: ' 0px 20px'}}>
                    <div className={classes.container} style={{color:"white"}}>
                        <h2  style={{textAlign:'center',margin: "0px 0 40px 0",color: "white",fontWeight: "800"}}>
                            {t('privacy_policy')}
                       </h2>
                       <div style={{font:' 500 24px Open Sans, sans-serif'}}>
                            <div >
                                <P >{t('privacy_policy_para1')}</P>
                                <P >{t('privacy_policy_para2')}</P>
                                <P >{t('privacy_policy_para3')}</P>

                                <P >
                                    <strong>{t('privacy_policy_heading_info')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_info_para1')}
                                </P>

                                <P >
                                    <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
                                        <li>
                                            {t('privacy_policy_info_list1')}
                                        </li>
                                        <li>
                                            {t('privacy_policy_info_list2')}
                                        </li>
                                        <li>
                                        {t('privacy_policy_info_list3')}
                                        </li>
                                        <li>
                                        {t('privacy_policy_info_list4')}
                                        </li>
                                    </ul>
                                </P>

                                <P >
                                    {t('privacy_policy_info_para2')}
                                </P>
                                <P >
                                    {t('privacy_policy_info_para3')}
                                </P>
                                <P >
                                    {t('privacy_policy_info_para4')}
                                </P>
                                <P >
                                    <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
                                        <li>
                                            {t('privacy_policy_info_list5')}
                                        </li>
                                        <li>
                                            {t('privacy_policy_info_list6')}
                                        </li>
                                        <li>
                                            {t('privacy_policy_info_list7')}
                                        </li>
                                        
                                    </ul> 
                                </P>
                                <P >
                                    {t('privacy_policy_info_para5')}
                                </P>
                                <P >
                                    {t('privacy_policy_info_para6')}
                                </P>
                                <P >
                                    <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
                                        <li>
                                            <a href="https://www.facebook.com/about/privacy/update/printable"
                                            target="_blank" rel="noopener noreferrer">Facebook</a>
                                        </li>
                                    </ul>
                                </P>

                            </div>
                            <div>
                                <P >
                                    <strong>{t('privacy_policy_heading_log')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_log_para1')}
                                </P>
                                <P >
                                    <strong>{t('privacy_policy_heading_cookie')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_cookie_para1')}
                                </P>
                                <P >
                                    {t('privacy_policy_cookie_para2')}
                                </P>
                                <P >
                                    <strong>{t('privacy_policy_heading_service')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_service_para1')}
                                </P>
                                <P >
                                <ul >
                                    <li>
                                        {t('privacy_policy_service_list1')}
                                    </li>
                                    <li>
                                        {t('privacy_policy_service_list2')}
                                    </li>
                                    <li>
                                        {t('privacy_policy_service_list3')}
                                    </li>
                                    <li>
                                        {t('privacy_policy_service_list4')}
                                    </li>
                                </ul>
                                </P>
                                <P >
                                    {t('privacy_policy_service_para2')}
                                </P>
                                <P >
                                    <strong>{t('privacy_policy_heading_security')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_security_para1')}
                                </P>
                                <P >
                                    <strong>{t('privacy_policy_heading_link')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_link_para1')}
                                </P>
                                <P >
                                    <strong>{t('privacy_policy_heading_children')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_children_para1')}
                                </P>
                                <P >
                                    <strong>{t('delete_account_lebel').toUpperCase()}</strong>
                                </P>
                                <P >
                                    {t('delete_account_msg')}
                                </P>
                                <P >
                                    {t('delete_account_subheading')}
                                </P>
                                <P >
                                    <ul style={{direction:isRTL === 'rtl'?'rtl':'ltr'}}>
                                        <li>
                                        {t('delete_account_para1')}
                                        </li>
                                        <li>
                                        {t('delete_account_para2')}
                                        </li>
                                    </ul>
                                </P>
                                <P >
                                    <strong>{t('privacy_policy_heading_change_privacy')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_change_privacy_para1')}
                                </P>
                                <P >
                                    {t('privacy_policy_change_privacy_para2')}
                                </P>
                                <P >
                                    <strong>{t('privacy_policy_heading_contact')}</strong>
                                </P>
                                <P >
                                    {t('privacy_policy_contact_para1')} 
                                    {settings && settings.contact_email?
                                        <a href={"mailto:" + settings.contact_email}><strong>{settings.contact_email}</strong></a>
                                    :null}
                                </P>
                            </div>

                       </div>
                    </div>
            </div>
       </Div12>
      <Footer backgroundColor={FooterBackground} color={footerColor}/>
    </div>
  );
}

const P=styled.p`
 font:500 17px Open Sans, sans-serif;
 line-height:1.5;
`
const Div12 = styled.div`
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: flex;
  width: 100%;
  height:100%;
  min-height:80vh;
  padding: 51px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
`;
