import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import Calendar from "react-calendar";
import { colors } from "../components/Theme/WebTheme";
import Button from "components/CustomButtons/Button.js";
import { Modal, Grid, Typography, IconButton, TextField,Select,
    MenuItem, FormControl,InputLabel, Link, useMediaQuery, 
    InputAdornment} from "@material-ui/core";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

const useStyles = makeStyles((theme) => ({
    submit: {
      backgroundColor: "#de7d1e",
    },
    modal: {
      display: "flex",
      padding: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 15,
      alignItems: "center",
      justifyContent: "center",
    },
  }));

export default function LoadBoard() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    addLoad,
    EditLoad,
    fetchLoadBoardBookings,
    deleteLoad,
    fetchRouteTypes,
    fetchUsersOnce,
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);
  const loadBoardData = useSelector(state => state.loadBoardData);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const rootRef = useRef(null);
  const trailertypes = useSelector((state) => state.trailertypes);
  const routeTypes = useSelector((state) => state.routetypes.routes);
  const bookinglistdata = useSelector(state => state.bookinglistdata);
  const userdata = useSelector(state => state.usersdata);

  
  useEffect(() => {
    dispatch(fetchRouteTypes());
  }, [dispatch, fetchRouteTypes]);


  useEffect(()=>{
    dispatch(fetchLoadBoardBookings(auth.info.uid));
},[dispatch,fetchLoadBoardBookings,auth?.info?.uid,fetchUsersOnce,bookinglistdata]);

const [routes, setRoutes] = useState({});
const [trailers, setTrailers] = useState({});

useEffect(() => {
    if (routeTypes) {
      let obj = {};
      routeTypes.map(
        (trailer) => (obj[trailer.name] = trailer.name)
      );
      setRoutes(obj);
    }
  }, [routeTypes]);
  
  useEffect(() => {
    if (trailertypes.trailers) {
      let obj = {};
      trailertypes.trailers.map(
        (trailer) => (obj[trailer.name] = trailer.name)
      );
      setTrailers(obj);
    }
  }, [trailertypes.trailers]);

const [loading, setLoading] = useState(false);
const [pickup, setPickup] = useState(null);
const [drop, setDrop] = useState(null);

const [pickupDate, setPickupDate] = useState(null);
const [dropDate, setDropDate] = useState(null);
const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

const [instructionData, setinstructionData] = useState({
    tripInstructions:"",
    deliveryInstructions:"",
    deliveryPerson:"",
    pickUpInstructions:"",
    deliveryPersonPhone:"",
    commodity: "",
    loadIdentification: "",
    shipperOffer: "",
    loadDimensions: "",
    loadWeight:"",
});
const [rowToUpdate,setRowToUpdate]=useState({})
const [PickupModal, setPickupModal] = useState(false);
const [PickupDateModal, setPickupDateModal] = useState(false);
const [DropModal, setDropModal] = useState(false);
const [DropDateModal, setDropDateModal] = useState(false);
const [instructionModal, setInstructionModal] = useState(false);
const [loadData,setLoadData]=useState(null)

function checkAllInstructionsAreFilled(data){
    if(data?.deliveryPerson&&data.shipperOffer&& data.loadWeight && data.loadDimensions &&(data?.deliveryPersonPhone && data?.deliveryPersonPhone.length>6)){
            return true
        }
    return false

}


const openPickupModal = (rowData) => {
    setLoadData(rowData)
    if(!pickup||rowToUpdate?.pickup!==rowData.id){
        setPickup(rowData.pickup
        ? {
            coords:rowData.pickup.coords ,
            description: rowData.pickup.description,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, pickup: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, pickup: null }))

    // Open the modal
    setPickupModal(true);
  };

const openDropModal = (rowData) => {
    setLoadData(rowData)
    if(!drop||rowToUpdate?.drop!==rowData.id){
        setDrop(rowData.drop
        ? {
            coords:rowData.drop.coords ,
            description: rowData.drop.description,
          }
        : "")
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, drop: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, drop: null}))
    // Open the modal
    setDropModal(true);
  };

const openPickupDateModal = (rowData) => {
    setLoadData(rowData)
    if(!pickupDate||rowToUpdate?.pickupDate!==rowData?.id){
        setPickupDate( rowData.pickupDate? rowData.pickupDate: null
      );
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, pickupDate: rowData.id })); 
    setPickupDateModal(true);
  };

const openDropDateModal = (rowData) => {
    setLoadData(rowData)
    if(!dropDate||rowToUpdate?.dropDate!==rowData?.id){
        setDropDate( rowData.dropDate? rowData.dropDate: null
      );
    }
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, dropDate: rowData.id })); 
    setDropDateModal(true);
  };

const openInstuctionModal=(rowData)=>{
    setLoadData(rowData)

    if(rowToUpdate?.instructionData===rowData?.id){
        setinstructionData(instructionData)
    }
    else if(rowData.instructionData?.tripInstructions) setinstructionData(rowData.instructionData)
    else{
        setinstructionData({
            tripInstructions:"",
            deliveryInstructions:"",
            deliveryPerson:"",
            pickUpInstructions:"",
            deliveryPersonPhone:"",
        })
    }
   
    if(rowData?.id)  setRowToUpdate(e => ({ ...e, instructionData: rowData.id })); 
    //else setRowToUpdate(e => ({ ...e, instructionData: null })); 

    // Open the modal
    setInstructionModal(true);
}

const giveIdThenIwillGiveYouData=(id)=>{
  const targetUser =userdata.users.filter(e=>{
    return e.id===id
  })
  if(targetUser.length>0) return targetUser[0]
  return null

}


const pageBackgroundColor=()=>{
  if(auth?.info?.profile?.dashboardColor) return auth?.info?.profile?.dashboardColor
  if(giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor) return giveIdThenIwillGiveYouData(auth?.info?.profile?.brokerId)?.dashboardColor
  return "#01aac1"
}

const columns = [
    { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null,cellStyle:{textAlign:isRTL=== 'rtl' ?'right':'left'}},
    { 
        title:"Pick up", 
        field: 'pickup',
        render: (rowData) =>
            rowData.pickup||pickup?.description ? (
                <div>
                <p>{(pickup?.description&&rowToUpdate?.pickup===rowData.id)?pickup?.description:rowData?.pickup?.description}</p>
                </div>
            ) : (
                "Missing Pick Up"
        ),
        editComponent: (props) => (
            <button onClick={() => openPickupModal(props.rowData)}>
                {props.rowData.pickup||pickup?.description ? (
                <div>
                    <p>{(pickup?.description&&rowToUpdate?.pickup===props.rowData.id)?
                     pickup?.description
                    :props.rowData?.pickup?.description}</p>
                </div>
                ) : (
                "Add"
                )}
            </button>
        ),
        cellStyle: {
        backgroundColor: "#D6F2F6",
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
        },
        headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
        },
     
     },

    { title: "Drop off", field: 'drop',
    render: (rowData) =>
        rowData.drop||drop?.description ? (
            <div>
            <p>{(drop?.description&&rowToUpdate?.drop===rowData.id)?drop?.description:rowData?.drop?.description}</p>
            </div>
        ) : (
            "Missing Drop off"
    ),
    editComponent: (props) => (
        <button onClick={() => openDropModal(props.rowData)}>
            {props.rowData.drop||drop?.description ? (
            <div>
                <p>{(drop?.description&&rowToUpdate?.drop===props.rowData.id)?drop?.description:props.rowData?.drop?.description}</p>
            </div>
            ) : (
             "Add"
            )}
        </button>
    ),
    cellStyle: {
        backgroundColor: "#D6F2F6",
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
        },
    headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
        }
    },

    { title: "Route type", field: 'routeType',
      lookup: routes,
     render: rowData => rowData?.instructionData?.optionIndex,
     cellStyle: { 
        textAlign: isRTL === "rtl" ? "right" : "center", 
        minWidth: 150,
        backgroundColor: "#E2F6F9",
        zIndex: 2
      },
      headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
      }, 
    },

     {
        title: t("trailer_type"),
        field: "trailerType",
        lookup: trailers,
        cellStyle: { 
          textAlign: isRTL === "rtl" ? "right" : "center", 
          minWidth: 150,
          backgroundColor: "#E2F6F9",
          zIndex: 2
        },
        headerStyle: {
          fontWeight: "bold",
          backgroundColor: pageBackgroundColor(),
          color: "white",
          textAlign: "center",
          zIndex: 2
        },
      },
    
     { title: "Instructions", field: 'instructionData',
     render: rowData => checkAllInstructionsAreFilled(rowData.instructionData)||checkAllInstructionsAreFilled(instructionData)?
         <CheckIcon/>:<DoDisturbIcon/>,
     editComponent: (props) => (
        <button onClick={() => openInstuctionModal(props.rowData)}>
                 {((rowToUpdate?.instructionData===props.rowData?.id&&checkAllInstructionsAreFilled(instructionData))
                    ||checkAllInstructionsAreFilled(props.rowData.instructionData))
                 ?"Update Instruction":'Add Instruction'}
        </button>
     ),
        cellStyle: {
            backgroundColor: "#D6F2F6",
            textAlign: isRTL === "rtl" ? "right" : "center",
            width: 150,
            minWidth: 150,
            zIndex: 2
        },
        headerStyle: {
            fontWeight: "bold",
            backgroundColor: pageBackgroundColor(),
            color: "white",
            textAlign: "center",
            zIndex: 2
        }
    },
    
    { title: "Pickup  Date",  field: 'pickupDate',
    render: (rowData) =>
        // <button onClick={() => openFrequentRouteModal(rowData)}
        // >
        rowData.pickupDate||pickupDate ? (
        <div>
            
            <p>
            {(pickupDate&&rowToUpdate?.pickupDate===rowData.id)?
            (new Date(pickupDate).getTime()>new Date().getTime()? 
                moment(pickupDate).format("MM/DD/YYYY"):
                "Missing Return Home Date"
            ):
            (new Date(rowData.pickupDate).getTime()>new Date().getTime()? 
                moment(rowData.pickupDate).format("MM/DD/YYYY"):
                "Missing Pickup Date"
            )
            }</p>
        </div>
        ) : (
        "Missing Pickup Date"
        ),
        // </button>
    editComponent: (props) => (
        <button onClick={() => openPickupDateModal(props.rowData)}>
        {props.rowData.pickupDate||pickupDate ? (
            <div>
               <p>{(pickupDate&&rowToUpdate?.pickupDate===props.rowData.id)?moment(pickupDate).format("MM/DD/YYYY"):moment(props.rowData.pickupDate).format("MM/DD/YYYY")}</p>
            </div>
        ) : (
            "Add"
        )}
        </button>
    ),
    cellStyle: {
        backgroundColor: "#D6F2F6",
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
    },
    headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
    },
      
    },

    { title: "Drop Date",  field: 'dropDate',
    render: (rowData) =>
        // <button onClick={() => openFrequentRouteModal(rowData)}
        // >
        rowData.dropDate||dropDate ? (
        <div>
            
            <p>
            {(dropDate&&rowToUpdate?.dropDate===rowData.id)?
            (new Date(dropDate).getTime()>new Date().getTime()? 
                moment(dropDate).format("MM/DD/YYYY"):
                "Missing Drop Date"
            ):
            (new Date(rowData.dropDate).getTime()>new Date().getTime()? 
                moment(rowData.dropDate).format("MM/DD/YYYY"):
                "Missing Drop Date"
            )
            }</p>
        </div>
        ) : (
        "Missing Drop Date"
        ),
        // </button>
    editComponent: (props) => (
        <button disabled={!pickupDate} onClick={() => openDropDateModal(props.rowData)}>
        {props.rowData.dropDate||dropDate ? (
            <div>
              <p>{(dropDate&&rowToUpdate?.dropDate===props.rowData.id)?moment(dropDate).format("MM/DD/YYYY"):moment(props.rowData.dropDate).format("MM/DD/YYYY")}</p>
            </div>
        ) : (
            "Add"
        )}
        </button>
    ),
    cellStyle: {
        backgroundColor: "#D6F2F6",
        textAlign: isRTL === "rtl" ? "right" : "center",
        width: 150,
        minWidth: 150,
        zIndex: 2
    },
    headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
    },
      
    },
     {
      title: "Public Load",
      field: "isPublic",
      type: "boolean",
      initialEditValue: false,
      cellStyle: {
        fontWeight: "bold",
        textAlign: isRTL === "rtl" ? "right" : "center",
        backgroundColor: "#92b3b8",
        zIndex: 2
      },
      headerStyle: {
        fontWeight: "bold",
        backgroundColor: pageBackgroundColor(),
        color: "white",
        textAlign: "center",
        zIndex: 2
      },
    },

  ];


const handlePickupDateSelect = (date) => {
    if(new Date(date).getTime()>new Date().getTime()){
       setPickupDate(date); 
     }
};

const isPickupAndDropAreValid = async (pickup, drop) => {

  if (!pickup || !drop) return false;

  try {
    const directionService = new window.google.maps.DirectionsService();
    const result = await directionService.route({
      origin: new window.google.maps.LatLng(pickup.coords.lat, pickup.coords.lng),
      destination: new window.google.maps.LatLng(drop.coords.lat, drop.coords.lng),
      travelMode: window.google.maps.TravelMode.DRIVING,
    });

    return result.status === window.google.maps.DirectionsStatus.OK;
  } catch (error) {
    //console.error(error);
    return false;
  }
};

const handleDropDateSelect = (date) => {
  if(new Date(date).getTime()>new Date().getTime()&& new Date(date).getTime() > new Date(pickupDate).getTime()){
     setDropDate(date); 
   }
   if(new Date(date).getTime() <= new Date(pickupDate).getTime()){
      setCommonAlert({ open: true, msg: "The Drop Date cannot be earlier than the Pickup Date." })
   }
};

const [isTooShort, setIsTooShort] = useState(false)
const [deliveryPersonPhoneWithOutPlus, setDeliveryPersonPhoneWithOutPlus] = useState('')
const handleChange = (e) => {
  if (e.target.name === 'deliveryPersonPhone') {
    setDeliveryPersonPhoneWithOutPlus(e.target.value.replace(/[^0-9]/g, ''))
    let deliveryPersonPhoneValue = '+' + e.target.value.replace(/[^0-9]/g, '');
    setinstructionData({ ...instructionData, [e.target.name]: deliveryPersonPhoneValue});
  }
  else{
      setinstructionData({ ...instructionData, [e.target.name]: e.target.value });
    }
  };

  const [isInstructionComplete,setIsInstructionComplete]=useState(false);
const SaveLoadInfo=()=>{
    if(checkAllInstructionsAreFilled(instructionData)){
        handleSetInstruction()
        setInstructionModal(false)

    }else{
        setIsInstructionComplete(true) 
        setTimeout(()=>{
            setIsInstructionComplete(false)
        },7000)
    }
  }

const handleSetPickup=()=>{
    if(!pickup) return
    if(loadData){
        let datatToBeUpdate={...loadData,pickup:pickup}
        dispatch(EditLoad(datatToBeUpdate));
        dispatch(fetchLoadBoardBookings(auth.info.uid));
    }
    setPickupModal(false)

    
}

const handleSetDrop=()=>{
    if(!drop) return
    if(loadData){
        let datatToBeUpdate={...loadData,drop:drop}
        dispatch(EditLoad(datatToBeUpdate));
        dispatch(fetchLoadBoardBookings(auth.info.uid));
    }
    setDropModal(false)
}

const handleSetPickupDate=()=>{
    if(!pickupDate) return
    if(loadData){
        let datatToBeUpdate={...loadData,pickupDate:pickupDate}
        dispatch(EditLoad(datatToBeUpdate));
        dispatch(fetchLoadBoardBookings(auth.info.uid));
    }
    setPickupDateModal(false)
}

const handleSetDropDate=()=>{
  if(!dropDate) return
  if(loadData){
      let datatToBeUpdate={...loadData,dropDate:dropDate}
      dispatch(EditLoad(datatToBeUpdate));
      dispatch(fetchLoadBoardBookings(auth.info.uid));
  }
  setDropDateModal(false)
}

const handleSetInstruction=()=>{
    if(!checkAllInstructionsAreFilled(instructionData)) return
    if(loadData){
        let datatToBeUpdate={...loadData,instructionData:instructionData}
        dispatch(EditLoad(datatToBeUpdate));
        dispatch(fetchLoadBoardBookings(auth.info.uid));
    }
}


function EditRowDone(newData,oldData){ 
    let datatToBeUpdate={...newData}

    if(pickupDate&&rowToUpdate?.pickupDate===datatToBeUpdate.id){
      datatToBeUpdate["pickupDate"]=new Date(pickupDate).getTime()
    }
    if(dropDate&&rowToUpdate?.dropDate===datatToBeUpdate.id){
      datatToBeUpdate["dropDate"]=new Date(dropDate).getTime()
    }

    if(pickup&&rowToUpdate?.pickup===datatToBeUpdate.id){
        datatToBeUpdate["pickup"]=pickup
      }

   if(drop&&rowToUpdate?.drop===datatToBeUpdate.id){
        datatToBeUpdate["drop"]=drop
      }
      
    if(checkAllInstructionsAreFilled(instructionData)&&rowToUpdate?.instructionData===datatToBeUpdate.id){
        datatToBeUpdate["instructionData"]=instructionData
    }
     
      dispatch(EditLoad(datatToBeUpdate));
      setRowToUpdate({})
      setTimeout(() => {
        dispatch(fetchLoadBoardBookings(auth.info.uid));
    }, 1000);
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  


  return (
    (loadBoardData.loading&&loadBoardData)? <CircularLoading/>:
     <>
        <MaterialTable
        title= 'Manage Loads'
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr'}}
        data={loadBoardData.loadboard}
        options={{
            exportButton: settings.AllowCriticalEditsAdmin,
            sorting: true,
        }}
        localization={{ body:{
            addTooltip: (t('add')),
            deleteTooltip: (t('delete')),
            editTooltip: (t('edit')),
            emptyDataSourceMessage: (
            (t('blank_message'))
        ),
            editRow: { 
            deleteText: (t('delete_message')),
            cancelTooltip: (t('cancel')),
            saveTooltip: (t('save')) 
            }, 
            },
            toolbar: {
            searchPlaceholder: (t('search')),
            exportTitle: (t('export')),
            },
            header: {
            actions: (t('actions')) 
        },
        pagination: {
            labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
            labelRowsSelect: (t('rows')),
            firstTooltip: (t('first_page_tooltip')),
            previousTooltip: (t('previous_page_tooltip')),
            nextTooltip: (t('next_page_tooltip')),
            lastTooltip: (t('last_page_tooltip'))
        },
        }}
        editable={{
          onRowAdd: async (newData) =>
            new Promise((resolve,reject) => {
                setTimeout(async() => {
                    if(auth.info?.profile?.usertype === 'dispatcher'){
                      newData['customer'] = auth.info?.profile?.brokerId;
                     }
                    else newData['customer'] = auth.info.uid;
                    newData['customer_email'] = auth.info.profile.email;
                    newData['customer_name'] = auth.info.profile.firstName+" "+ auth.info.profile.lastName;
                    newData['customer_contact'] = auth.info.profile.mobile;
                    newData['customer_token'] = auth.info.profile.pushToken;
                    newData['customer_image'] = null;
                    instructionData['optionIndex']=newData['routeType']
                    instructionData['optionSelected']=routeTypes.filter(e=>e.name===newData['routeType'])[0]
                    newData['instructionData']=instructionData
                    newData['drop']=drop
                    newData['pickup']=pickup
                    newData['SharedCarriers']=auth.info.profile.SharedCarriers
                    newData['pickupDate']=new Date(pickupDate).getTime()
                    newData['dropDate']=new Date(dropDate).getTime()
                    newData['createdAt'] = new Date().toISOString();
                    if(newData['routeType']&&newData['trailerType']&&pickupDate&&dropDate&&drop&&pickup&&checkAllInstructionsAreFilled(instructionData)){
                          const isValidRoutes=await isPickupAndDropAreValid(pickup,drop)
                        if(isValidRoutes){
                                dispatch(addLoad(newData))
                                setPickup(null)
                                setDrop(null)
                                setPickupDate(null)
                                setDropDate(null)
                                
                                dispatch(fetchLoadBoardBookings(auth.info.uid));
                        }
                        else{
                            setCommonAlert({
                              open: true,
                              msg: 'The pickup and drop-off locations you provided are not valid. Please check the coordinates and try again.',
                            });
                        }
                        
                    }
                    else{
                      setCommonAlert({open:true,msg:"Please fill all fields"})
                    }
                    setinstructionData({
                      tripInstructions:"",
                      deliveryInstructions:"",
                      deliveryPerson:"",
                      pickUpInstructions:"",
                      deliveryPersonPhone:"",
                      commodity: "",
                      loadIdentification: "",
                      shipperOffer: "",
                      loadDimensions: "",
                      loadWeight:"",
                    })
                    setDeliveryPersonPhoneWithOutPlus('')
                    resolve();
                }, 600);
            }),

            onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
                setTimeout(() => {

                  dispatch(EditLoad(newData));
                  dispatch(fetchLoadBoardBookings(auth.info.uid));
                    resolve();
                   
                }, 600);
            }),
            onRowDelete: oldData =>
            new Promise(resolve => {
               setTimeout(() => {
                  dispatch(deleteLoad(oldData.id))
                  dispatch(fetchLoadBoardBookings(auth.info.uid));
                   resolve();
                }, 600);
            })
            
        }}
        />

        
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={PickupModal}
        onClose={()=>setPickupModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Pick Up"}
              value={pickup}
              className={classes.items}
              onChange={(value) => {
                setPickup(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetPickup}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={DropModal}
        onClose={()=>setDropModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          <Grid item xs={12} style={{ width:"100%"}}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={"Drop off"}
              value={drop}
              className={classes.items}
              onChange={(value) => {
                setDrop(value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetDrop}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
          
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={PickupDateModal}
        onClose={()=>setPickupDateModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            borderRadius: 8,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
          }}
        >
          <Grid item xs={12}>
            <Calendar onChange={handlePickupDateSelect} value={pickupDate} />
            {/* moment(selectedDate).format('dddd, MMM D') */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetPickupDate}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={DropDateModal}
        onClose={()=>setDropDateModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            borderRadius: 8,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
          }}
        >
          <Grid item xs={12}>
            <Calendar onChange={handleDropDateSelect} value={dropDate} />
            {/* moment(selectedDate).format('dddd, MMM D') */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleSetDropDate}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>

      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={instructionModal}
        onClose={()=>setInstructionModal(false)}
        className={classes.modal}
        container={() => rootRef.current}
      >
        
        <Grid container spacing={1} className={classes.paper} style={{height:"80vh",overflow:"auto"}}>
          <Typography component="h2" variant="h5" style={{ marginTop: 15, color: '#000' }}>
            {t('delivery_information')}
          </Typography>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="loadWeight"
              label={"Load Weight *"}
              name="loadWeight"
              autoComplete="loadWeight"
              type="number"
              onChange={handleChange}
              value={instructionData.loadWeight}
              autoFocus
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                      <span>Kg</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="loadDimensions"
              label={"Load Dimensions *"}
              name="loadDimensions"
              autoComplete="loadDimensions"
              onChange={handleChange}
              value={instructionData.loadDimensions}
              autoFocus
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryPerson"
              label={'Receiiver Name *'}
              name="deliveryPerson"
              autoComplete="deliveryPerson"
              onChange={handleChange}
              value={instructionData.deliveryPerson}
              autoFocus
              className={isRTL === 'rtl' ? classes.inputRtl : null}
              style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryPersonPhone"
              label={'Receiiver Phone No *'}
              name="deliveryPersonPhone"
              autoComplete="deliveryPersonPhone"
              type="number"
              onChange={handleChange}
              value={deliveryPersonPhoneWithOutPlus}
              className={isRTL === 'rtl' ? [classes.inputRtl, classes.rightRty] : null}
              style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
              onBlur={() => {
                if (instructionData.deliveryPersonPhone.length < 6) setIsTooShort(!isTooShort)
                setTimeout(() => {
                  setIsTooShort(false)
                }, 3000);
              }}
            />
            {isTooShort ? <p style={{color: 'red', fontSize: 10}}>The number you entered is too short</p> : <></>}
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="pickUpInstructions"
              label={t('pickUpInstructions')}
              name="pickUpInstructions"
              autoComplete="pickUpInstructions"
              onChange={handleChange}
              value={instructionData.pickUpInstructions}
              className={isRTL === 'rtl' ? classes.inputRtl : null}
              style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="deliveryInstructions"
              label={t('deliveryInstructions')}
              name="deliveryInstructions"
              autoComplete="deliveryInstructions"
              onChange={handleChange}
              value={instructionData.deliveryInstructions}
              className={isRTL === 'rtl' ? classes.inputRtl : null}
              style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="tripInstructions"
              label={"Trip Instruction"}
              name="tripInstructions"
              autoComplete="tripInstructions"
              onChange={handleChange}
              value={instructionData.tripInstructions}
              className={isRTL === 'rtl' ? classes.inputRtl : null}
              style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="commodity"
              label={"Commodity"}
              name="commodity"
              autoComplete="commodity"
              onChange={handleChange}
              value={instructionData.commodity}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="loadIdentification"
              label={"Load Identification"}
              name="loadIdentification"
              autoComplete="loadIdentification"
              onChange={handleChange}
              value={instructionData.loadIdentification}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>

         
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              type={"number"}
              margin="normal"
              fullWidth
              id="shipperOffer"
              label={"Load Rate *"}
              name="shipperOffer"
              autoComplete="shipperOffer"
              onChange={handleChange}
              value={instructionData.shipperOffer}
              className={isRTL === "rtl" ? classes.inputRtl : null}
              style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
            />
          </Grid>


          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center'}}>
            {isInstructionComplete&&<div style={{color:"red"}}>Please fill all necessery fields or make your phone number long enough</div>}
            <Button onClick={SaveLoadInfo} variant="contained" color="primary" style={isRTL === 'rtl' ? { marginRight: 10 } : { marginLeft: 10 }}>
              Save
            </Button>
          </Grid>
        </Grid>

      </Modal>

      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
     </>
  );
}
