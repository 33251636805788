import React,{ useState, useEffect, useContext, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { FirebaseContext } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapsAutoComplete from "../components/GoogleMapsAutoComplete";
import Calendar from "react-calendar";
import { colors } from "../components/Theme/WebTheme";
import Button from "components/CustomButtons/Button.js";
import { Modal, Grid, Typography, IconButton, TextField,Select,
    MenuItem, FormControl,InputLabel, Link, useMediaQuery, Box,Snackbar, 
    Paper} from "@material-ui/core";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckCircle from "@material-ui/icons/CheckCircle";
import UsersCombo from '../components/UsersCombo';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { getContinent } from 'components/getContinent';

const useStyles = makeStyles((theme) => ({
    submit: {
      backgroundColor: "#de7d1e",
    },
    modal: {
      display: "flex",
      padding: theme.spacing(1),
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 15,
      alignItems: "center",
      justifyContent: "center",
    },
  }));

export default function LoadBoard() {
  const { api } = useContext(FirebaseContext);
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const classes = useStyles();
  const rootRef = useRef(null);

  const {
    getEstimate,
    fetchLoadBoardBookings,
    clearEstimate,
    fetchRouteTypes,
    fetchUsersOnce,
    addBooking
  } = api;
  const estimatedata = useSelector((state) => state.estimatedata);
  const settings = useSelector(state => state.settingsdata.settings);
  const loadBoardData = useSelector(state => state.loadBoardData);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const trailertypes = useSelector((state) => state.trailertypes);
  const routeTypes = useSelector((state) => state.routetypes.routes);
  const userdata = useSelector(state => state.usersdata);

  //const staticusers = useSelector(state => state.usersdata.staticusers);
  const  [BookFleetAdmin, HandleBookFleetAdmin] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [drivers, setDrivers] = useState([]);
  const [driverCombo, setDriverCombo] = useState(null);
  const [SharedCariers,SetSharedCarriers]=useState([])
  const [ActiveSharedCarrier,SetActiveSharedCarrier]=useState(null)
  const [targetLoad,SetTargetLoad]=useState(null)
  const bookinglistdata = useSelector(state => state.bookinglistdata);


  useEffect(() => {
    dispatch(fetchRouteTypes());
  }, [dispatch, fetchRouteTypes]);

  function isCarrierAlreadyBook(loadId,carrierId){
      if(!loadId||!carrierId||!bookinglistdata.bookings) return false
      let bookExists=bookinglistdata.bookings.filter(e=>{
        return e.loadId===loadId&&e.carrierId===carrierId&&(e.status!=='CANCELLED')
    }).length>0

    return bookExists
  }

  const giveIdThenIwillGiveYouData=(id)=>{
    const targetUser =userdata.users.filter(e=>{
     return e.id===id
  })
    if(targetUser.length>0) return targetUser[0]
    return null

}

  useEffect(()=>{
        if((isSuperDispatcher()||auth.info?.profile?.dispatcherId)&&userdata.users){
          let SharedCarriers = auth.info?.profile?.dispatcherId? giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)?.SharedCarriers :auth.info?.profile?.SharedCarriers
          if(SharedCarriers&&SharedCarriers.length>0){
                let carriers=[] 
                SharedCarriers.map(e=>{
                    if(e.approved&&e.aprovedByCarrier){
                      const carrier=userdata.users.filter(element=>{
                          return element.id===e.id
                      })
                      if(carrier.length>0){
                        if(!isCarrierAlreadyBook(targetLoad?.id,carrier[0]?.id)){
                            carriers.push({
                                ...carrier[0],
                                'desc': carrier[0].firstName + ' ' + carrier[0].lastName + ' (' + (settings.AllowCriticalEditsAdmin ? carrier[0].mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? carrier[0].email : "Hidden"),
          
                              })
                          }
                        }
                    }
                })
                SetSharedCarriers(carriers)
           }
         }
         else{
          SetSharedCarriers([])
         }

  },[auth.info,userdata.users,targetLoad,ActiveSharedCarrier,bookinglistdata.bookings])
  

 function isSuperDispatcher(){
    return auth.info.profile.usertype==='dispatcher'&&auth.info.profile.independent
  }

  function fetchLoadBoards(){
    if(isSuperDispatcher()){
      HandleBookFleetAdmin(ActiveSharedCarrier?.id||null);
    }
    else if(auth.info?.profile?.dispatcherId){
      HandleBookFleetAdmin(ActiveSharedCarrier?.id||null);
    }
    else if(auth.info.profile.usertype==='dispatcher'&&auth.info.profile.carrierId){
      HandleBookFleetAdmin(auth.info.profile.carrierId);
    }
    else{
        HandleBookFleetAdmin(auth.info.uid);
    }
    dispatch(fetchLoadBoardBookings(null));
  }

  useEffect(()=>{
    fetchLoadBoards()
},[dispatch,fetchLoadBoardBookings,auth?.info?.uid,fetchUsersOnce,bookinglistdata,ActiveSharedCarrier]);

const [estimateRequested, setEstimateRequested] = useState({
  loading: false,
  id: null,
});

const [routes, setRoutes] = useState({});
const [trailers, setTrailers] = useState({});
const [bookProgress,setBookProgress]=useState(false)

useEffect(() => {
    if (routeTypes) {
      let obj = {};
      routeTypes.map(
        (trailer) => (obj[trailer.name] = trailer.name)
      );
      setRoutes(obj);
    }
  }, [routeTypes]);
  
  useEffect(() => {
    if (trailertypes.trailers) {
      let obj = {};
      trailertypes.trailers.map(
        (trailer) => (obj[trailer.name] = trailer.name)
      );
      setTrailers(obj);
    }
  }, [trailertypes.trailers]);

function checkAllInstructionsAreFilled(data){
    if(data?.tripInstructions&&data?.deliveryInstructions&&data?.deliveryPerson&&data?.pickUpInstructions&&
        data?.deliveryPersonPhone){
            return true
        }
    return false

}
const [openBooking,setOpenBooking]=useState(false)

useEffect(() => {
  if (estimatedata.estimate && estimateRequested?.loading) {
    setOpenBooking(true);
  }
  else setOpenBooking(false);
  if (userdata.users) {
    let arrDrivers = [];
    for (let i = 0; i < userdata.users.length; i++) {
      let user = userdata.users[i];
      if((!user.approved||!user.emailVerified) && (user.usertype === 'driver') ){
        continue
    }
      if((isSuperDispatcher()||auth.info?.profile?.dispatcherId)&&ActiveSharedCarrier){
        if ((user.usertype) && (user.usertype === 'driver') && (user.queue === false) && (user.driverActiveStatus === true) && (user.location) && user.fleetadmin===ActiveSharedCarrier?.id) {
          arrDrivers.push({
            'uid': user.id,
            'location': user.location,
            'trailerType': user.trailerType,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            ...user
          });
        }
      }
      else if(auth.info.profile.usertype==="admin"){
          if ((user.usertype) && (user.usertype === 'driver') && (user.queue === false) && (user.driverActiveStatus === true) && (user.location)) {
            arrDrivers.push({
              'uid': user.id,
              'location': user.location,
              'trailerType': user.trailerType,
              'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
              ...user
            });
          }
      }

      else if(auth.info.profile.usertype==="fleetadmin"){
          if ((user.usertype) && (user.usertype === 'driver') && (user.queue === false) && (user.driverActiveStatus === true) && (user.location) && user.fleetadmin===auth.info.uid) {
            arrDrivers.push({
              'uid': user.id,
              'location': user.location,
              'trailerType': user.trailerType,
              'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
              ...user
            });
          }
      }

      else if(auth.info.profile.usertype==="dispatcher"){
        if ((user.usertype) && (user.usertype === 'driver') && (user.queue === false) && (user.driverActiveStatus === true) && (user.location) && user.fleetadmin===auth.info.profile.carrierId) {
          arrDrivers.push({
            'uid': user.id,
            'location': user.location,
            'trailerType': user.trailerType,
            'desc': user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
            ...user
          });
        }
     }
    }

    setDrivers(arrDrivers);
  }

}, [estimatedata.estimate, ActiveSharedCarrier,estimateRequested]);


const handleGetEstimate=(e,rowData)=>{
  e.preventDefault();
  SetTargetLoad(rowData)
  const {pickup,drop, pickupDate,isActive}=rowData
  setEstimateRequested({ loading: true, id: rowData.id });
    if (auth.info) {
        if (
          auth.info.profile.email === " " ||
          auth.info.profile.firstName === " " ||
          auth.info.profile.lastName === " "
        ) {
          setCommonAlert({ open: true, msg: t("profile_incomplete") });
          setEstimateRequested({ loading: false, id: null });
        } else {
          if (pickup && drop && pickupDate) {
            if (new Date().getTime() > new Date(pickupDate).getTime()) {
              setCommonAlert({
                open: true,
                msg: "This Load is expired to be booked.",
              });
              setEstimateRequested({ loading: false, id: null });
            } 
            else if(!isActive){
              setCommonAlert({
                open: true,
                msg: "Unable to Book inactive loads ",
              });
            }
            else {
              const directionService =
                new window.google.maps.DirectionsService();
              directionService.route(
                {
                  origin: new window.google.maps.LatLng(
                    pickup.coords.lat,
                    pickup.coords.lng
                  ),
                  destination: new window.google.maps.LatLng(
                    drop.coords.lat,
                    drop.coords.lng
                  ),
                  travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {

                  if (status === window.google.maps.DirectionsStatus.OK) {
                    const route = {
                      distance_in_km:
                        result.routes[0].legs[0].distance.value / 1000,
                      time_in_secs: result.routes[0].legs[0].duration.value,
                      polylinePoints: result.routes[0].overview_polyline,
                    };
                    const estimateRequest = {
                      pickup: pickup,
                      drop: drop,
                      routeDetails: route,
                      post_request: rowData ? rowData : null,
                    };

                    dispatch(getEstimate(estimateRequest));
                    
                  } else {
                    setCommonAlert({
                      open: true,
                      msg: t("place_to_coords_error"),
                    });
                    setEstimateRequested({ loading: false, id: null });
                  }
                }
              );
            }
          } else {
            setCommonAlert({
              open: true,
              msg: t("select_pickup_drop_and_date"),
            });
            setEstimateRequested({ loading: false, id: null });
          }
        }
      
    } else {
      setCommonAlert({ open: true, msg: t("must_login") });
      setEstimateRequested({ loading: false, id: null });
    }
}

const columns = [
   
    { 
        title:"Pick up", 
        field: 'pickup',
        render: (rowData) =>
            rowData.pickup ? (
                <div>
                    {rowData?.pickup?.description}
                </div>
            ) : (
                "Missing Pick Up"
        ),
        cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "left",
            color: "black",
            width: 250,
            minWidth: 250,
            fontSize: "13px",
            fontWeight: 500,
          },
          headerStyle: {
            backgroundColor: "#01aac1",
            fontWeight: "bold",
            color: "white",
            fontSize: 18,
            width: 250,
            minWidth: 250,
          },
     
     },

    { title: "Drop off", field: 'drop',
    render: (rowData) =>
        rowData.drop? (
            <div>
                {rowData?.drop?.description}
            </div>
        ) : (
            "Missing Drop off"
    ),
    cellStyle: {
        textAlign: isRTL === "rtl" ? "right" : "left",
        color: "black",
        width: 250,
        minWidth: 250,
        fontSize: "13px",
        fontWeight: 500,
      },
      headerStyle: {
        backgroundColor: "#01aac1",
        fontWeight: "bold",
        color: "white",
        fontSize: 18,
        width: 250,
        minWidth: 250,
      },
    },
    
    { title: "Customer",  field: 'customer_name',editable:"never", 
        cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "left",
            color: "black",
            width: 250,
            minWidth: 250,
            fontSize: "13px",
            fontWeight: 500,
        },
        headerStyle: {
            backgroundColor: "#01aac1",
            fontWeight: "bold",
            color: "white",
            fontSize: 18,
            width: 250,
            minWidth: 250,
        },
      
    },

    { title: "Route type", field: 'routeType', 
      lookup: routes,
     render: rowData => rowData?.instructionData?.optionIndex,
     cellStyle: {
        textAlign: isRTL === "rtl" ? "right" : "left",
        color: "black",
        width: 250,
        minWidth: 250,
        fontSize: "13px",
        fontWeight: 500,
      },
      headerStyle: {
        backgroundColor: "#01aac1",
        fontWeight: "bold",
        color: "white",
        fontSize: 18,
        width: 250,
        minWidth: 250,
      },
    },

     {
        title: t("trailer_type"),
        field: "trailerType",
        lookup: trailers,
        cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "left",
            color: "black",
            width: 250,
            minWidth: 250,
            fontSize: "13px",
            fontWeight: 500,
          },
          headerStyle: {
            backgroundColor: "#01aac1",
            fontWeight: "bold",
            color: "white",
            fontSize: 18,
            width: 250,
            minWidth: 250,
          },
      },
    
     { title: "Instructions", field: 'instructionData',
     render: rowData => checkAllInstructionsAreFilled(rowData.instructionData)?
         <HourglassFullIcon/>:<HourglassBottomIcon/>,
         cellStyle: {
            textAlign: isRTL === "rtl" ? "right" : "left",
            color: "black",
            width: 250,
            minWidth: 250,
            fontSize: "13px",
            fontWeight: 500,
          },
          headerStyle: {
            backgroundColor: "#01aac1",
            fontWeight: "bold",
            color: "white",
            fontSize: 18,
            width: 250,
            minWidth: 250,
          },
    },
    
     { title: "Pickup Date",  field: 'pickupDate', 
    render: (rowData) =>
        // <button onClick={() => openFrequentRouteModal(rowData)}
        // >
        rowData.pickupDate? (
        <div>
             {moment(rowData.pickupDate).format("MM/DD/YYYY")}
        </div>
        ) : (
        "Missing Pick up Date"
        ),
        // </button>
        cellStyle: {
          textAlign: isRTL === "rtl" ? "right" : "left",
          color: "black",
          width: 250,
          minWidth: 250,
          fontSize: "13px",
          fontWeight: 500,
        },
        headerStyle: {
          backgroundColor: "#01aac1",
          fontWeight: "bold",
          color: "white",
          fontSize: 18,
          width: 250,
          minWidth: 250,
        },
      
    },

    { title: "Drop Date",  field: 'dropDate', 
    render: (rowData) =>
        // <button onClick={() => openFrequentRouteModal(rowData)}
        // >
        rowData.dropDate? (
        <div>
             {moment(rowData.dropDate).format("MM/DD/YYYY")}
        </div>
        ) : (
        "Missing Drop Date"
        ),
        // </button>
        cellStyle: {
          textAlign: isRTL === "rtl" ? "right" : "left",
          color: "black",
          width: 250,
          minWidth: 250,
          fontSize: "13px",
          fontWeight: 500,
        },
        headerStyle: {
          backgroundColor: "#01aac1",
          fontWeight: "bold",
          color: "white",
          fontSize: 18,
          width: 250,
          minWidth: 250,
        },
      
    },
    { title: 'Load Offer', field: 'shipperOffer', editable:'never', 
    render: rowData => rowData?.instructionData?.shipperOffer? `$${rowData?.instructionData?.shipperOffer}` : 'Unknown',
    cellStyle: {
      textAlign: isRTL === "rtl" ? "right" : "left",
      color: "black",
      width: 250,
      minWidth: 250,
      fontSize: "13px",
      fontWeight: 500,
    },
    headerStyle: {
      backgroundColor: "#01aac1",
      fontWeight: "bold",
      color: "white",
      fontSize: 18,
      width: 250,
      minWidth: 250,
    },
   },
   { title: 'Load Weight', field: 'loadWeight', editable:'never', 
    render: rowData => rowData?.instructionData?.loadWeight? `${rowData?.instructionData?.loadWeight} Kg` : 'Unknown',
    cellStyle: {
      textAlign: isRTL === "rtl" ? "right" : "left",
      color: "black",
      width: 250,
      minWidth: 250,
      fontSize: "13px",
      fontWeight: 500,
    },
    headerStyle: {
      backgroundColor: "#01aac1",
      fontWeight: "bold",
      color: "white",
      fontSize: 18,
      width: 250,
      minWidth: 250,
    },
  },

    // { title: "Created at", field: 'createdAt', editable:'never', defaultSort:'desc',
    //  render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null,
    //  cellStyle: {
    //     textAlign: isRTL === "rtl" ? "right" : "left",
    //     color: "black",
    //     width: 250,
    //     minWidth: 250,
    //     fontSize: "13px",
    //     fontWeight: 500,
    //   },
    //   headerStyle: {
    //     backgroundColor: "#01aac1",
    //     fontWeight: "bold",
    //     color: "white",
    //     fontSize: 18,
    //     width: 250,
    //     minWidth: 250,
    //   },
    // },


  ];

  if (auth?.info?.profile?.usertype !== "shipper" && !auth.info?.profile?.brokerId) {
    columns.unshift({
        title: "",
        field: "actions",
        headerStyle: {
          backgroundColor: "#01aac1",
          color: "white",
          fontSize: 16,
        },
        render: (rowData) => {
          const IconComponent = (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: 100,
                height: 50,
                color: "white",
                backgroundColor: estimateRequested.loading&&estimateRequested?.id=== rowData?.id?"white":"#01aac1",
                borderRadius: 5,
                fontSize: 10,
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              {estimateRequested.loading&&estimateRequested?.id=== rowData?.id?
                <CircularProgress size={25}/>
                :<>
                  <Typography variant="subtitle2" >Book</Typography>
                  <CheckCircle  style={{color:isLoadAlreadyBooked(rowData.id)?"gray":"green"}}/>
                </>}
            </div>
          );
            return (
              <IconButton
                onClick={(event) => {
                   handleGetEstimate(event,rowData);
                }}
                disabled={estimateRequested.loading||isLoadAlreadyBooked(rowData.id)}
              >
                {IconComponent}
              </IconButton>
            );
          
        },
        });
  }


const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

    // EstimateModalClose
const handleBookModalClose = (e) => {
      e.preventDefault();
      setOpenBooking(false);
      dispatch(clearEstimate());
      setEstimateRequested({ loading: false, id: null });
      SetTargetLoad(null)
      SetActiveSharedCarrier(null)
      setDriverCombo(null)
      setDrivers([])
    };

const [state, setState] = React.useState({
      open: false,
      message:""
    });
const { open,message } = state;

  
const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setState({ ...state, open: false });
    };


  const confirmBooking = (e) => {
      e.preventDefault();
      if(new Date(estimatedata.estimate.post_request.dropDate).getTime() < new Date().getTime() || new Date(estimatedata.estimate.post_request.pickupDate).getTime() < new Date().getTime()){
        setCommonAlert({ open: true, msg: "The Drop Date and Pickup Date cannot be Past Dates." })
        return
      }
     else if(new Date(estimatedata.estimate.post_request.dropDate).getTime() <= new Date(estimatedata.estimate.post_request.pickupDate).getTime()){
        setCommonAlert({ open: true, msg: "The Drop Date cannot be earlier than the Pickup Date." })
        return
     }
      // else if(driverCombo.availableWeight && Number(driverCombo.availableWeight) < Number(estimatedata?.estimate?.post_request?.instructionData?.loadWeight)){
      //   setCommonAlert({ open: true, msg: `The weight of ${driverCombo?.availableWeight} Kg is the maximum this driver can handle.` });   
      //   return
      // }

        setBookProgress(true);
        setOpenBooking(false);
       
         let selecetedDriver={...driverCombo,trailerType:driverCombo?.trailerType||null}
        const post_request={
          canBeBooked:true,
          driver:selecetedDriver,
          fleetadmin: BookFleetAdmin,
          origin:null,
          destination:null,
          trailerType:estimatedata.estimate.post_request.trailerType,
          //post_request:estimatedata.estimate.post_request,
        }
        const bookingObject={
          approved: false,
          isActive:estimatedata.estimate.post_request.isActive,
          fleetadmin: BookFleetAdmin,
          pickup:estimatedata.estimate.pickup,
          drop:estimatedata.estimate.drop,
          estimate: estimatedata.estimate,
          bookLater: true,
          settings: settings,
          customer:estimatedata.estimate.post_request.customer,
          customer_email:estimatedata.estimate.post_request.customer_email,
          customer_name:estimatedata.estimate.post_request.customer_name,
          customer_contact:estimatedata.estimate.post_request.customer_contact,
          customer_token:estimatedata.estimate.post_request.customer_token,
          customer_image:estimatedata.estimate.post_request.customer_image,
          pickupDate:estimatedata.estimate.post_request.pickupDate,
          dropDate:estimatedata.estimate.post_request.dropDate,
          post_request:post_request,
          type:estimatedata.estimate.post_request.type,
          booking_type_admin:false,
          instructionData:estimatedata.estimate.post_request.instructionData,
          tripInstructions:estimatedata.estimate.post_request.instructionData.tripInstructions,
          loadId:estimatedata.estimate.post_request.id
        }
       
        dispatch(addBooking(bookingObject))
          .then(() => {
            setBookProgress(false);
            dispatch(clearEstimate());
            setEstimateRequested({ loading: false, id: null });
            setState({ open: true ,message:"Succesfuly Booked !!"  })
            setTimeout(()=>{
              window.location.href="/bookings"
            },1000)
          })
          .catch((error) => {
            setState({ open: true ,message:"Error occurred, try again !!"  })
          });
          fetchLoadBoards()
          setDriverCombo(null)
          SetTargetLoad(null)
          SetActiveSharedCarrier(null)
    };

function isLoadAlreadyBooked(loadId){
   if(!bookinglistdata?.bookings) return false

   let bookExists=bookinglistdata?.bookings?.filter(e=>{
      if(auth.info.profile.usertype==='dispatcher'){
        return e.loadId===loadId&&e.carrierId===auth.info.profile.carrierId&&e.status!=='CANCELLED'
      }
      return e.loadId===loadId&&e.carrierId===auth.info.uid&&(e.status!=='CANCELLED')
   }).length>0

   return bookExists

}


const [searchedlocationValue,setSearchedlocationValue]=useState(null)
const [searchedRadiusValue,setSearchedRadiusValue]=useState(null)
const [reset,setReset]=useState(true)
const [filter,setFilter]=useState([])
const [data,setData]=useState([])


useEffect(()=>{
    if(loadBoardData?.loadboard&&loadBoardData?.loadboard.length>0){
       if(searchedRadiusValue && searchedlocationValue&& searchedlocationValue?.coords){
           let filterdData = filterRoutesByLocation(loadBoardData?.loadboard,searchedlocationValue.coords,searchedRadiusValue)
           setFilter(filterdData)
           setReset(false)
       }
    }
},[loadBoardData.loadboard,searchedRadiusValue,searchedlocationValue])


const [ownerData,setOwnerData] =useState(null)

useEffect(()=>{
  if(auth.info?.profile?.brokerId){
       const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.brokerId)
       setOwnerData(targetData||null)
  } 
  else if(auth.info?.profile?.dispatcherId){
   const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.dispatcherId)
   setOwnerData(targetData||null)
   } 
   else if(auth.info?.profile?.carrierId){
     const targetData = giveIdThenIwillGiveYouData(auth.info?.profile?.carrierId)
     setOwnerData(targetData||null)
   } 
   else{
    setOwnerData({...auth.info?.profile,id:auth?.info?.uid})
   }

},[auth])

useEffect(()=>{
  if(reset && loadBoardData.loadboard){
     if(loadBoardData.loadboard?.length>0){
         if(ownerData?.actionType === 'forwarder'){
            setData(loadBoardData.loadboard.sort((a, b) => a.pickupDate - b.pickupDate))
         }
         else{
           const targetLoads = loadBoardData.loadboard.filter(e=>{
   
               return getContinent(giveIdThenIwillGiveYouData(e.customer)?.selectedcountry?.code)  ===  getContinent(ownerData?.selectedcountry?.code)
           })
           if(targetLoads.length   ===  0) setData([])
           else setData(targetLoads.sort((a, b) => a.pickupDate - b.pickupDate))

         }
     }else{
       setData(loadBoardData.loadboard)
     }
  }
  else{
      setData(filter)
  }

},[reset,loadBoardData,filter,ownerData])


function filterRoutesByLocation(routeData, targetLocation, radius) {
  const latitude=targetLocation.lat
  const longitude=targetLocation.lng
  // Sort the routes based on the distance from the target location

  const sortedRoutes = routeData.sort((a, b) => {
    const aPickupDistance = calculateDistance(
      a.pickup.coords.lat,
      a.pickup.coords.lng,
      latitude,
      longitude
    );
    const bPickupDistance = calculateDistance(
      b.pickup.coords.lat,
      b.pickup.coords.lng,
      latitude,
      longitude
    );


    return aPickupDistance - bPickupDistance;
  });

  // Filter the routes based on the specified radius
  return sortedRoutes.filter((route) => {
    const pickupDistance = calculateDistance(
      route.pickup.coords.lat,
      route.pickup.coords.lng,
      latitude,
      longitude
    );
    return pickupDistance <= radius;
  });
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadius = 3959; // Earth's radius in miles
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadius * c;
}

function toRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

const clearFilterAndMatch = () => {
    setReset(true);
    setFilter([]);
    setSearchedlocationValue(null);
    setSearchedRadiusValue(null);
  };





  return (
    (loadBoardData.loading&&loadBoardData)? <CircularLoading/>:
     <>

     <div
              style={{
                borderRadius: "4px",
                zIndex: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow:
                  "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                marginTop: "10px",
                backgroundColor: "rgba(255, 255, 255,0.6)",
                backdropFilter: 'blur(10px)',
              }}
            >
            {loadBoardData.loadboard?
                <>
                   {loadBoardData.loadboard.length>0?
                    <>
                       <div style={{width:"90%",textAlign:"center"}}>
                            <div
                            className={classes.containerInput}
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "20px",
                                flexWrap: "wrap",
                                margin: "10px 0 10px 0 ",
                                padding: 0,
                            }}
                            >
                                <div style={{ width: "160px" }}>
                                    <Paper>
                                        <GoogleMapsAutoComplete
                                            placeholder={'Search Location'}
                                            variant={"filled"}
                                            value={searchedlocationValue}
                                            onChange={(value) => {
                                            setSearchedlocationValue(value);
                                            }}
                                            style={{ width: '160px' }}
                                        />
                                    </Paper>
                                </div>

                                <div style={{ width: "160px" }}>
                                    <Paper>
                                        <TextField
                                            placeholder={'Search Radius'}
                                            variant={"filled"}
                                            type="number"
                                            value={searchedRadiusValue}
                                            onChange={(e) => {
                                            setSearchedRadiusValue(e.target.value);
                                            }}
                                            style={{ width: '160px' }}
                                        />
                                        </Paper>
                                </div>

                                                
                            </div> 
                            <Button
                                color="primaryButton"
                                size="lg"
                                rel="noopener noreferrer"
                                className={classes.items}
                                onClick={clearFilterAndMatch}
                                style={{
                                    height: 40,
                                    marginLeft: "10px",
                                    width: "150px",
                                    minWidth: "200",
                                    backgroundColor: "#01aac1",
                                }}
                                >
                                <i
                                    className="fas fa-times-circle"
                                    style={
                                    isRTL === "rtl"
                                        ? { marginLeft: "10px" }
                                        : { marginRight: "10px" }
                                    }
                                />
                                Clear
                            </Button>

                       </div>

                        <MaterialTable
                        title={"Public Load Board"}
                        columns={columns}
                        data={data}
                        options={{
                        exportButton: settings.AllowCriticalEditsAdmin,
                        sorting: true,
                        }}
                        localization={{
                        toolbar: {
                            searchPlaceholder: t("search"),
                            exportTitle: t("export"),
                        },
                        header: {
                            actions: "",
                        },
                        pagination: {
                            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
                            // labelRowsSelect: (t('rows')),
                            firstTooltip: t("first_page_tooltip"),
                            previousTooltip: t("previous_page_tooltip"),
                            nextTooltip: t("next_page_tooltip"),
                            lastTooltip: t("last_page_tooltip"),
                        },
                        
                        }}
                        style={{  
                            backgroundColor: "rgba(255, 255, 255,0.1)",
                            backdropFilter: 'blur(10px)', width: "100%", 
                            overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                       />
                    </>
            
                   :<Box sx={{height:"80vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                       <Typography variant="h3" style={{color:"black"}}>No Data is Available</Typography>
                   </Box>
                       }
                </>
                 :null
            
              }

     </div>
       
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
        
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openBooking}
        onClose={handleBookModalClose}
        className={classes.modal}
        container={() => rootRef.current}
      >
         <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            marginTop: 7,
            elevation: 3,
            borderRadius: 8,
            elevation: 3,
            borderColor: "black",
            borderWidth: 2,
            shadowOpacity: 0.9,
            shadowRadius: 10,
            shadowColor: colors.PROMO,
            shadowOffset: { height: 5, width: 4 },
           
          }}
        >
          {(isSuperDispatcher()||auth.info?.profile?.dispatcherId)&&<Grid item xs={12} style={{width:"100%"}}>
                {SharedCariers&&SharedCariers.length>0 ?
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Choose Carrier"}
                    users={SharedCariers}
                    value={ActiveSharedCarrier}
                    onChange={(event, newValue) => {
                      SetActiveSharedCarrier(newValue);
                    }}
                  />
                  : null}
              </Grid> 
            }

            {(isSuperDispatcher()||auth.info?.profile?.dispatcherId)&&SharedCariers.length===0&&<h3>No Active Fleetadmin is found for this load</h3>}
            {ActiveSharedCarrier&&drivers.length===0&&<h3>No Active Driver is found for this Fleetadmin</h3>}
            {!(isSuperDispatcher()||auth.info?.profile?.dispatcherId)&&drivers.length===0&&<h3>No Active Driver is found</h3>}
            
            {(((isSuperDispatcher()||auth.info?.profile?.dispatcherId)&&ActiveSharedCarrier)||!(isSuperDispatcher()||auth.info?.profile?.dispatcherId))&&<Grid item xs={12} style={{width:"100%"}}>
                {drivers&&drivers.length>0 ?
                  <UsersCombo
                    className={classes.items}
                    placeholder={"Choose Driver"}
                    users={drivers}
                    value={driverCombo}
                    onChange={(event, newValue) => {
                      setDriverCombo(newValue);
                    }}
                  />
                  : null}
              </Grid> }
            {driverCombo&&<div><Button onClick={confirmBooking}>Assign</Button></div>}
         </Grid>
      </Modal>

      <Box sx={{ width: 500 }}>

        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
        />

      </Box>
     </>
  );
}