import React, { useEffect, useContext } from "react";
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { FirebaseContext } from "common";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import Header from "components/Header/Header";

function AuthLoading(props) {
  const { api } = useContext(FirebaseContext);
  const { t } = useTranslation();
  const {
    fetchUser,
    fetchTrailerTypes,
    fetchSettings,
    fetchBookings,
    fetchEmptyRoutePostings,
    fetchCancelReasons,
    fetchUsers,
    fetchNotifications,
    signOut,
    fetchLanguages,
    monitorProfileChanges
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const languagedata = useSelector((state) => state.languagedata);
  const settingsdata = useSelector((state) => state.settingsdata);

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch, fetchSettings]);

  useEffect(() => {
    if (languagedata.langlist) {
      for (const value of Object.values(languagedata.langlist)) {
        if (value.default === true) {
          i18n.addResourceBundle(
            value.langLocale,
            "translations",
            value.keyValuePairs
          );
          i18n.changeLanguage(value.langLocale);
          moment.locale(value.dateLocale);
        }
      }
      const platform = "web";
      dispatch(fetchUser(platform));
    }
  }, [languagedata, dispatch, fetchUser]);

  useEffect(() => {
    if (settingsdata.settings) {
      dispatch(fetchLanguages());
      dispatch(fetchTrailerTypes());
      document.title = settingsdata.settings.appName;
    }
  }, [settingsdata.settings, dispatch, fetchLanguages, fetchTrailerTypes]);

  
  useEffect(() => {
    if (auth.info) {
      if (auth.info.profile) {
        let role = auth.info.profile.usertype;
        if (role === "shipper") {
          dispatch(fetchBookings(auth.info.uid, role));
          dispatch(fetchEmptyRoutePostings(auth.info.uid, role));
          dispatch(fetchCancelReasons());
          dispatch(fetchUsers());
        } else if (role === "driver") {
          dispatch(fetchBookings(auth.info.uid, role));
          dispatch(fetchEmptyRoutePostings(auth.info.uid, role));
          dispatch(monitorProfileChanges());
        } else if (role === "admin") {
          dispatch(fetchUsers());
          dispatch(fetchBookings(auth.info.uid, role));
          dispatch(fetchEmptyRoutePostings(auth.info.uid, role));
          dispatch(fetchNotifications());
          dispatch(fetchCancelReasons());
        } else if (role === "fleetadmin") {
          dispatch(fetchUsers());
          dispatch(fetchBookings(auth.info.uid, role));
          dispatch(fetchEmptyRoutePostings(auth.info.uid, role));
        } else if (role === "dispatcher") {
          dispatch(fetchUsers());
          dispatch(fetchBookings(auth.info.uid, role));
          dispatch(fetchEmptyRoutePostings(auth.info.uid, role));
        }  
         else {
          alert(t("not_valid_user_type"));
          dispatch(signOut());
        }
      } else {
        alert(t("user_issue_contact_admin"));
        dispatch(signOut());
      }
    } else {
      dispatch(fetchEmptyRoutePostings(null, null));
      dispatch(fetchUsers())
    }
  }, [
    auth.info,
    dispatch,
    fetchBookings,
    fetchEmptyRoutePostings,
    fetchCancelReasons,
    fetchNotifications,
    fetchUsers,
    signOut,
    monitorProfileChanges,
    t,
  ]);

  return props.excludedPaths && props.excludedPaths.includes(window.location.pathname) ? (
    props.children 
  ) :(settingsdata.loading ? (
      <>
        <Header color="landing"/>
        <CircularLoading />
      </>
  ) : settingsdata.settings ? (
    auth.loading || !languagedata.langlist ? (
      <>
        <Header color="landing"/>
        <CircularLoading />
      </>
    ) : (
      props.children
    )
  ) : (
    <div>
      <span>No Database Settings found</span>
  </div>
  ))
}

export default AuthLoading;
